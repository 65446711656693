import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CustomDatePipe} from './custom-date.pipe';
import {CustomCurrencyPipe} from './custom-currency.pipe';
import {CapitalizePipe} from './capitalize.pipe';
import {ObsWithStatus} from '../../utils/obsWithStatus';
import {ShowEmailIfNoNamePipe} from './show-email-if-no-name.pipe';
import {FormatContractNoPipe} from './format-contract-no.pipe';
import { FormatDaysPipe } from './format-days.pipe';
import { CustomPercentagePipe } from './custom-percentage.pipe';
import { CustomPercentageOrCurrencyPipe } from './custom-percentage-or-currency.pipe';
import { CustomPercentage2Pipe } from './custom-percentage-2.pipe';
import { CustomPercentageToIntegerPipe } from './custom-percentage-to-integer.pipe';
import { UcfirstPipe } from './ucfirst.pipe';
import { PipeCompanyPipe } from './pipe-company.pipe';
import { RoundUpPipe } from './round-up.pipe';
import { HighlightSearchPipe } from './highlight-search.pipe';
import { StripHtmlPipe } from './strip-html.pipe';
import { ParseUrlPipe } from './parse-url.pipe';

@NgModule({
  declarations: [
    CustomCurrencyPipe,
    CustomDatePipe,
    CapitalizePipe,
    ObsWithStatus,
    ShowEmailIfNoNamePipe,
    FormatContractNoPipe,
    FormatDaysPipe,
    CustomPercentagePipe,
    CustomPercentageOrCurrencyPipe,
    CustomPercentage2Pipe,
    CustomPercentageToIntegerPipe,
    UcfirstPipe,
    PipeCompanyPipe,
    RoundUpPipe,
    HighlightSearchPipe,
    StripHtmlPipe,
    ParseUrlPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CustomCurrencyPipe,
    CustomDatePipe,
    CapitalizePipe,
    ObsWithStatus,
    ShowEmailIfNoNamePipe,
    FormatContractNoPipe,
    FormatDaysPipe,
    CustomPercentagePipe,
    CustomPercentageOrCurrencyPipe,
    CustomPercentage2Pipe,
    CustomPercentageToIntegerPipe,
    UcfirstPipe,
    PipeCompanyPipe,
    RoundUpPipe,
    HighlightSearchPipe,
    StripHtmlPipe,
    ParseUrlPipe
  ],
  providers: [
    FormatContractNoPipe
  ]
})
export class CustomPipesModule {
}
