import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {catchError, map, switchMap, take} from 'rxjs/operators';
import {IImportResult, IResultMessage} from '../../../../../../models/admin/csv-upload';
import {ConsultantTemplateImportService} from '../../../../../../services/admin/consultant-template-import.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {of} from 'rxjs';
import {SPINNER_NAME} from '../../../../../../../core/utils/constants';
import {RoutesAdminConfig} from '../../../../../../../core/utils/routes';

@Component({
  selector: 'app-import-results',
  templateUrl: './import-results.component.html',
  styleUrls: ['./import-results.component.scss']
})
export class ImportResultsComponent implements OnInit {
  importRecord: IImportResult;
  allErrors: IResultMessage[];
  errors: IResultMessage[];
  successes: IResultMessage[];

  constructor(private readonly consultantTemplateImportService: ConsultantTemplateImportService,
              private readonly route: ActivatedRoute,
              private readonly spinner: NgxSpinnerService) {
  }

  ngOnInit(): void {
    this.spinner.show(SPINNER_NAME);
    this.route.paramMap.pipe(
      map(params => params.get('id')),
      switchMap(id => this.consultantTemplateImportService.getImportRecord(Number.parseInt(id, 10)))
    ).pipe(
      take(1),
      catchError(() => {
        this.spinner.hide(SPINNER_NAME);
        return of(null);
      })
    ).subscribe(record => {
      this.importRecord = record;
      this.allErrors = record.errors;
      this.errors = record.errors.slice(0, 50);
      this.successes = record.successes.slice(0, 50);
      this.spinner.hide(SPINNER_NAME);
    });
  }

  showMoreErrors(): void {
    const l = this.errors.length;
    this.errors = [...this.errors, ...this.allErrors.slice(l, l + 50)];
  }

  getBack() {
    return RoutesAdminConfig.UPLOAD_RESULTS;
  }
}
