import {Component, OnInit} from '@angular/core';
import {AuthService} from '../main/services/auth.service';
import {EmbeddedAuthService} from '../main/services/embedded-auth.service';
import {LocalStorageService} from '../main/services/local-storage-service';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss']
})
export class CallbackComponent implements OnInit {

  constructor(private readonly auth: AuthService,
              private readonly embeddedAuth: EmbeddedAuthService,
              public localStorageService: LocalStorageService) {
  }

  ngOnInit() {
    this.embeddedAuth.handleAuthentication();
  }
}
