import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SignUpService {

  public email = '';
  constructor() { }

  setSignUpEmail(signUpEmail: string): void {
    this.email = signUpEmail;
  }

  getSignUpEmail(): string {
    return this.email;
  }

  resetSignUpEmail(): void {
    this.email = '';
  }
}
