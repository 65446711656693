import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ConfirmModalComponent} from './confirm-modal.component';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {TranslateModule} from '@ngx-translate/core';
import {FlexLayoutModule} from '@angular/flex-layout';


@NgModule({
  declarations: [ConfirmModalComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    TranslateModule,
    FlexLayoutModule
  ],
  exports: [
    ConfirmModalComponent
  ]
})
export class ConfirmModalModule {
}
