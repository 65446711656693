import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Pipe({
  name: 'highlightSearch'
})
export class HighlightSearchPipe implements PipeTransform {

  constructor(private readonly sanitizer: DomSanitizer) {
  }

  transform(value: string, search: string): SafeHtml {
    const valueStr = value + '';
    if (search === '') return this.sanitizer.bypassSecurityTrustHtml(valueStr);

    const htmlTags = /(<\/?[^>]+>)/g;

    // allows the search of special characters like '?' and '.'
    const replacedStr = (search || '').trim().split(/\s+/).map(x => x.replace(/([-[\]{}()*+!<=:?.\\^$|#\s,])/g, '\\$&')).join('|');
    const highlightRegex = new RegExp(replacedStr, 'gi');

    const result = valueStr.split(htmlTags).map((s) => {
      if (s.match(htmlTags)) {
        return s;
      } else {
        return s.replace(highlightRegex,
          '<strong class = "text-accent">$&</strong>');
      }
    }).join('');

    return this.sanitizer.bypassSecurityTrustHtml(result);
  }
}
