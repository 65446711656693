import {BrowserModule} from '@angular/platform-browser';
import {Compiler, COMPILER_OPTIONS, CompilerFactory, LOCALE_ID, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CallbackComponent} from './callback/callback.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {AddTokenService} from './core/interceptors/add-token.service';
import {ErrorInterceptorService} from './core/interceptors/error-interceptor.service';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatIconModule} from '@angular/material/icon';
import {LoginComponent} from './main/content/login/login.component';
import {FlexLayoutModule, LayoutGapStyleBuilder} from '@angular/flex-layout';
import {MatCardModule} from '@angular/material/card';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {UserService} from './main/services/consultant/user.service';
import {NotifierModule, NotifierService} from 'angular-notifier';
import {notifierDefaultOptions} from './main/models/admin/custom-message';
import {ConsultantIdentityService} from './main/services/consultant/consultant-identity.service';
import {MatDialogModule} from '@angular/material/dialog';
import {InlineSVGModule} from 'ng-inline-svg';
import {AuthFallbackComponent} from './auth-fallback/auth-fallback.component';
import {MatButtonModule} from '@angular/material/button';
import {SignUpComponent} from './main/content/sign-up/sign-up.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {ForgotPasswordComponent} from './main/content/forgot-password/forgot-password.component';
import {ReactiveFormsModule} from '@angular/forms';
import {SlickCarouselModule} from 'ngx-slick-carousel';
import {
  ForgotPasswordRecoveryComponent
} from './main/content/forgot-password-recovery/forgot-password-recovery.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {IConfig, NgxMaskModule} from 'ngx-mask';
import {
  PublicSalarySimulationCountryComponent
} from './main/content/public-pages/pages/public-salary-simulation-country/public-salary-simulation-country.component';
import {MatRadioModule} from '@angular/material/radio';
import {MatOptionModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {CustomPipesModule} from './core/formatters/custom-pipes/custom-pipes.module';
import {ChartsModule, ThemeService} from 'ng2-charts';
import {SalarySimService} from './main/services/consultant/salary-sim.service';
import {CustomSlideToggleModule} from './main/utils/custom-slide-toggle.module';
import localeFr from '@angular/common/locales/fr';
import {registerLocaleData} from '@angular/common';
import {
  DialogHbsptComponent
} from './main/content/public-pages/pages/public-salary-simulation-country/dialog-hbspt/dialog-hbspt.component';
import {SafePipe} from './core/utils/safe.pipe';
import {TimeOutDialogComponent} from './main/content/time-out-dialog/time-out-dialog/time-out-dialog.component';
import {TIME_OUT_TOKEN} from './main/content/time-out-dialog/time-out-dialog/time-out-token';
import {MatMenuModule} from '@angular/material/menu';
import {MatBadgeModule} from '@angular/material/badge';
import {MatDividerModule} from '@angular/material/divider';
import {MatListModule} from '@angular/material/list';
import {
  PublicSalarySimulationPdfComponent
} from './main/content/public-pages/pages/public-salary-simulation-pdf/public-salary-simulation-pdf.component';
import {NgIdleKeepaliveModule} from '@ng-idle/keepalive';
import MultiPlatform from '../environments/multi-platform';
import {Platform} from './core/utils/global';
import {LangConf, SUFFIX} from './core/utils/languages';
import {BrowserPlatform} from './core/utils/BrowserPlatform';
import {SupportedBrowsersComponent} from './main/content/supported-browsers/supported-browsers.component';
import {LanguageConsultantResolver} from './core/resolver/language-consultant.resolver';
import {LanguageAdminResolver} from './core/resolver/language-admin.resolver';
import {JitCompilerFactory} from '@angular/platform-browser-dynamic';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import {TranslateMatPaginatorIntl} from './core/utils/translatePaginator';
import {CustomLayoutGapStyleBuilder} from './core/builders/layout-gap-style-builder';
import {ComponentsModule} from './main/content/components/components.module';
import {UserSettingsModule} from './main/content/common/user-settings/user-settings.module';
import {MarketplaceModalComponent} from './main/content/common/modals/marketplace-modal/marketplace-modal.component';
import {
  PrivateSalarySimulationCountryComponent
} from './main/content/consultants/pages/salary-simulation-country/private-salary-simulation-country.component';
import {EmptyComponent} from './empty/empty.component';
import {UploadCsvComponent} from './main/content/admin/pages/bulk-import/upload-csv.component';
import {
  TransactionTypesManagementComponent
} from './main/content/admin/pages/configurations/transaction-types-management/transaction-types-management.component';
import {
  ImportResultsTableComponent
} from './main/content/admin/pages/configurations/import-results-table/import-results-table.component';
import {
  ImportResultsComponent
} from './main/content/admin/pages/configurations/import-results-table/import-results/import-results.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {
  TemplatePropertiesViewModule
} from './main/content/admin/pages/common/template-properties-view/template-properties-view.module';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatTableModule} from '@angular/material/table';
import {NoResultsModule} from './main/content/admin/pages/common/no-results/no-results.module';
import {MatExpansionModule} from '@angular/material/expansion';
import {ConsultantTemplateImportService} from './main/services/admin/consultant-template-import.service';
import {MatSortModule} from '@angular/material/sort';
import {TransactionService} from './main/services/admin/transaction-service';
import {AdminModule} from './main/content/admin/admin.module';
import {ConfirmModalModule} from './main/content/admin/pages/common/confirm-modal/confirm-modal.module';
import {ConfirmModalComponent} from './main/content/admin/pages/common/confirm-modal/confirm-modal.component';
import {
  AddEditTrxTypeComponent
} from './main/content/admin/pages/configurations/transaction-types-management/add-edit-trx-type/add-edit-trx-type.component';

export function createCompiler(compilerFactory: CompilerFactory) {
  return compilerFactory.createCompiler();
}

registerLocaleData(localeFr);

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: false
};

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, LangConf.getTranslateHttpLoader(), SUFFIX);
}

const maskConfig: Partial<IConfig> = {
  validation: true
};

@NgModule({
  declarations: [
    AppComponent,
    CallbackComponent,
    LoginComponent,
    AuthFallbackComponent,
    SignUpComponent,
    ForgotPasswordComponent,
    ForgotPasswordRecoveryComponent,
    SafePipe,
    PublicSalarySimulationCountryComponent,
    PublicSalarySimulationPdfComponent,
    DialogHbsptComponent,
    SafePipe,
    TimeOutDialogComponent,
    SupportedBrowsersComponent,
    MarketplaceModalComponent,
    PrivateSalarySimulationCountryComponent,
    EmptyComponent,
    UploadCsvComponent,
    TransactionTypesManagementComponent,
    ImportResultsTableComponent,
    ImportResultsComponent,
    AddEditTrxTypeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    FlexLayoutModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    HttpClientModule,
    MatIconModule,
    MatCardModule,
    PerfectScrollbarModule,
    NotifierModule.withConfig(notifierDefaultOptions),
    MatDialogModule,
    InlineSVGModule.forRoot(),
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    SlickCarouselModule,
    MatTooltipModule,
    MatRadioModule,
    MatOptionModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    CustomPipesModule,
    ChartsModule,
    NgxMaskModule.forRoot(maskConfig),
    CustomSlideToggleModule,
    NgIdleKeepaliveModule.forRoot(),
    MatMenuModule,
    MatBadgeModule,
    MatDividerModule,
    MatListModule,
    ScrollingModule,
    InfiniteScrollModule,
    DragDropModule,
    ComponentsModule,
    UserSettingsModule,
    NgxSpinnerModule,
    TemplatePropertiesViewModule,
    MatProgressBarModule,
    MatTableModule,
    NoResultsModule,
    MatPaginatorModule,
    MatSortModule,
    MatExpansionModule,
    ConfirmModalModule
  ],
  providers: [
    UserService,
    NotifierService,
    ConsultantIdentityService,
    SalarySimService,
    ThemeService,
    LanguageConsultantResolver,
    LanguageAdminResolver,
    ConsultantTemplateImportService,
    TransactionService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddTokenService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptorService,
      multi: true
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {
      provide: LOCALE_ID, useValue: MultiPlatform.get().provideLocaleId
    },
    {
      provide: TIME_OUT_TOKEN,
      useValue: TimeOutDialogComponent
    },
    {
      provide: Platform,
      useClass: BrowserPlatform
    },
    {provide: COMPILER_OPTIONS, useValue: {}, multi: true},
    {provide: CompilerFactory, useClass: JitCompilerFactory, deps: [COMPILER_OPTIONS]},
    {provide: Compiler, useFactory: createCompiler, deps: [CompilerFactory]},
    {provide: MatPaginatorIntl, useClass: TranslateMatPaginatorIntl},
    {provide: LayoutGapStyleBuilder, useClass: CustomLayoutGapStyleBuilder}
  ],
  bootstrap: [AppComponent],
  exports: [],
  entryComponents: [
    DialogHbsptComponent,
    MarketplaceModalComponent,
    ConfirmModalComponent,
    AddEditTrxTypeComponent
  ]
})

export class AppModule {
}
