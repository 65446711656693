import {AbstractControl, FormArray, FormControl, FormGroupDirective, NgForm, ValidatorFn} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import {isNull} from 'util';
import {CLASS_MAT_INPUT_ERROR} from './constants';

export function repeatedSubjectValidator(subjects: string[], oldValue?: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const repeated = subjects.some(inputSubject => control.value === inputSubject) && oldValue !== control.value;
    return repeated ? {repeated: {value: control.value}} : null;
  };
}

export function blankSpacesValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const isControlNull = control.value === undefined || isNull(control.value);
    let isBlank = false;
    if (control.value) {
      isBlank = isControlNull ? false : control.value.toString().trim().length === 0;
    }
    return isBlank ? {isBlank: {value: control.value}} : null;
  };
}

export function oneCheckboxValidator(fa: FormArray) {
  let valid = false;

  for (let x = 0; x < fa.length; ++x) {
    if (fa.at(x).value) {
      valid = true;
      break;
    }
  }
  return valid ? null : {
    oneCheckboxValidator: true
  };
}

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

export function checkErrorInput(value1: any, value2: any) {
  if (!value1.value) {
    value2.nativeElement.classList.add(CLASS_MAT_INPUT_ERROR);
  } else if (value1.toString().trim().length === 0) {
    value2.nativeElement.classList.add(CLASS_MAT_INPUT_ERROR);
  } else {
    value2.nativeElement.classList.remove(CLASS_MAT_INPUT_ERROR);
  }

}
