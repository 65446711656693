import { Pipe, PipeTransform } from '@angular/core';
import {ConsultantSummaryData} from '../../../main/models/admin/consultant-account';

@Pipe({
  name: 'showEmailIfNoName'
})
export class ShowEmailIfNoNamePipe implements PipeTransform {

  transform(value: ConsultantSummaryData, ...args: any[]): any {
    return (value.firstName !== null && value.lastName !== null) ?
      `${value.lastName} ${value.firstName}` : value.email;
  }

}
