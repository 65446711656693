import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TemplatePropertiesViewComponent} from './template-properties-view.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {FlexModule} from '@angular/flex-layout';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';


@NgModule({
  declarations: [
    TemplatePropertiesViewComponent
  ],
  imports: [
    CommonModule,
    MatTooltipModule,
    FlexModule,
    PerfectScrollbarModule
  ],
  exports: [
    TemplatePropertiesViewComponent
  ]
})
export class TemplatePropertiesViewModule {
}
