import { Pipe, PipeTransform } from '@angular/core';
import {PERT_LOCALE} from '../format-percentagem';
import {formatPercent} from '@angular/common';

@Pipe({
  name: 'customPercentage2'
})
export class CustomPercentage2Pipe implements PipeTransform {

  transform(
    value: number,
    digitsInfo = '1.3',
    locale = PERT_LOCALE,
  ): string | null {
    let pert = 0;

    if (isNaN(value)) {
      pert = 0;
    } else if (value === undefined) {
      pert = 0;
    } else if (value === Infinity) {
      pert = 0;
    } else if (value === 100) {
      return Math.trunc(value) + ' %';
    } else {
      pert = value / 100;
    }

    return formatPercent(
      pert,
      locale,
      digitsInfo
    );
  }

}
