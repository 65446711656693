import {Inject, Injectable} from '@angular/core';
import MultiPlatform from '../../../../environments/multi-platform';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {
  IInfoSalarySimulatorRequest,
  IInfoTaxClassRequest,
  IResultSalarySimulatorRequest,
  ISalarySimulatorBodyRequest
} from '../../../core/utils/SimulatorSalary';
import {DOCUMENT} from '@angular/common';
import {NotifierWithTemplateService} from '../notifier-with-template.service';
import {TranslateService} from '@ngx-translate/core';
import {HatchrAuthService} from '../hatchr-auth.service';

@Injectable()
export class SalarySimService {
  protected readonly http: HttpClient;

  API_SALARY_SIMULATION_PUBLIC = `${MultiPlatform.getDataEnvironmentHost()}/v1/payroll/simulator`;
  CAR_LEASING = 'carLeasing';
  HEALTH_INSURANCE = 'healthInsurance';
  MOBILE_PHONE = 'mobilePhone';
  FUEL_CARD = 'fuelCard';
  EXPENSES = 'expenses';
  TAX_CLASS_CODE_OTHER = 'other_pert';
  TAX_CLASS_NEUTRE = 'tax_neutre';
  TAX_CLASS_NON_IMPOSABLE = 'non_imposable';
  OPTION_CAR_LEASING = 0;
  FULL_TIME = 'FULL_TIME';
  PART_TIME = 'PART_TIME';
  PARAMETER_FINAL_NET_SALARY = 'PARAMETER_FINAL_NET_SALARY';
  TEXTBOX = 'textbox';
  COMBOBOX = 'combobox';

  constructor(private readonly hatchrAuthService: HatchrAuthService,
              private readonly notifier: NotifierWithTemplateService,
              private readonly translate: TranslateService,
              @Inject(DOCUMENT) private document: Document) {
    this.http = new HttpClient(hatchrAuthService);
  }

  saveSalarySimulator(data: ISalarySimulatorBodyRequest) {
    this.http.put(this.API_SALARY_SIMULATION_PUBLIC + '/save-pdf', data).subscribe();
  }

  resultSalarySimulator(data: ISalarySimulatorBodyRequest): Observable<IResultSalarySimulatorRequest> {
    return this.http.put(`${this.API_SALARY_SIMULATION_PUBLIC}/result-simulation`,
      data) as Observable<IResultSalarySimulatorRequest>;
  }

  resultTaxClass(data: ISalarySimulatorBodyRequest): Observable<IInfoTaxClassRequest> {
    return this.http.put(`${this.API_SALARY_SIMULATION_PUBLIC}/result-tax-class`, data) as Observable<IInfoTaxClassRequest>;
  }

  infoSalarySimulator(): Observable<IInfoSalarySimulatorRequest> {
    return this.http.get(`${this.API_SALARY_SIMULATION_PUBLIC}/info`) as Observable<IInfoSalarySimulatorRequest>;
  }

  resultSalarySimulatorPdf(id: any): Observable<IResultSalarySimulatorRequest> {
    return this.http.put(`${this.API_SALARY_SIMULATION_PUBLIC}/result/${id}`, null) as Observable<IResultSalarySimulatorRequest>;
  }
}
