import {Component, OnInit} from '@angular/core';
import {EmbeddedAuthService} from '../../services/embedded-auth.service';
import {TranslateService} from '@ngx-translate/core';
import {MyErrorStateMatcher} from '../../../core/utils/validators';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {SignUpService} from '../../services/signup/sign-up.service';
import {Country} from '../../models/common/country';
import {CountryService} from '../../services/common/country.service';
import MultiPlatform from '../../../../environments/multi-platform';
import {ActivatedRoute} from '@angular/router';
import {filter, map, switchMap, take} from 'rxjs/operators';
import {InvitationService} from '../../services/admin/invitation.service';
import {IInvitation} from '../../models/admin/invitation';
import {NotifierWithTemplateService} from '../../services/notifier-with-template.service';
import {PublicRoute} from '../../../core/utils/routes';
import {MOBILE} from '../../../core/utils/constants';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['../login/login.component.scss', 'sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
  errorStateMatcher = new MyErrorStateMatcher();
  countries: Country[];

  dataCompany: any = MultiPlatform.get();

  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    phoneCodeCountryIdentifier: new FormControl('', Validators.required),
    mobile: new FormControl('', [Validators.required, Validators.maxLength(16)]),
    password: new FormControl('', [Validators.required, Validators.minLength(8)]),
    confPassword: new FormControl('', [Validators.required, Validators.minLength(8)])
  });
  hide = true;
  hideConf = true;
  showRules = false;
  confPasswordError = false;
  showAllInputs = false;
  userExistsError = false;

  invitation: IInvitation;

  private confirmPasswordValidator(): boolean {
    return this.loginForm.controls.password.value === this.loginForm.controls.confPassword.value;
  }

  constructor(private readonly embeddedAuth: EmbeddedAuthService,
              private readonly translate: TranslateService,
              private readonly countryService: CountryService,
              private signUpService: SignUpService,
              private route: ActivatedRoute,
              private readonly invitationService: InvitationService,
              private readonly notifier: NotifierWithTemplateService) {
  }

  ngOnInit() {
    this.route.paramMap.pipe(
      take(1),
      map(params => params.get(PublicRoute.PARAM_INVITATION_ID)),
      filter(id => id !== null),
      switchMap(id => this.invitationService.getInvitation(id))
    ).subscribe(invitation => {
      if (invitation) {
        this.loginForm.controls.email.setValue(invitation.email);
        this.loginForm.controls.firstName.setValue(invitation.firstName);
        this.loginForm.controls.lastName.setValue(invitation.lastName);
      } else {
        this.notifier.error(this.translate.instant('FORM_SIGN_UP.ERROR.INVITATION_NOT_FOUND'));
      }
      this.showInputs();
    });

    this.getCountries();

  }

  get mobileFC(): FormControl {
    return this.loginForm ? this.loginForm.controls.mobile as FormControl : null;
  }

  signUp(): void {
    if (!this.confirmPasswordValidator()) {
      this.confPasswordError = true;
      this.loginForm.controls.confPassword.setErrors({notMatch: true});
      return;
    } else { this.confPasswordError = false; }
    this.checkPrefix(MOBILE);
    if (!this.loginForm.valid) return;

    this.embeddedAuth.signUpAndLogin(
      this.loginForm.controls.email.value,
      this.loginForm.controls.password.value,
      this.loginForm.controls.firstName.value,
      this.loginForm.controls.lastName.value,
      this.loginForm.controls.phoneCodeCountryIdentifier.value,
      this.loginForm.controls.mobile.value,
      this.checkError
    );
  }

  getCountries() {
      this.countryService.getCountriesUnregistered()
        .pipe(take(1))
        .subscribe(countries => {
          this.countries = countries;
        }, () => this.notifier.error(this.translate.instant('VIEW.ERROR.GET_COUNTRIES')));
  }

  loginWithGoogle(): void {
    this.embeddedAuth.loginWithGoogle();
  }

  loginWithMicrosoft(): void {
    this.embeddedAuth.loginWithMicrosoft();
  }

  loginWithLinkedIn(): void {
    this.embeddedAuth.loginWithLinkedIn();
  }

  getErrorMessage(error: string) {
    switch (error) {
      case 'email': return this.loginForm.controls.email.hasError('required') ? this.translate.instant('EMPTY_FIELD') :
        this.loginForm.controls.email.hasError('email') ? this.translate.instant('EMAIL_INVALID') :
          this.translate.instant('USER_ALREADY_EXISTS');
      case 'firstName': return this.loginForm.controls.firstName.hasError('required') ? this.translate.instant('EMPTY_FIELD') : '';
      case 'lastName': return this.loginForm.controls.lastName.hasError('required') ? this.translate.instant('EMPTY_FIELD') : '';
      case 'mobile': return this.loginForm.controls.mobile.hasError('required') ? this.translate.instant('EMPTY_FIELD') : '';
      case 'password': return this.loginForm.controls.password.hasError('required') ? this.translate.instant('EMPTY_FIELD') : '';
      default: return '';
    }

  }

  showInputs() {
    if (this.loginForm.controls.email.value !== null && this.loginForm.controls.email.value !== '') {
      this.showAllInputs = true;
    }
  }

  enterPress() {
    if (!this.showAllInputs) {
      event.preventDefault();
      this.showInputs();
    }
  }

  checkError = (err) => {
    if (err.code === 'user_exists') {
      this.userExistsError = true;
    }
  }

  onEmailFocus() {
    this.userExistsError = false;
  }

  checkActiveColor(input: string) {
    if (this.loginForm.get(input).value !== '') {
      return 'active';
    }
    return '';
  }

  getLinkLogin(): any {
    return `/${PublicRoute.LOGIN}`;
  }

  getLinkHome(): any {
    return PublicRoute.HOME;
  }

  checkPrefix(key: string) {
    this.countries.forEach(country => {
      if (country && this.loginForm.controls[key] &&
        this.loginForm.controls[key].value &&
        this.loginForm.controls[key].value.toString().includes(country.phoneCode)
      ) {
        this.loginForm.controls[key].setErrors({containsCode: true});
        this.loginForm.controls[key].markAsTouched();
      }
    });
  }
}
