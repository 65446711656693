import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MyErrorStateMatcher} from '../../../core/utils/validators';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import MultiPlatform from '../../../../environments/multi-platform';
import {LocalStorageService} from '../../services/local-storage-service';
import {PublicRoute} from '../../../core/utils/routes';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  errorStateMatcher = new MyErrorStateMatcher();
  dataCompany: any = MultiPlatform.get();
  forgotPasswordForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  constructor(private readonly router: Router,
              public readonly translate: TranslateService,
              public readonly localStorageService: LocalStorageService) {
  }

  ngOnInit() {
  }

  forgotPassword(): void {
    const keyEMail = 'email';
    if (this.forgotPasswordForm.get(keyEMail).valid) {
      this.router.navigate([this.localStorageService.getPathForgotPasswordRecovery()],
        { queryParams: { email: this.forgotPasswordForm.get(keyEMail).value }});
    }
  }

  getErrorMessage() {
    return this.forgotPasswordForm.controls.email.hasError('required') ? this.translate.instant('EMPTY_FIELD') :
      this.forgotPasswordForm.controls.email.hasError('email') ? this.translate.instant('EMAIL_INVALID') : '';
  }

  getLinkLogin(): any {
    return `/${PublicRoute.LOGIN}`;
  }

  getLinkHome(): any {
    return PublicRoute.HOME;
  }
}
