import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptorService implements HttpInterceptor {

  constructor(private readonly snackBar: MatSnackBar) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<HttpErrorResponse>> {
    return next.handle(request)
      .pipe(catchError(errorResponse => this.handleError(errorResponse)));
  }

  private handleError(errorResponse: HttpErrorResponse): Observable<HttpEvent<HttpErrorResponse>> {
    if (errorResponse instanceof HttpErrorResponse) {
      this.handleServerError(errorResponse);
    }
    throw errorResponse;
  }

  private handleServerError(errorResponse: HttpErrorResponse) {
    try {
      if (errorResponse.error.status) {
        // errorResponse.error.error + ': ' +
        const message = errorResponse.error.message === 'No message available' ?
          errorResponse.error.error : errorResponse.error.message;
        this.snackBar.open(message, 'Dismiss', {panelClass: ['errors'], duration: 5000});
      }
    } catch (e) {

    }
  }
}
