import {Inject, Injectable} from '@angular/core';
import * as auth0 from 'auth0-js';
import MultiPlatform from '../../../environments/multi-platform';
import {Router} from '@angular/router';
import {redirectLanguage} from '../../core/utils/util-functions';
import {NotifierWithTemplateService} from './notifier-with-template.service';
import {EnrollmentService} from './admin/enrollment.service';
import {take} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {LocalStorageService} from './local-storage-service';
import {USER_AUTH} from '../../core/utils/constants';
import {MatDialog} from '@angular/material';
import {TIME_OUT_TOKEN} from '../content/time-out-dialog/time-out-dialog/time-out-token';
import {ComponentType} from '@angular/cdk/typings/portal';
import {AuthRolesService} from './admin/auth-roles.service';
import {InstanceService} from './instance.service';
import {PublicRoute} from '../../core/utils/routes';
import {CLASS_MAT_DIALOG_TYPE} from '../../core/utils/responsive';
import {ResponsiveService} from './responsive.service';
import {Platform} from '../../core/utils/global';

@Injectable({
  providedIn: 'root'
})
export class EmbeddedAuthService {
  private databaseConnection = 'Username-Password-Authentication';
  authOptions = {
    clientID: `${MultiPlatform.getDataEnvironmentAuth0().clientID}`,
    domain: `${MultiPlatform.getDataEnvironmentAuth0().domain}`,
    redirectUri: `${MultiPlatform.getDataEnvironmentRedirectUrl()}/${PublicRoute.CALLBACK}`,
    audience: `${MultiPlatform.getDataEnvironmentApi().audience}`,
    responseType: 'token id_token'
  };

  auth0 = new auth0.WebAuth(this.authOptions);

  constructor(private readonly notifier: NotifierWithTemplateService,
              private readonly router: Router,
              private readonly enrollmentService: EnrollmentService,
              private readonly translate: TranslateService,
              private localStorageService: LocalStorageService,
              @Inject(TIME_OUT_TOKEN) private timeOutComponent: ComponentType<any>,
              private authRoles: AuthRolesService,
              private dialog: MatDialog,
              public readonly instance: InstanceService,
              private readonly responsiveService: ResponsiveService,
              private readonly platform: Platform) {
    setInterval(() => this.checkSessionTimeout(), 1000 * 180);
  }

  isUserProfile() {
    return this.localStorageService.isUserProfile();
  }

  handleAuthentication() {
    this.localStorageService.remove();
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
        this.auth0.client.userInfo(authResult.accessToken, (error1, profile) => {
          profile = Object.assign(profile, this.localStorageService.getNames()); // name contains an object with first and last names
          profile = Object.assign(profile, {
            phoneCodeCountryIdentifier: this.localStorageService.getPhoneCodeCountryIdentifier(),
            mobile: this.localStorageService.getPhoneNumber()
          });
          this.enrollmentService.checkIfCreatesAccount(profile).pipe(take(1))
            .subscribe(created => {
              if (created) {
                this.authRoles.getUserRoles(profile.sub).pipe(take(1))
                  .subscribe(userRoles => {
                    for (const role of userRoles) {
                      profile[USER_AUTH].roles.push(role);
                    }
                    this.localStorageService.setUserProfile(profile);
                    redirectLanguage(this.platform, this.router, profile[USER_AUTH].roles, this.instance);
                  });
              } else {
                this.localStorageService.setUserProfile(profile);
                redirectLanguage(this.platform, this.router, profile[USER_AUTH].roles, this.instance);
              }
            });
        });
      }
    });
  }

  getAccessToken() {
    return this.localStorageService.getAccessToken();
  }

  getIdToken() {
    return this.localStorageService.getIdToken();
  }

  renewSession() {
    this.auth0.checkSession({}, (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
      } else if (err) {
        console.log(err);
      }
    });
  }

  logout() {
    this.localStorageService.remove();
    const urlLogin = `${window.location.origin}/${PublicRoute.LOGIN}`;
    this.auth0.logout({
      returnTo: urlLogin
    });
  }

  signUpAndLogin(email?, password?, firstName?, lastName?, phoneCodeCountryIdentifier?, phoneNumber?, fn?) {
    if (firstName && lastName) {
      // name is in local storage to be later added to the user
      this.localStorageService.setNames(firstName, lastName);
    }
    if (phoneCodeCountryIdentifier) {
      this.localStorageService.setPhoneCodeCountryIdentifier(phoneCodeCountryIdentifier);
    }
    if (phoneNumber) {
      this.localStorageService.setPhoneNumber(phoneNumber);
    }
    this.auth0.redirect.signupAndLogin({
      connection: this.databaseConnection,
      email,
      password,
    }, (err: any) => {
      if (typeof err.description === 'string' || err.description instanceof String) {
        this.notifier.error(err.description);
        fn(err);
      } else if (err.name === 'PasswordStrengthError') {
        this.notifier.error(this.translate.instant('FORM_SIGN_UP.PASSWORD_STRENGTH_ERROR'));
      }
    });
  }

  checkSessionTimeout() {

    const expiresAt = this.localStorageService.getExpiresAt();
    const nowExpires = new Date().getTime();
    if (expiresAt) {
      if (Number(nowExpires) >= Number(expiresAt) - 280 * 1000) {
        this.dialog.open(this.timeOutComponent, {
          panelClass: this.responsiveService.getMatDialog(CLASS_MAT_DIALOG_TYPE.DIALOG_400)
        });
      }
    }

  }

  login(username?, password?) {
    this.auth0.crossOriginAuthentication.login({
      realm: this.databaseConnection,
      username,
      password,
      redirectUri: this.authOptions.redirectUri
    }, (err) => this.notifier.error(err.description));
  }

  resetPassword(email) {
    if (!email) {
      this.notifier.error('Please enter your email.');
      return;
    }
    this.auth0.changePassword({
      connection: this.databaseConnection,
      email
    }, (err, resp) => {
      if (err) {
        this.notifier.error(err.description);
      } else {
        this.notifier.success(resp);
      }
    });
  }

  loginWithAuth0() {
    this.auth0.authorize(this.authOptions);
  }

  loginWithGoogle() {
    this.auth0.authorize({
      connection: 'google-oauth2'
    });
  }

  loginWithLinkedIn() {
    this.auth0.authorize({
      connection: 'linkedin'
    });
  }

  loginWithMicrosoft() {
    this.auth0.authorize({
      connection: 'windowslive'
    });
  }

  crossOriginVerification() {
    this.auth0.crossOriginVerification();
  }

  crossOriginAuthenticationCallback() {
    this.auth0.crossOriginAuthentication.callback();
  }

  setSession(authResult) {
    // Set loggedIn flag in localStorage

    const expiresAt = new Date();
    expiresAt.setSeconds(expiresAt.getSeconds() + authResult.expiresIn);

    this.localStorageService.update(expiresAt.getTime(), authResult.accessToken, authResult.idToken);
  }

  checkAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    const expiresAt = this.localStorageService.getExpiresAt();
    const nowExpires = new Date().getTime();
    if (expiresAt) {
      if (Number(nowExpires) >= Number(expiresAt)) {
        this.localStorageService.remove();
        return false;
      }
    }
    return true;
  }

  isLoggedIn() {
    return this.localStorageService.isLoggedIn();
  }

  getUserProfile() {
    return this.localStorageService.getUserProfile();
  }
}
