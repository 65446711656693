import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../main/services/auth.service';
import {getRouteByRoles} from '../core/utils/util-functions';
import {EmbeddedAuthService} from '../main/services/embedded-auth.service';
import {LocalStorageService} from '../main/services/local-storage-service';
import {USER_AUTH} from '../core/utils/constants';
import {NotifierWithTemplateService} from '../main/services/notifier-with-template.service';
import {TranslateService} from '@ngx-translate/core';
import {InstanceService} from '../main/services/instance.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(private readonly auth: AuthService,
              private readonly embeddedAuth: EmbeddedAuthService,
              private readonly router: Router,
              private readonly notifier: NotifierWithTemplateService,
              private readonly translate: TranslateService,
              private localStorageService: LocalStorageService,
              public readonly instance: InstanceService) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return false;
    if (!this.embeddedAuth.checkAuthenticated()) {
      return true;
    }

    if (this.embeddedAuth.isUserProfile()) {
      const userProfile = this.localStorageService.getUserProfile();
      const roles = userProfile[USER_AUTH].roles;
      if (!roles.includes(this.instance.instanceConsultantRole) && !roles.includes(this.instance.instanceAdminRole)) {
        this.notifier.error(this.translate.instant('NOT_AUTHORIZED'));
        setTimeout(() => this.embeddedAuth.logout(), 2000);
        return true;
      }
      this.router.navigate([getRouteByRoles(roles, this.instance, this.localStorageService)]);
      return true;
    }
    return true;
  }

}
