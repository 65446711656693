import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ActivatedRoute} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HatchrAuthService extends HttpHandler {
  accessToken = '';

  constructor(
    private readonly next: HttpHandler,
    private readonly route: ActivatedRoute
  ) {
    super();

    this.route.queryParams.subscribe(params => {
      if (!params.accessToken) {
        throw Error('No access token provided');
      }
      this.accessToken = params.accessToken;
    });
  }

  handle(req: HttpRequest<any>): Observable<HttpEvent<any>> {
    const clone = req.clone({
      setHeaders: {Authorization: 'Bearer ' + this.accessToken}
    });
    return this.next.handle(clone);
  }
}
