export interface IDocumentType {
  id: number;
  name: string;
  totalDocs?: number;
  totalNotifs?: number;
}

export interface IDocument {
  id: number;
  filename: string;
  description: string;
  fileLocation: string;
  expirationDate: number;
  uploadDate: number;
  isContractFile: boolean;
  type?: IDocumentType;
  fileFormat: string;
  fileContentType: string;
  fileNumber?: string;
}

export interface IDocuments {
  docs: IDocument[];
  types: IDocumentType[];
}

export enum TypeDocument {
  IBAN = 'IBAN',
  SECURITY_SOCIAL = 'SECURITY_SOCIAL'
}

export interface IDocumentMassivePayslip {
  filename: string;
  document: any;
  documentType: string;
}

export interface IDocumentConsultantAssociation {
  subFileName: string;
  path: string;
  consultant: {
    id: number
    name: string;
    email: string;
  };
}

export interface IPayslipsCreationRequest {
  accountId: number;
  filename: string;
  documentPath: string;
  documentType: string;
  expirationDate: string;
}

export enum DocumentZipNature {
  DOCUMENT = 'DOCUMENT'
}

export interface IDocumentZipRequest {
  nature: DocumentZipNature;
  value: string;
}
