import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-delete-document-dialog',
  templateUrl: './delete-document-dialog.component.html',
  styleUrls: ['./delete-document-dialog.component.scss']
})
export class DeleteDocumentDialogComponent implements OnInit {


  formGroup: FormGroup;

  constructor(public translate: TranslateService,
              public dialogRef: MatDialogRef<DeleteDocumentDialogComponent>,
              private readonly fb: FormBuilder) {
  }

  ngOnInit() {
    this.formGroup = this.fb.group({

    });
  }

  cancelDocument() {
    this.dialogRef.close(false);
  }

  deleteDocument() {
    this.dialogRef.close(true);
  }

  get deleteLabel(): string {
    return this.translate.instant('');
  }
}
