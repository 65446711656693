import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ConsultantTemplateImportService} from '../../../../services/admin/consultant-template-import.service';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {finalize, map, switchMap} from 'rxjs/operators';
import {enumStatus, IImportResult, IPropertyMap, ITemplate} from '../../../../models/admin/csv-upload';
import {DEFAULT_FILE_ENCODING} from '../../../../../core/utils/constants';
import {LocalStorageService} from '../../../../services/local-storage-service';
import {AdminRoute, RoutesAdminConfig} from '../../../../../core/utils/routes';

@Component({
  selector: 'app-upload-csv',
  templateUrl: './upload-csv.component.html',
  styleUrls: ['./upload-csv.component.scss']
})
export class UploadCsvComponent implements OnInit {

  constructor(private readonly formBuilder: FormBuilder,
              private readonly router: Router,
              private readonly consultantsTemplateService: ConsultantTemplateImportService) {
  }

  @ViewChild('uploadFile', {static: true}) uploadFile: HTMLInputElement;
  uploadForm: FormGroup;
  status: enumStatus;
  fileInfo = '';
  isLoading: boolean;
  emptyColumns: number;

  private readonly _templates$: BehaviorSubject<ITemplate[]> = new BehaviorSubject([]);
  templates$: Observable<ITemplate[]> = this._templates$.asObservable();
  templateProperties$: Observable<IPropertyMap[]>;
  scrollUpdateSubject: Subject<void> = new Subject<void>();

  private static fetchHeaderColumns(csvRecords: string, separator = ';'): string[] {
    const headerRecord = csvRecords[0];
    return headerRecord.split(separator);
  }

  private emitScrollUpdateToChild() {
    this.scrollUpdateSubject.next();
  }

  ngOnInit(): void {
    this.status = enumStatus.initial;
    this.consultantsTemplateService.list().subscribe(templates => this._templates$.next(templates));
    this.uploadForm = this.formBuilder.group({
      templateId: ['', Validators.required],
      file: ['', Validators.required]
    });
    this.templateProperties$ = this.uploadForm.get('templateId').valueChanges.pipe(
      switchMap(templateId => this._templates$.asObservable()
        .pipe(
          map(templates => templates.filter((template: ITemplate) => template.id === templateId))
        )
      ),
      map(templates => {
        this.emitScrollUpdateToChild();
        return this.handleSortAndClearFile(templates[0].propertyMaps);
      }),
    );
  }

  handleSortAndClearFile(propertyMaps: IPropertyMap[]): IPropertyMap[] {
    this.status = enumStatus.initial;
    this.uploadForm.controls.file.setValue('');
    return propertyMaps.sort((a, b) => a.externalKey.localeCompare(b.externalKey));
  }

  uploadFileEvent(event): void {
    const previousStatus = this.status;
    this.status = enumStatus.loading;
    const files = event.srcElement.files as FileList;
    if (!files || (files && files.length === 0)) {
      this.status = previousStatus;
      return;
    }
    const file: File = files.item(0);
    if (file.type !== 'application/vnd.ms-excel' && file.type !== 'text/csv') {
      this.invalidFileType();
      return;
    }
    this.status = enumStatus.correctFile;
    this.fileInfo = `${file.name} - ${this.getFileSizeToDisplay(file.size)} Mb`;
    const reader: FileReader = new FileReader();
    reader.readAsText(file, DEFAULT_FILE_ENCODING);
    reader.onload = () => {
      const resultTreated = (reader.result as any).split(/\r\n|\n/);
      let headerColumns = UploadCsvComponent.fetchHeaderColumns(resultTreated);
      headerColumns = headerColumns.map(item => item.toUpperCase());
      this.countEmptyColumns(headerColumns);
      this.emptyColumns > 0 ? this.showEmptyColumns(this.emptyColumns, headerColumns) :
        (this.checkHeaderColumns(headerColumns) ? this.validateFile(file) : this.showInvalidColumnMapping());
    };
  }

  getFileSizeToDisplay(size: number): number {
    return Math.round((size / 1024 / 1024) * 100) / 100;
  }

  onSubmit(): void {
    this.isLoading = true;
    this.consultantsTemplateService.importConsultants(this.uploadForm.getRawValue()).pipe(
      finalize(() => this.isLoading = false),
    ).subscribe((importResult: IImportResult) =>
      this.router.navigate([`${AdminRoute.UPLOAD_RESULTS}/${importResult.id}`], {queryParamsHandling: 'preserve'}));
  }

  invalidFileType(): void {
    this.status = enumStatus.incorrectFile;
    this.fileInfo = 'INCORRECT_FILE_TYPE';
    this.uploadForm.controls.file.setValue(null);
  }

  validateFile(file): void {
    this.status = enumStatus.correctMapping;
    this.uploadForm.controls.file.setValue(file);
  }

  showInvalidColumnMapping(): void {
    this.status = enumStatus.incorrectMapping;
    this.fileInfo = 'MANDATORY_COLUMN_MISSING';
    this.uploadForm.controls.file.setValue(null);
  }

  showEmptyColumns(count: number, headerColumns: string[]): void {
    this.status = enumStatus.incorrectMapping;
    this.fileInfo = `The file contains ${count} empty columns\n${headerColumns.toString()}\nPlease remove them and try again`;
  }

  countEmptyColumns(headerColumns: string[]): void {
    this.emptyColumns = headerColumns.filter(column => column === '').length;
  }

  checkHeaderColumns(headerColumns: string[]): boolean {
    let columns = [];
    this.templates$.pipe(
      map(templates => templates.filter((template: ITemplate) => template.id === this.uploadForm.get('templateId').value)))
      .subscribe(([template]) => {
        columns = template.propertyMaps.map((item) => item.externalKey.toUpperCase());
      });
    return columns.every((item) => headerColumns.includes(item));
  }
}
