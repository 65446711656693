import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {EmbeddedAuthService} from '../../services/embedded-auth.service';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import MultiPlatform from '../../../../environments/multi-platform';
import {LocalStorageService} from '../../services/local-storage-service';
import {PublicRoute} from '../../../core/utils/routes';

@Component({
  selector: 'app-forgot-password-recovery',
  templateUrl: './forgot-password-recovery.component.html',
  styleUrls: ['./forgot-password-recovery.component.scss']
})
export class ForgotPasswordRecoveryComponent implements OnInit {
  message: any;
  email: any;
  dataCompany: any = MultiPlatform.get();
  emailSent = false;

  constructor(private readonly router: Router,
              private route: ActivatedRoute,
              private activatedRoute: ActivatedRoute,
              private readonly auth: AuthService,
              private readonly embeddedAuth: EmbeddedAuthService,
              private readonly translate: TranslateService,
              private readonly localStorageService: LocalStorageService) {
  }

  ngOnInit() {
    const message = this.translate.instant('FORM_FORGOT_PASSWORD.INSTRUCTIONS.DESCRIPTION');
    this.activatedRoute.queryParams
      .subscribe(params => {
          if (params.email) {
            this.email = params.email;
            this.message = message;
          } else {
            this.router.navigate([this.localStorageService.getPathForgotPassword()]);
          }
        }
      );
  }

  sendResetPasswordRequest() {
    this.message = this.message.replace('%email%', this.email);
    this.embeddedAuth.resetPassword(this.email);
    this.emailSent = true;
  }

  forgotPassword() {
    if (this.email) {
      this.embeddedAuth.resetPassword(this.email);
    } else {
      this.router.navigate([this.localStorageService.getPathForgotPassword()]);
    }
  }

  getLinkHome(): any {
    return PublicRoute.HOME;
  }
}
