import {ITrxType} from './transaction';
import {Country, DataCountry} from '../common/country';
import {IGender} from '../consultant/consultant';
import {IDocument} from '../common/document';

export interface IConsultantsSummaryInfo {
  accountSummaries: ConsultantSummaryData[];
  overallPaid: number;
  overallPending: number;
  balance: number;
}

export interface ConsultantSummaryData {
  accountId: number;
  identityId: string;
  firstName: string;
  lastName: string;
  email: string;
  balance: number;
  lastTransaction?: string;
  lastTransactionCategory?: string;
  paid?: number;
  pending?: number;
  status?: ConsultantStatus;
}

export enum ConsultantStatus {
  GUEST = 'guest',
  ACTIVE = 'active',
  INACTIVE = 'inactive'
}

export interface PersonalData {
  firstName: string;
  lastName: string;
  email?: string;
  email2?: string;
  ssnID?: string;
  street?: string;
  city?: string;
  country?: Country | DataCountry | number;
  postalCode?: string;
  phoneCodeCountryIdentifier?: string;
  mobile?: string;
  mobile2?: string;
  picture?: string;
  birthDate?: Date;
  birthPlace?: string;
  gender?: IGender;
  occupation?: string;
  status?: ConsultantStatus;
  optionCorrespondenceAddress?: boolean;
  correspondenceAddress?: string;
  correspondenceCity?: string;
  correspondenceCountry?: Country | DataCountry | number;
  correspondencePostalCode?: string;
  emergencyContactFirstName?: string;
  emergencyContactLastName?: string;
  emergencyPhoneCodeCountryIdentifier?: string;
  emergencyContactMobile?: string;
  iban?: string;
  optionSecuritySocial?: boolean;
  documentIban?: IDocument;
  documentSecuritySocial?: IDocument;
  language?: number;
  optionGeoLocation?: boolean;
}

export interface AccountData {
  name?: string;
  paySlipId: string;
  creationDate: Date;
  startDate: Date;
  active: boolean;
  nbStatement: number;
  taxClass: string;
  taxPercentage: number;
  rate: number;
  managementFee: IMngFee;
  status: ConsultantStatus;
}

export interface IMngFee {
  value: number;
  date?: Date;
  type: MngFeeTypeEnum;
}

export enum MngFeeTypeEnum {
  MONETARY = 'monetary',
  PERCENTAGE = 'percentage'
}

/**
 * the request is used in the creation request for a consultant
 */
export interface ConsultantCreationRequest {
  firstName: string;
  lastName: string;
  email: string;
  initialAccountValue: number;
  startDate: string;
  hasMealVoucher: boolean;
}

export interface StatementRegisteredTrxApiData {
  transactionId: string;
  type: ITrxType;
  valueDate: Date;
}

export interface MainStatementApiData {
  generationDate: string;
  balance: number;
  overallBalance: number;
  received: number;
  pending: number;
  registeredTransactions: StatementRegisteredTrxApiData[];
  minYearInvoice: number;
  maxYearInvoice: number;
  minYearStatament: number;
  maxYearStatament: number;
}

export interface AccountsAutocompleteView {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  status: ConsultantStatus;
}
