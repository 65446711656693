import {Injectable} from '@angular/core';
import MultiPlatform from '../../../../environments/multi-platform';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Country} from '../../models/common/country';

export const COUNTRY_URL = `${MultiPlatform.getDataEnvironmentHost()}api/consultants/countries`;
export const COUNTRY_URL_UNREGISTERED = `${MultiPlatform.getDataEnvironmentHost()}api/public/countries`;

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  constructor(private http: HttpClient) {
  }

  public getCountries(): Observable<Country[]> {
    return this.http.get<Country[]>(COUNTRY_URL);
  }

  public getCountriesUnregistered(): Observable<Country[]> {
    return this.http.get<Country[]>(COUNTRY_URL_UNREGISTERED);
  }

  public getCountriesByOrderPersonalData(): Observable<Country[]> {
    return this.http.get<Country[]>(`${COUNTRY_URL}/personal-data`);
  }

  public getCountriesAndHolidays(): Observable<Country[]> {
    return this.http.get<Country[]>(`${COUNTRY_URL}/holiday`);
  }

}
