import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {CountryService} from '../../../../services/common/country.service';
import {Country} from '../../../../models/common/country';
import {take} from 'rxjs/operators';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../../../../services/consultant/user.service';
import {ConsultantIdentityService} from '../../../../services/consultant/consultant-identity.service';
import {forkJoin, Observable} from 'rxjs';
import {EditProfileRequest, PersonalData} from '../../../../models/consultant/consultant';
import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {checkErrorInput, MyErrorStateMatcher} from '../../../../../core/utils/validators';
import {MOBILE, SPINNER_NAME} from '../../../../../core/utils/constants';
import {NotifierWithTemplateService} from '../../../../services/notifier-with-template.service';
import {DocumentsService} from '../../../../services/common/documents.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {SidenavService} from '../../../../services/admin/sidenav.service';
import {APP_DATE_FORMATS, AppDateAdapter} from '../../../../../core/formatters/format-datepicker';

@Component({
  selector: 'app-complete-your-profile-modal',
  templateUrl: './complete-your-profile-modal.component.html',
  styleUrls: ['./complete-your-profile-modal.component.scss'],
  providers: [
    {
      provide: DateAdapter, useClass: AppDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
  ]
})
export class CompleteYourProfileModalComponent implements OnInit {

  countries$: Observable<Country[]>;
  countries: Country[];
  country: Country;

  @ViewChild('inputDataFirstName', {static: false, read: ElementRef}) inputDataFirstName: ElementRef;
  @ViewChild('inputDataLastName', {static: false, read: ElementRef}) inputDataLastName: ElementRef;
  @ViewChild('inputDataBirth', {static: false, read: ElementRef}) inputDataBirth: ElementRef;
  @ViewChild('fileInputIban', {static: false}) fileInputIban: ElementRef<HTMLInputElement>;
  @ViewChild('fileInputSecuritySocial', {static: false}) fileInputSecuritySocial: ElementRef<HTMLInputElement>;

  profile: PersonalData;
  profileForm: FormGroup;
  maxDate: Date;
  public someDate: Date;

  consultantFilter = new FormControl('');
  errorStateMatcher = new MyErrorStateMatcher();
  notFilled = false;
  showSSNID = false;

  emptyDocumentErrorIban = false;
  emptyDocumentErrorSecuritySocial = false;
  invalidSizeError = false;

  ibanAlreadyExists = false;
  securitySocialAlreadyExists = false;

  formDataIban = null;
  formDataSecuritySocial = null;

  constructor(public dialogRef: MatDialogRef<CompleteYourProfileModalComponent>,
              private readonly countryService: CountryService,
              private readonly consultantIdService: ConsultantIdentityService,
              private readonly fb: FormBuilder,
              private readonly userService: UserService,
              private readonly notifier: NotifierWithTemplateService,
              private readonly dialog: MatDialog,
              private documentService: DocumentsService,
              private readonly spinner: NgxSpinnerService,
              private readonly translate: TranslateService,
              private readonly sidenav: SidenavService) {
    const currentDate = new Date();
    this.maxDate = new Date(currentDate.getFullYear() - 18, currentDate.getMonth(), currentDate.getDate());
  }

  get mobileFC(): FormControl {
    return this.profileForm ? this.profileForm.controls.mobile as FormControl : null;
  }

  ngOnInit() {
    this.initForm();
    this.getProfileAndCountries();
  }

  getProfileAndCountries() {
    forkJoin([
      this.userService.getUserProfile(this.consultantIdService.getConsultantId()),
      this.countryService.getCountries()
    ]).subscribe(([profile, countries]) => {
      this.countries = countries;

      Object.keys(this.profileForm.controls).forEach(key => {
        this.profileForm.get(key).setValue(profile[key] !== null ? profile[key] : '');
      });

      if (profile.mobile) {
        if (profile.mobile.toString().substring(0, 4) === '+352') {
          this.profileForm.controls.phoneCodeCountryIdentifier.setValue('LU');
          this.profileForm.controls.mobile.setValue(profile.mobile.toString().substring(4).replace(/\s/g, ''));
        } else if (profile.mobile.toString().substring(0, 3) === '352') {
          this.profileForm.controls.phoneCodeCountryIdentifier.setValue('LU');
          this.profileForm.controls.mobile.setValue(profile.mobile.toString().substring(3).replace(/\s/g, ''));
        } else if (profile.mobile.toString().substring(0, 3) === '+33') {
          this.profileForm.controls.phoneCodeCountryIdentifier.setValue('FR');
          this.profileForm.controls.mobile.setValue(profile.mobile.toString().substring(3).replace(/\s/g, ''));
        } else if (profile.mobile.toString().substring(0, 2) === '33') {
          this.profileForm.controls.phoneCodeCountryIdentifier.setValue('FR');
          this.profileForm.controls.mobile.setValue(profile.mobile.toString().substring(2).replace(/\s/g, ''));
        }
      }

    });
  }

  saveInfo(): void {

    checkErrorInput(this.profileForm.controls.firstName, this.inputDataFirstName);
    checkErrorInput(this.profileForm.controls.lastName, this.inputDataLastName);
    this.checkPrefix(MOBILE);

    if (this.profileForm.invalid) return;
    const editProfile: EditProfileRequest = this.profileForm.value;
    editProfile.mobile = this.profileForm.controls.mobile.value.toString().replace(/\s/g, '');

    this.userService.completeProfile(this.consultantIdService.getConsultantIdentity().id, editProfile).pipe(take(1))
      .subscribe(() => {
        this.spinner.hide(SPINNER_NAME);
        this.notifier.success(this.translate.instant('VIEW.SUCCESS.UPDATE_PERSONAL_DATA'));
        this.dialogRef.close();
      }, () => {
        this.spinner.hide(SPINNER_NAME);
        this.notifier.error(this.translate.instant('VIEW.ERROR.UPDATE_SERVER'));
      });
  }

  changeCountry(_country: Country) {
    this.country = _country;
  }

  toggleMenu(): void {
    this.sidenav.toggle();
  }

  checkPrefix(key: string) {
    this.countries.forEach(country => {
      if (country && this.profileForm.controls[key] &&
        this.profileForm.controls[key].value &&
        this.profileForm.controls[key].value.toString().includes(country.phoneCode)
      ) {
        this.profileForm.controls[key].setErrors({containsCode: true});
        this.profileForm.controls[key].markAsTouched();
      }
    });
  }

  private initForm(): void {
    this.profileForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      phoneCodeCountryIdentifier: ['', [Validators.required]],
      mobile: ['', [Validators.required]],
    });
  }
}
