import {Injectable} from '@angular/core';
import {LocalStorageService} from '../local-storage-service';
import {UserSettingsService} from "./user-settings.service";
import {take} from "rxjs/operators";
import MultiPlatform from "../../../../environments/multi-platform";

@Injectable({
  providedIn: 'root'
})
export class ScreebSurveyService {
  private readonly screebWebsiteID = MultiPlatform.getDataEnvironmentScreebWebsiteID();

  constructor(private readonly localStorageService: LocalStorageService,
              private readonly userSettingsService: UserSettingsService) {
  }

  public initSurvey(userProfile: any, surveyScript: HTMLScriptElement): void {
    if (!userProfile) {
      return;
    }

    surveyScript.type = 'text/javascript';

    this.userSettingsService.getSurveyIdentityData(userProfile.sub)
      .pipe(take(1))
      .subscribe(surveyData => {
        const surveySession = {
          identity: {
            id: userProfile.sub,
            properties: {
              plan: this.localStorageService.getNameCompany(),
              last_seen_at: new Date(),
              authenticated: true,
              ...surveyData
            }
          }
        };

        surveyScript.text = `$screeb('init', '${this.screebWebsiteID}', ${JSON.stringify(surveySession)});`;
        document.body.appendChild(surveyScript);
      }, (e) => console.error(e))
  }

  public resetSurveyIdentity() {
    const surveyScript = document.createElement('script');
    surveyScript.type = 'text/javascript';
    surveyScript.text = '$screeb(\'identity.reset\');';
    document.body.appendChild(surveyScript);
  }
}
