import {Component, OnInit, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {DOCUMENT} from '@angular/common';
import {
  ISalarySimulatorHbsptUrlRequest
} from '../../../../../../core/utils/SimulatorSalary';
import {SalarySimService} from '../../../../../services/consultant/salary-sim.service';
import {TranslateService} from '@ngx-translate/core';
import {CustomCurrencyPipe} from '../../../../../../core/formatters/custom-pipes/custom-currency.pipe';
import MultiPlatform from '../../../../../../../environments/multi-platform';

declare var hbspt: any;
declare var $: any;

@Component({
  selector: 'app-dialog-hbspt',
  templateUrl: './dialog-hbspt.component.html',
  styleUrls: ['./dialog-hbspt.component.scss']
})
export class DialogHbsptComponent implements OnInit {
  idPdfSalarySimulator = '';
  urlPdfSalarySimulator = '';
  monthlyGrossSalary = '';
  durationOfTheMissionInMonths = '';
  dailyRate = '';
  message = '';
  cancelButtonText = 'Cancel';

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    @Inject(DOCUMENT) private _document,
    private dialogRef: MatDialogRef<DialogHbsptComponent>,
    private readonly salarySimService: SalarySimService,
    private readonly translate: TranslateService) {
    this.message = data.message;
    this.idPdfSalarySimulator = data.idPdfSalarySimulator;
    this.urlPdfSalarySimulator = data.urlPdfSalarySimulator;
    this.monthlyGrossSalary = data.monthlyGrossSalary;
    this.durationOfTheMissionInMonths = data.durationOfTheMissionInMonths;
    this.dailyRate = data.dailyRate;
    if (data.buttonText) {
      this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
    }
    this.dialogRef.updateSize('600vw', '300vw');
    this.dialogRef.updatePosition({top: '50px', left: '50px'});

  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }

  getValueCurrency(value: any): any {
    const data = MultiPlatform.get();
    const price = new CustomCurrencyPipe().transform(Number(value.monthlyGrossSalary),
      data.currencyCode,
      data.display,
      data.digitsInfo,
      data.locale);
    return price;
  }

  ngOnInit(): void {
    const dataFormInput = this;
    const idPdfSalarySimulator = this.idPdfSalarySimulator;
    const savePdf = this.salarySimService;
    hbspt.forms.create({
      region: 'na1',
      portalId: '9174638',
      formId: '1b1f79ff-174b-41b7-a40d-9edba40c1226',
      target: '#hsformContainer',
      css: '',
      cssRequired: '',
      locale: MultiPlatform.get().locale2,
      translations: {
        fr: {
          submitText: dataFormInput.translate.instant('CONSULTANT.SALARY_SIMULATOR.BTN_POPUP'),
        }
      },
      onFormReady($form) {
        const urlPdfLegend = '.hs_url_pdf_salary_simulator legend';
        $('input[name="firstname"]').attr('placeholder', dataFormInput.translate.instant('CONSULTANT.SALARY_SIMULATOR.FIRST_NAME'));
        $('input[name="lastname"]').attr('placeholder', dataFormInput.translate.instant('CONSULTANT.SALARY_SIMULATOR.LAST_NAME'));
        $('input[name="email"]').attr('placeholder', dataFormInput.translate.instant('CONSULTANT.SALARY_SIMULATOR.EMAIL'));

        $(urlPdfLegend).html(dataFormInput.urlPdfSalarySimulator);

        $('input[name="daily_rate"]').val(dataFormInput.dailyRate);
        $('input[name="duration_of_the_mission_in_months"]').val(dataFormInput.durationOfTheMissionInMonths);
        $('input[name="url_pdf_salary_simulator"]').val(dataFormInput.urlPdfSalarySimulator);
        $('input[name="monthly_gross_salary"]').val(dataFormInput.monthlyGrossSalary);

        $('.hs_url_pdf_salary_simulator  span').html(`${dataFormInput.translate.instant('CONSULTANT.SALARY_SIMULATOR.URL_PDF')}: `);
        $('.hs_monthly_gross_salary span').html(`${dataFormInput.translate.instant('CONSULTANT.SALARY_SIMULATOR.INVOICED_AMOUNT_POPUP')}: `);
        $('.hs_duration_of_the_mission_in_months span').html(`${dataFormInput.translate.instant('CONSULTANT.SALARY_SIMULATOR.MISSION_DURATION')}: `);
        $('.hs-daily_rate span').html(`${dataFormInput.translate.instant('CONSULTANT.SALARY_SIMULATOR.DAILY_RATE')}: `);

        $('.hs_monthly_gross_salary legend').html(dataFormInput.monthlyGrossSalary);
        $('.hs_duration_of_the_mission_in_months legend').html(`${dataFormInput.durationOfTheMissionInMonths} ${dataFormInput.translate.instant('CONSULTANT.SALARY_SIMULATOR.P_MONTH')}`);
        $('.hs-daily_rate legend').html(dataFormInput.dailyRate);

      }, onFormSubmit($form) {
        const dataFormSubmit: ISalarySimulatorHbsptUrlRequest = {
          firstname: $('input[name="firstname"]').val(),
          lastname: $('input[name="lastname"]').val(),
          email: $('input[name="email"]').val(),
          id: idPdfSalarySimulator
        };
        savePdf.saveSalarySimulator(dataFormSubmit);
      }
    });
  }
}
