import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NavBarComponent} from './nav-bar.component';
import {ProfileLinkComponent} from './profile-link/profile-link.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {FlexModule} from '@angular/flex-layout';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule} from '@angular/router';
import { NewsLinkComponent } from './news-link/news-link.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatBadgeModule} from '@angular/material/badge';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {MatDividerModule} from '@angular/material/divider';
import { DefaultPictureComponent } from './profile-link/default-picture/default-picture.component';


@NgModule({
  declarations: [
    NavBarComponent,
    ProfileLinkComponent,
    NewsLinkComponent,
    DefaultPictureComponent
  ],
  imports: [
    CommonModule,
    MatToolbarModule,
    FlexModule,
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    TranslateModule,
    RouterModule,
    MatMenuModule,
    MatBadgeModule,
    PerfectScrollbarModule,
    ScrollingModule,
    MatDividerModule
  ],
  exports: [
    NavBarComponent,
    ProfileLinkComponent,
    DefaultPictureComponent
  ]
})
export class NavBarModule {
}
