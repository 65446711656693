import {
  AccountData,
  AccountsAutocompleteView,
  ConsultantCreationRequest,
  ConsultantStatus,
  ConsultantSummaryData,
  IConsultantsSummaryInfo,
  MainStatementApiData,
  PersonalData
} from '../../models/admin/consultant-account';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import MultiPlatform from '../../../../environments/multi-platform';
import {IdentitiesMapping} from '../../models/admin/identities-mapping';
import {catchError, switchMap, take, tap} from 'rxjs/operators';
import {NotifierWithTemplateService} from '../notifier-with-template.service';
import {TranslateService} from '@ngx-translate/core';
import {EmbeddedAuthService} from '../embedded-auth.service';
import {DocumentExtensionTypes} from '../../models/consultant/statement';
import {DOCUMENT} from '@angular/common';
import {getAbrvFullnameByPersonalData} from '../../../core/utils/util-functions';
import {RolesEnum} from '../../models/admin/auth0';

@Injectable()
export class ConsultantAccountService {

  private readonly consultantsUrl: string;
  private readonly consultantUrlTemplate: string;
  private readonly personalDataUrlTemplate: string;
  private readonly mainStatementUrlTemplate: string;
  private readonly accountUrlTemplate: string;
  private readonly consultantDeleteTemplate: string;
  private readonly archivedRecordsTemplate: string;
  private readonly identitiesMappingTemplate: string;
  private readonly accountsAutocompleteUrl: string;
  private readonly personalDataAuthUrlTemplate: string;

  private readonly consultantIdStr = '{consultantId}';
  private readonly authIdStr = '{authId}';

  constructor(private readonly http: HttpClient,
              private readonly notifier: NotifierWithTemplateService,
              private readonly translate: TranslateService,
              private readonly auth: EmbeddedAuthService,
              @Inject(DOCUMENT) private document: Document) {
    this.consultantsUrl = `${MultiPlatform.getDataEnvironmentHost()}api/admin/accounts`;
    this.consultantUrlTemplate = `${this.consultantsUrl}/{consultantId}`;
    this.personalDataUrlTemplate = `${this.consultantUrlTemplate}/personal-data`;
    this.personalDataAuthUrlTemplate = `${this.consultantsUrl}/auth/{authId}/personal-data`;
    this.mainStatementUrlTemplate = `${this.consultantUrlTemplate}/main-statement`;
    this.accountUrlTemplate = `${this.consultantUrlTemplate}`;
    this.consultantDeleteTemplate = `${this.consultantUrlTemplate}/delete`;
    this.archivedRecordsTemplate = `${this.consultantsUrl}/archived-records`;
    this.identitiesMappingTemplate = `${MultiPlatform.getDataEnvironmentHost()}api/admin/mapping/identities-mapping`;
    this.accountsAutocompleteUrl = `${this.consultantsUrl}/autocomplete`;
  }

  getIdentitiesMappingList(status: ConsultantStatus = null): Observable<IdentitiesMapping[]> {
    let params = new HttpParams();
    if (status) params = params.set('status', status);
    return this.http.get<IdentitiesMapping[]>(this.getUrlIdentitiesMappingList(), {params});
  }

  getIdentitiesMapping(id: string): Observable<IdentitiesMapping> {
    return this.http.get<IdentitiesMapping>(this.getUrlIdentitiesMapping(encodeURIComponent(id)));
  }

  createConsultant(consultant: ConsultantCreationRequest): Observable<string> {
    return this.http.post<string>(this.getUrlConsultants(), consultant);
  }

  getConsultants(status: ConsultantStatus = null, role: RolesEnum = null): Observable<IConsultantsSummaryInfo> {
    let params = new HttpParams();
    if (status !== null) params = params.set('status', status);
    if (role !== null) params = params.set('role', role);
    return this.http.get<IConsultantsSummaryInfo>(this.getUrlConsultants(), {params});
  }

  getIdentitiesMappingByAuthId(authUserId: string): Observable<IdentitiesMapping> {
    return this.http.get<IdentitiesMapping>(this.getUrlAuthIdPersonalData(encodeURIComponent(authUserId)));
  }

  getPersonalData(consultantId: string): Observable<PersonalData> {
    return this.http.get<PersonalData>(this.getUrlPersonalData(parseInt(consultantId, 10)));
  }

  updatePersonalData(consultantId: number, profileUpdate: PersonalData): Observable<PersonalData> {
    const adminResponsible = this.auth.getUserProfile().email;
    return this.http.put<PersonalData>(this.getUrlPersonalData(consultantId), {profileUpdate, adminResponsible});
  }

  getMainStatement(accountId: number, year: number, month: number): Observable<MainStatementApiData> {
    if (year === 0) {
      return this.http.get<MainStatementApiData>(this.getUrlMainStatement(accountId));
    }
    return this.http.get<MainStatementApiData>(`${this.getUrlMainStatement(accountId)}/${year}/${month}`);
  }

  getAccountData(accountId: string): Observable<AccountData> {
    return this.http.get<AccountData>(this.getUrlConsultantAccount(parseInt(accountId, 10)));
  }

  updateAccount(accountId: number, accountData: AccountData): Observable<AccountData> {
    const adminResponsible = this.auth.getUserProfile().email;
    const url = this.getUrlConsultantAccount(accountId);
    return this.http.put<AccountData>(url, {accountData, adminResponsible});
  }

  deleteConsultant(consultantId: number, changeStatusRequest): Observable<boolean> {
    return this.http.patch<boolean>(this.getUrlDeleteConsultant(consultantId), changeStatusRequest);
  }

  getArchivedRecords(): Observable<[ConsultantSummaryData]> {
    return this.http.get<[ConsultantSummaryData]>(this.getUrlArchivedRecords());
  }

  updateIdentitiesMapping(mappingId: string, identitiesMapping: IdentitiesMapping): Observable<IdentitiesMapping> {
    const url = this.getUrlIdentitiesMapping(encodeURIComponent(mappingId));
    return this.http.put<IdentitiesMapping>(url, identitiesMapping);
  }

  updateConsultantStatus(id: number, status: ConsultantStatus, consultant: string): Observable<void> {
    const adminResponsible = this.auth.getUserProfile().email;
    return this.http.patch<void>(this.getUrlConsultantAccount(id), {adminResponsible, status})
      .pipe(
        switchMap(() => this.translate.get('CHANGE_STATUS.SUCCESS', {consultant, status})
          .pipe(tap(message => this.notifier.success(message)))
        ),
        catchError(() => this.translate.get('CHANGE_STATUS.ERROR', {consultant, status}).pipe(
          take(1),
          tap(error => this.notifier.error(error))
        ))
      );
  }

  statusEnrollment(id: number, status: boolean): Observable<void> {
    return this.http.patch<void>(`${this.identitiesMappingTemplate}/${id}/status`, {status});
  }

  getAccountsForAutocomplete(): Observable<AccountsAutocompleteView[]> {
    return this.http.get<AccountsAutocompleteView[]>(this.accountsAutocompleteUrl);
  }

  private getUrlConsultantAccount(id: number): string {
    return this.accountUrlTemplate.replace(this.consultantIdStr, String(id));
  }

  private getUrlPersonalData(id: number): string {
    return this.personalDataUrlTemplate.replace(this.consultantIdStr, String(id));
  }

  private getUrlMainStatement(id: number): string {
    return this.mainStatementUrlTemplate.replace(this.consultantIdStr, String(id));
  }

  private getUrlConsultants(): string {
    return this.consultantsUrl;
  }

  private getUrlDeleteConsultant(id: number): string {
    return this.consultantDeleteTemplate.replace(this.consultantIdStr, String(id));
  }

  private getUrlArchivedRecords(): string {
    return this.archivedRecordsTemplate;
  }

  private getUrlIdentitiesMappingList(): string {
    return this.identitiesMappingTemplate;
  }

  private getUrlIdentitiesMapping(id: string): string {
    return `${this.identitiesMappingTemplate}/${id}`;
  }

  exportPersonalData(accountId: number, personalData: PersonalData, type: DocumentExtensionTypes) {
    const documentName = `${getAbrvFullnameByPersonalData(personalData)}-personal-data-${MultiPlatform.get().abrvCompany}.${type}`.toString().toLocaleLowerCase();

    return this.http.get(this.getExportPersonalDataUrl(accountId, type), {responseType: 'blob', observe: 'response'}).pipe(
      tap((response: HttpResponse<any>) => {
        const downloadUrl = window.URL.createObjectURL(response.body);
        const link = this.document.createElement('a');
        link.href = downloadUrl;
        link.download = documentName;
        link.click();
      }),
      catchError(() => {
        this.translate.get(`PERSONAL_DATA.EXPORT.ERROR`).pipe(take(1))
          .subscribe(this.notifier.error);
        return of(false);
      })
    );
  }

  private getExportPersonalDataUrl(accountId: number, documentType: DocumentExtensionTypes): string {
    return this.getUrlPersonalData(accountId) + '/download/' + documentType.toString();
  }

  private getUrlAuthIdPersonalData(authId: string): string {
    return this.personalDataAuthUrlTemplate.replace(this.authIdStr, authId);
  }
}



