import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {Observable} from 'rxjs';
import {IAuth0Profile, RoleToRouteEnum} from '../../models/admin/auth0';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {IRouteData} from '../../models/admin/routes-data';
import {filter, map, mergeMap} from 'rxjs/operators';
import {LocalStorageService} from '../../services/local-storage-service';
import {USER_AUTH} from '../../../core/utils/constants';
import {InstanceService} from '../../services/instance.service';
import {LangConf} from '../../../core/utils/languages';
import {Platform} from '../../../core/utils/global';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
  @Input() sNav: MatSidenav;
  @Input() isMobile: boolean;
  @Input() userProfile$: Observable<IAuth0Profile>;
  @Output() logout: EventEmitter<any> = new EventEmitter();

  routeData: IRouteData;

  constructor(private readonly titleService: Title,
              private readonly route: ActivatedRoute,
              private readonly router: Router,
              private localStorageService: LocalStorageService,
              public readonly instance: InstanceService,
              public readonly platform: Platform) {
  }

  ngOnInit() {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.route),
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
    ).subscribe((routeData: IRouteData) => this.routeData = routeData);
  }

  get title() {
    return this.titleService.getTitle();
  }

  goBack(backRoute): void {
    this.router.navigateByUrl(backRoute);
  }

  doLogout(): void {
    this.localStorageService.logout();
    this.logout.emit();
  }

  isRouteAdmin(): boolean {
    return this.router.url.includes(RoleToRouteEnum.ADMIN);
  }

  goToAdminOrConsultants() {
    let role = RoleToRouteEnum.ADMIN;
    if (this.isRouteAdmin()) {
      role = RoleToRouteEnum.CONSULTANT;
    }
    LangConf.navigateByUrlLanguage(this.platform, role, this.router, this.localStorageService);
  }

  canShowLink(profile: IAuth0Profile): boolean {
    return profile[USER_AUTH].roles.includes(
      this.isRouteAdmin() ? this.instance.instanceConsultantRole : this.instance.instanceAdminRole
    );
  }
}
