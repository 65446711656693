import {CustomErrors} from './custom-message';

export interface AccountStatementApiData {
  generationDate: Date;
  balance: number;
  overallBalance: number;
  receivedStatement: number;
  receivedInvoice: number;
  pending: number;
  paid: number;
  minYearInvoice?: number;
  maxYearInvoice?: number;
  minYearStatament?: number;
  maxYearStatament?: number;
  registeredTransactions: StatementTrxApiData[];
}

export interface StatementTrxApiData {
  transactionId: string;
  type: TransactionTypeApi;
  valueDate: Date;
  creationDate: Date;
  creditDebit: CreditDebitEnum;
  amount: number;
  descComments?: string;
  businessInfo?: string;
}

export interface TransactionTypeApi {
  id?: number;
  typeName: string;
  creditDebit: CreditDebitEnum;
}

export enum CreditDebitEnum {
  DEBIT = 'DEBIT', CREDIT = 'CREDIT', BOTH_POSSIBLE = 'BOTH_POSSIBLE'
}

export interface AccountStatementApiDataResponse {
  status?: number;
  response?: AccountStatementApiData;
  errors?: CustomErrors;
}

export interface ITransaction {
  id?: 12756;
  amount: number;
  creationDate: Date;
  valueDate: Date;
  creditOrDebit: CreditDebitEnum;
  transactionType: TransactionTypeApi;
}

export enum StatementDocumentType {
  PDF = 'pdf',
  CSV = 'csv',
  EXCEL = 'xlsx'
}

export enum DocumentExtensionTypes {
  PDF = 'pdf',
  CSV = 'csv',
  EXCEL = 'xlsx'
}
