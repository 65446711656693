import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DocumentsRoutingModule} from './documents-routing.module';
import {DocumentsComponent} from './documents.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {FlexLayoutModule} from '@angular/flex-layout';
import {TranslateModule} from '@ngx-translate/core';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {MatTableModule} from '@angular/material/table';
import {MatIconModule} from '@angular/material/icon';
import {MatSortModule} from '@angular/material/sort';
import {CustomPipesModule} from '../../../../../core/formatters/custom-pipes/custom-pipes.module';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {NoResultsModule} from '../../../admin/pages/common/no-results/no-results.module';
import {MatPaginatorModule} from '@angular/material/paginator';
import {AddDocumentModule} from '../../../admin/pages/consultant/list/consultant-view/documents/add-document/add-document.module';
import {AddDocumentComponent} from '../../../admin/pages/consultant/list/consultant-view/documents/add-document/add-document.component';
import {InlineSVGModule} from 'ng-inline-svg';
import {MatListModule} from '@angular/material/list';
import {MatBadgeModule} from '@angular/material/badge';
import {MatInputModule} from '@angular/material/input';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ViewDocumentModalComponent} from '../../../common/modals/view-document-modal/view-document-modal.component';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {MatDialogModule} from '@angular/material/dialog';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {ComponentsModule} from '../../../components/components.module';

@NgModule({
  declarations: [
    DocumentsComponent,
    ViewDocumentModalComponent
  ],
  imports: [
    CommonModule,
    DocumentsRoutingModule,
    NgxSpinnerModule,
    FlexLayoutModule,
    TranslateModule,
    PerfectScrollbarModule,
    MatTableModule,
    MatIconModule,
    MatSortModule,
    CustomPipesModule,
    MatMenuModule,
    MatButtonModule,
    MatDividerModule,
    NoResultsModule,
    MatPaginatorModule,
    AddDocumentModule,
    InlineSVGModule,
    MatListModule,
    MatBadgeModule,
    MatInputModule,
    MatTooltipModule,
    PdfViewerModule,
    MatDialogModule,
    MatCheckboxModule,
    ComponentsModule
  ],
  entryComponents: [
    AddDocumentComponent,
    ViewDocumentModalComponent
  ]
})
export class DocumentsModule {
}
