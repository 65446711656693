import {Component, Input, OnInit} from '@angular/core';
import {Platform} from '../../../../core/utils/global';
import {LocalStorageService} from '../../../services/local-storage-service';
import {Router} from '@angular/router';
import {RoleToRouteEnum} from '../../../models/admin/auth0';
import {LangConf} from '../../../../core/utils/languages';

@Component({
  selector: 'app-menu-change-language',
  templateUrl: './menu-change-language.component.html',
  styleUrls: ['./menu-change-language.component.scss']
})
export class MenuChangeLanguageComponent implements OnInit {

  @Input() role: RoleToRouteEnum;

  constructor(public readonly platform: Platform,
              private readonly router: Router,
              public localStorageService: LocalStorageService) { }

  ngOnInit() {
  }

  applyLanguage(lang: any) {
    LangConf.changeLanguage(this.platform, lang, this.role, this.router);
  }
}
