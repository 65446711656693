export class AdminRoute {
  public static ADMIN = 'admin';
  public static CHANGE_PASSWORD = 'change-password';
  public static NOTIFICATIONS = 'notifications';
  public static CONTRACTS = 'contracts';
  public static CONTACTS = 'contacts';
  public static DOCUMENTS = 'documents';
  public static PROFILE = 'profile';
  public static INVOICES = 'invoices';
  public static STATEMENTS = 'statements';
  public static TIME_SHEET = 'time-sheets';
  public static BULK_IMPORT = 'bulk-import';
  public static FAQS = 'faqs';
  public static SALARY_SIMULATION = 'salary-simulator';
  public static YEAR_PROJECTION = 'year-projection';
  public static HOLIDAYS = 'holidays';
  public static HOLIDAY_REQUESTS = 'holiday-requests';
  public static BULK_TEMP_MODELS = 'bulk-temp-models';
  public static UPLOAD_RESULTS = 'upload-results';
}

export enum AdminBfnMngRouteEnum {
  BNF_MNG = 'bnf-mng',
  FUEL_CARDS = 'fuel-cards',
  PHONES = 'phones',
  TRX_BTX = 'trx-btx',
  TRX_BTX_JOB_ROWS = 'trx-btx/job-rows/:id',
}

export class AdminBfnMngRoute {

  public static FUEL_CARDS = AdminBfnMngRoute.getPath(AdminBfnMngRouteEnum.FUEL_CARDS);
  public static PHONES = AdminBfnMngRoute.getPath(AdminBfnMngRouteEnum.PHONES);
  public static TRX_BTX = AdminBfnMngRoute.getPath(AdminBfnMngRouteEnum.TRX_BTX);

  public static getPath(value: string): string {
    return `${AdminBfnMngRouteEnum.BNF_MNG}/${value}`;
  }

  public static getBackRoute(value: string): string {
    return `${AdminRoute.ADMIN}/${AdminBfnMngRouteEnum.BNF_MNG}/${value}`;
  }
}

export enum RoutesAdminConsultantsQuerystringEnum {
  DATA_PERSONAL = 'data-personal',
  REQUESTS = 'requests',
  DOCUMENTS = 'documents',
}

export class RoutesAdminConsultants {

  public static PATH = 'c';
  public static VIEW = 'view';

  public static CONSULTANTS = RoutesAdminConsultants.getPath('list');
  public static CONSULTANTS_VIEW = `${RoutesAdminConsultants.CONSULTANTS}/${RoutesAdminConsultants.VIEW}`;
  public static CONSULTANTS_VIEW_ID = `${RoutesAdminConsultants.CONSULTANTS}/${RoutesAdminConsultants.VIEW}/:id`;
  public static ARCHIVED = RoutesAdminConsultants.getPath('archived');
  public static ENROLLMENT = RoutesAdminConsultants.getPath('enrollment');
  public static ENROLLMENT_ID = `${RoutesAdminConsultants.ENROLLMENT}/:id`;
  public static INVITES = RoutesAdminConsultants.getPath('invites');
  public static REQUESTS = RoutesAdminConsultants.getPath('requests');
  public static PAYSLIP_MASSIVE_UPLOAD = RoutesAdminConsultants.getPath('payslip-massive-upload');
  public static CREATE_CONSULTANT = RoutesAdminConsultants.getPath('create');

  public static getPath(value: string): string {
    return `${RoutesAdminConsultants.PATH}/${value}`;
  }

  public static getBackRoute(value: string): string {
    return `${AdminRoute.ADMIN}/${RoutesAdminConsultants.PATH}/${value}`;
  }
}

export class ConsultantRoute {
  public static CONSULTANT = 'consultant';
  public static HOME = 'home';
  public static SIMULATION = 'simulation';
  public static SIMULATION_PDF = 'simulation.pdf';
  public static CONTACTS = 'contacts';
  public static DOCUMENTS = 'documents';
  public static PROFILE = 'profile';
  public static CHANGE_PASSWORD = 'change-password';
  public static NOTIFICATIONS = 'notifications';
  public static CONTRACTS = 'contracts';
  public static INVOICES = 'invoices';
  public static STATEMENTS = 'statements';
  public static TIME_SHEET = 'time-sheets';
  public static FAQS = 'faqs';
  public static SALARY_SIMULATION = 'salary-simulator';
  public static YEAR_PROJECTION = 'year-projection';
  public static HOLIDAYS = 'holidays';
  public static HOLIDAY_REQUESTS = 'holiday-requests';
}

export class PublicRoute {
  public static PARAM_INVITATION_ID = 'invitationId';

  public static LOGIN = 'login';
  public static SIGNUP = 'signup';
  public static SIGNUP_ID = `${PublicRoute.SIGNUP}/:${PublicRoute.PARAM_INVITATION_ID}`;
  public static CALLBACK = 'callback';
  public static SUPPORTED_BROWSERS = 'supported-browsers';
  public static FORGOT_PASSWORD = 'forgot-password';
  public static FORGOT_PASSWORD_RECOVERY = 'forgot-password-recovery';
  public static AUTH_FALLBACK = 'auth-fallback';
  public static SIMULATION = 'simulation';
  public static SIMULATION_PDF = 'simulation.pdf';

  public static HOME = '/';
}

export class LangRoute {
  public static RESOLVER_LANG = 'lang';

  public static getLang(): string {
    return `:${LangRoute.RESOLVER_LANG}`;
  }

  public static getLangAdmin(): string {
    return `${LangRoute.getLang()}/${AdminRoute.ADMIN}`;
  }

  public static getLangConsultant(): string {
    return `${LangRoute.getLang()}/${ConsultantRoute.CONSULTANT}`;
  }
}

export class RoutesAdminConfig {
  public static PATH = 'config';
  public static UPLOAD_RESULTS = RoutesAdminConfig.getPath(AdminRoute.UPLOAD_RESULTS);
  public static UPLOAD_RESULTS_ID = `${RoutesAdminConfig.UPLOAD_RESULTS}/:id`;
  public static FAQS = RoutesAdminConfig.getPath(AdminRoute.FAQS);
  public static FAQS_ID = `${RoutesAdminConfig.FAQS}/:id`;
  public static CHANGE_PASSWORD = RoutesAdminConfig.getAdmin(AdminRoute.CHANGE_PASSWORD);
  public static NOTIFICATIONS = RoutesAdminConfig.getAdmin(AdminRoute.NOTIFICATIONS);
  public static NEWS = RoutesAdminConfig.getPath('news');
  public static HOLIDAYS = RoutesAdminConfig.getPath('holidays');
  public static SIMULATOR = RoutesAdminConfig.getPath('simulator');
  public static COUNTRIES = RoutesAdminConfig.getPath('countries');
  public static AUTH_ROLES = RoutesAdminConfig.getPath('auth-roles');
  public static DOCUMENT_TYPES = RoutesAdminConfig.getPath('document-types');
  public static TRX_TYPES = RoutesAdminConfig.getPath('trx-types');
  public static BULK_IMPORT = RoutesAdminConfig.getAdmin(AdminRoute.BULK_IMPORT);
  public static BULK_TEMP_MODELS = RoutesAdminConfig.getPath(AdminRoute.BULK_TEMP_MODELS);

  public static getAdmin(value: string): string {
    return `${value}`;
  }

  public static getPath(value: string): string {
    return `${RoutesAdminConfig.PATH}/${value}`;
  }

  public static getBackRoute(value: string): string {
    return `${AdminRoute.ADMIN}/${RoutesAdminConfig.PATH}/${value}`;
  }
}

export function getAdminConsultants(name: any): any {
  const path = RoutesAdminConsultants.CONSULTANTS;
  if (name) {
    return `${path}/${name}`;
  }
  return `${path}`;
}

export class TemplateRoute {
  public static CREATE = 'create';
  public static TEMPLATE_NAME_TYPE = 'template-name-type';
  public static TEMPLATE_UPLOAD_FILE = 'template-upload-file';
  public static TEMPLATE_MAP_PROPERTIES = 'template-map-properties';

  public static createTemplateNameType(): string {
    return `${TemplateRoute.CREATE}/${TemplateRoute.TEMPLATE_NAME_TYPE}`;
  }

  public static createTemplateMapProperties() {
    return `${TemplateRoute.CREATE}/${TemplateRoute.TEMPLATE_MAP_PROPERTIES}`;
  }
}
