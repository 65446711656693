import { Pipe, PipeTransform } from '@angular/core';
import {translaterCompany} from '../../utils/util-functions';

@Pipe({
  name: 'pipeCompany'
})
export class PipeCompanyPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    return translaterCompany(value);
  }

}
