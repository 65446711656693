import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {filter, map, mergeMap} from 'rxjs/operators';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {FormArray, FormGroup} from '@angular/forms';
import {IRequestMessage} from '../../main/models/consultant-requests';
import {HttpParams} from '@angular/common/http';
import {TimeSheetHoliday} from '../../main/models/common/time-sheet';
import {EnumTypeHoliday, EnumTypeHolidayTag, EnumTypeHolidayToCssClass} from '../../main/models/admin/holidays';
import {PersonalData} from '../../main/models/admin/consultant-account';
import {IdentitiesMapping} from '../../main/models/admin/identities-mapping';
import MultiPlatform from '../../../environments/multi-platform';
import {LocalStorageService} from '../../main/services/local-storage-service';
import {PublicRoute} from './routes';
import {InstanceService} from '../../main/services/instance.service';
import ISO6391 from 'iso-639-1';
import {LangConf} from './languages';
import {RoleToRouteEnum} from '../../main/models/admin/auth0';
import {Platform} from './global';

export function redirectLanguage(platform: Platform,
                                 router: Router,
                                 roles: string[],
                                 instance: InstanceService) {
  const isAdmin = () => roles.includes(instance.instanceAdminRole);
  const isConsultant = () => roles.includes(instance.instanceConsultantRole);
  if (isAdmin() || isConsultant()) {
    let role = RoleToRouteEnum.CONSULTANT;
    if (isAdmin()) {
      role = RoleToRouteEnum.ADMIN;
    }
    LangConf.changeLanguage(platform, MultiPlatform.get().localeLangI18n, role, router);
  }
  router.navigateByUrl(PublicRoute.LOGIN);
}

export function getRouteByRoles(roles: string[],
                                instance: InstanceService,
                                localStorageService: LocalStorageService) {
  const isAdmin = () => roles.includes(instance.instanceAdminRole);
  const isConsultant = () => roles.includes(instance.instanceConsultantRole);
  if (isAdmin() || isConsultant()) {
    return isAdmin() ? localStorageService.getUrlHomeAdmin() : localStorageService.getUrlHomeConsultant();
  }
  return PublicRoute.LOGIN;
}

export function initializeDataSource(
  dataSource: MatTableDataSource<any>,
  data: any,
  sort: MatSort,
  paginator: MatPaginator,
): MatTableDataSource<any> {
  dataSource = new MatTableDataSource<any>(data);
  dataSource.sort = sort;
  dataSource.paginator = paginator;
  return dataSource;
}

export function getRouterData(router: Router, activatedRoute: ActivatedRoute): Observable<any> {
  return router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    map(() => activatedRoute),
    map((route) => {
      while (route.firstChild) {
        route = route.firstChild;
      }
      return route;
    }),
    filter((route) => route.outlet === 'primary'),
    mergeMap((route) => route.data));
}

export function getRouterEvents(router: Router, activatedRoute: ActivatedRoute): Observable<any> {
  return router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    map(() => activatedRoute),
    map((route) => {
      while (route.firstChild) {
        route = route.firstChild;
      }
      return route;
    }),
    filter((route) => route.outlet === 'primary'));
}

export function getAllErrors(form: FormGroup | FormArray): { [key: string]: any; } | null {
  let hasError = false;
  const result = Object.keys(form.controls).reduce((acc, key) => {
    const control = form.get(key);
    const errors = (control instanceof FormGroup || control instanceof FormArray)
      ? this.getAllErrors(control)
      : control.errors;
    if (errors) {
      acc[key] = errors;
      hasError = true;
    }
    return acc;
  }, {} as { [key: string]: any; });
  return hasError ? result : null;
}

export function reverseMessages(messages: IRequestMessage[]): IRequestMessage[] {
  return messages.reverse();
}

export function getHttpParams(params: HttpParamHelper[]): HttpParams {
  let httpParams = new HttpParams();
  params.filter(param => param.value !== null && param.value !== '')
    .forEach(p => httpParams = httpParams.append(p.param, p.value));
  return httpParams;
}

export interface HttpParamHelper {
  param: string;
  value: any;
}

export function getHolidayTag(holiday: TimeSheetHoliday): string {
  if (holiday === undefined || holiday === null) return null;
  const getSpecialHolidayTag = (name: string) => name.slice(0, 2).toUpperCase();
  return holiday.typeHoliday === EnumTypeHoliday.SPECIAL_HOLIDAY ? getSpecialHolidayTag(holiday.name)
    : EnumTypeHolidayTag[holiday.typeHoliday];
}

export function getHolidayCssClass(holiday: TimeSheetHoliday): string {
  if (holiday === undefined || holiday === null) return '';
  return EnumTypeHolidayToCssClass[holiday.typeHoliday];
}

export function greetingMessage(): string {

  const greetings = [
    [22, 'GREETINGS.NIGHT'],
    [18, 'GREETINGS.EVENING'],
    [12, 'GREETINGS.AFTERNOON'],
    [5, 'GREETINGS.MORNING'],
    [0, 'GREETINGS.NIGHT']
  ];
  const hr = new Date().getHours();

  for (const timeGreeting of greetings) {
    if (hr >= timeGreeting[0]) {
      return `${timeGreeting[1]}`;
    }
  }
}

export function ucfirst(value) {
  if (false) {
    let rtn = '';
    const name = value.toString().trim();
    if (name.length === 1) {
      rtn = name[0].toUpperCase();
    } else if (name.length > 1) {
      rtn = name[0].toUpperCase() + name.substr(1);
    }
    return rtn;
  }
  return value;
}

export function getAbrvFullnameByPersonalData(data: PersonalData): string {
  let fullname = '';
  if (data.firstName && data.lastName) {
    fullname = `${data.firstName}-${data.lastName}`;
  } else if (data.firstName) {
    fullname = data.firstName;
  } else if (data.lastName) {
    fullname = data.lastName;
  } else {
    fullname = data.email;
  }
  return fullname.toLowerCase();
}

export function getAbrvFullnameByIdentitiesMapping(data: IdentitiesMapping): string {
  let fullname = '';
  if (data.firstName && data.lastName) {
    fullname = `${data.firstName}-${data.lastName}`;
  } else if (data.firstName) {
    fullname = data.firstName;
  } else if (data.lastName) {
    fullname = data.lastName;
  } else {
    fullname = data.email;
  }
  return fullname.toLowerCase();
}

export function translaterCompany(value: any): any {
  if (value) {
    return value.replace('%nameCompany%', MultiPlatform.get().nameCompany);
  }
  return value;
}

export function getIsoLanguage(isoLanguage: string): string {
  return ISO6391.getName(isoLanguage);
}

export function compareObjectsByUuid(o1: any, o2: any): boolean {
  return o1 && o2 && o1.uuid === o2.uuid;
}
