export interface IHoliday {
  id?: number;
  date: Date;
  localName?: string;
  name?: string;
  days?: number;
  year?: number;
  allowDays?: boolean;
  eventDate?: boolean;
  statusDocument?: boolean;
  type: EnumTypeHoliday;
  active?: boolean;
  deleted?: boolean;
  translaters: IHolidayTranslater[];
  documents: IDocumentTranslater[];
}

export interface IHolidayRequestsConsultants {
  id?: number;
  name?: string;
  list: IHolidayConsultant[];
}

export interface IHolidayConsultant {
  id?: number;
  name?: string;
  maxDays?: any;
  allowDays?: boolean;
  statusDocument?: boolean;
  download?: boolean;
  days?: number;
  type: EnumTypeHoliday;
  currentDays?: number;
  totalDays?: number;
  currentRequests: IHolidayRequest[];
  archivedRequests: IHolidayRequest[];
  documents: IRequestDocument[];
  presentDaysCurrentRequests?: number;
  totalDaysCurrentRequests?: number;
  presentDaysArchivedRequests?: number;
  totalDaysArchivedRequests?: number;

}

export interface IRequestDocument {
  id?: number;
  name?: string;
  filename?: string;
  documentId?: number;
  isUpload: boolean;
}

export interface IHolidayRequest {
  id?: number;
  current?: boolean;
  codeStatus: any;
  currentDays?: number;
  totalDays?: number;
  eventDate?: Date;
  download?: boolean;
  periods: IHolidayRequestAbcensePeriod[];
  urlDocument: string;
  documents: IRequestDocument[];
  comment?: string;
  messageDate?: Date;
  type?: number;
  contactus?: string;
}

export interface IHolidayRequestAbcensePeriod {
  id?: number;
  startDate: string;
  endDate: string;
  startHolidayPeriodType: IHolidayPeriodType;
  endHolidayPeriodType: IHolidayPeriodType;
}

export class IHolidayPeriods {
  types: IHolidayPeriodType[];
  dates: IHolidayRequestDates[];
}

export class IHolidayRequestDates {
  name?: string;
  date?: string;
  typeHoliday?: number;
  typePeriod?: number;
  requestId?: number;
}

export class IHolidayPeriodType {
  id?: number;
  name?: string;
  code?: string;
  totalHours?: number;
  totalDays?: number;
}

export interface IDocumentTranslater {
  translaters: IHolidayTranslater[];
}

export interface IHolidayTranslater {
  id?: number;
  name?: string;
  locale?: ILocale;
}

export interface ILocale {
  id?: number;
  name?: string;
}

export enum EnumTypeHolidayTag {
  PH = 1,
  H = 2,
}

export enum EnumTypeHolidayToCssClass {
  all,
  ph,
  h,
  sp,
}

export enum EnumTypeHoliday {
  PUBLIC_HOLIDAY = 1,
  LEGAL_HOLIDAY = 2,
  SPECIAL_HOLIDAY = 3,
  ALL = 0,
}

export enum EnumTypePeriod {
  FULL_DAY = 1,
  MORNING = 2,
  AFTERNOON = 3
}

export enum EnumStringTypePeriod {
  FULL_DAY = "FULL_DAY",
  MORNING = "MORNING",
  AFTERNOON = "AFTERNOON"
}

export enum EnumNamesTypeHoliday {
  ALL = "all",
  PUBLIC = "public",
  LEGAL = "legal",
  SPECIAL = "special"
}

export enum EnumStatusHoliday {
  ACTIVE = "active",
  INACTIVE = "inactive"
}

export enum EnumStatusRequestHoliday {
  PENDING = 1,
  REFUSED = 2,
  APPROVED = 3,
  ALL = 0
}

export enum EnumNameStatusRequestHoliday {
  PENDING = 'PENDING',
  REFUSED = 'REFUSED',
  APPROVED = 'APPROVED'
}

export enum EnumAccordionHolidayRequest {
  CURRENT_REQUESTS = 'CURRENT_REQUESTS',
  ARCHIVED_REQUESTS = 'ARCHIVED_REQUESTS'
}

export enum WeekDayHoliday {
  Sunday = 0,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday
}

export class DataPeriodHoliday {
  type: any;
  date: any;

  constructor(_type: any, _date: any) {
    this.type = _type;
    this.date = _date;
  }

}
