import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CallbackComponent} from './callback/callback.component';
import {AdminGuard} from './guard/admin.guard';
import {LoginComponent} from './main/content/login/login.component';
import {LoginGuard} from './guard/login.guard';
import {AuthFallbackComponent} from './auth-fallback/auth-fallback.component';
import {SignUpComponent} from './main/content/sign-up/sign-up.component';
import {ForgotPasswordComponent} from './main/content/forgot-password/forgot-password.component';
import {
  ForgotPasswordRecoveryComponent
} from './main/content/forgot-password-recovery/forgot-password-recovery.component';
import {
  CompleteYourProfileModalComponent
} from './main/content/consultants/pages/complete-your-profile-modal/complete-your-profile-modal.component';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {TranslateModule} from '@ngx-translate/core';
import {FlexModule} from '@angular/flex-layout';
import {MatFormFieldModule} from '@angular/material/form-field';
import {ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatRadioModule} from '@angular/material/radio';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {
  PublicSalarySimulationCountryComponent
} from './main/content/public-pages/pages/public-salary-simulation-country/public-salary-simulation-country.component';
import {TimeOutDialogComponent} from './main/content/time-out-dialog/time-out-dialog/time-out-dialog.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatIconModule} from '@angular/material/icon';
import 'hammerjs';
import {
  PublicSalarySimulationPdfComponent
} from './main/content/public-pages/pages/public-salary-simulation-pdf/public-salary-simulation-pdf.component';
import {SupportedBrowsersComponent} from './main/content/supported-browsers/supported-browsers.component';
import {AdminRoute, ConsultantRoute, LangRoute, PublicRoute, RoutesAdminConfig} from './core/utils/routes';
import MultiPlatform from '../environments/multi-platform';
import {LanguageAdminResolver} from './core/resolver/language-admin.resolver';
import {LanguageConsultantResolver} from './core/resolver/language-consultant.resolver';
import {RoutePathMatchType} from './main/models/admin/auth0';
import {
  PrivateSalarySimulationCountryComponent
} from './main/content/consultants/pages/salary-simulation-country/private-salary-simulation-country.component';
import {EmptyComponent} from './empty/empty.component';
import {UploadCsvComponent} from './main/content/admin/pages/bulk-import/upload-csv.component';
import {
  TransactionTypesManagementComponent
} from './main/content/admin/pages/configurations/transaction-types-management/transaction-types-management.component';
import {
  ImportResultsTableComponent
} from './main/content/admin/pages/configurations/import-results-table/import-results-table.component';
import {
  ImportResultsComponent
} from './main/content/admin/pages/configurations/import-results-table/import-results/import-results.component';

const routes: Routes = [
  {
    path: PublicRoute.LOGIN,
    component: LoginComponent,
    data: {title: MultiPlatform.get().login},
    canActivate: [LoginGuard]
  },
  {
    path: PublicRoute.SIGNUP,
    component: SignUpComponent,
    data: {title: MultiPlatform.get().signup},
    canActivate: [LoginGuard]
  },
  {
    path: PublicRoute.SIGNUP_ID,
    component: SignUpComponent,
    data: {title: MultiPlatform.get().signup},
    canActivate: [LoginGuard]
  },
  {
    path: PublicRoute.CALLBACK,
    component: CallbackComponent,
    data: {title: MultiPlatform.get().loading}
  },
  {
    path: PublicRoute.AUTH_FALLBACK,
    component: AuthFallbackComponent,
    data: {title: MultiPlatform.get().loading}
  },
  {
    path: LangRoute.getLangAdmin(),
    loadChildren: () => import('./main/content/admin/admin.module').then(m => m.AdminModule),
    canActivate: [AdminGuard],
    resolve: [LanguageAdminResolver]
  },
  {
    path: LangRoute.getLangConsultant(),
    loadChildren: () => import('./main/content/consultants/consultants.module').then(m => m.ConsultantsModule),
    resolve: [LanguageConsultantResolver]
  },
  {
    path: PublicRoute.FORGOT_PASSWORD,
    component: ForgotPasswordComponent
  },
  {
    path: PublicRoute.SUPPORTED_BROWSERS,
    component: SupportedBrowsersComponent
  },
  {
    path: PublicRoute.FORGOT_PASSWORD_RECOVERY,
    component: ForgotPasswordRecoveryComponent
  },
  {
    path: PublicRoute.SIMULATION,
    component: PublicSalarySimulationCountryComponent
  },
  {
    path: PublicRoute.SIMULATION_PDF,
    component: PublicSalarySimulationPdfComponent
  },
  {
    path: '',
    redirectTo: PublicRoute.LOGIN,
    pathMatch: RoutePathMatchType.FULL
  },
  {
    path: '**',
    redirectTo: PublicRoute.LOGIN,
    pathMatch: RoutePathMatchType.FULL
  }
];

const availableRoutes: Routes = [
  {
    path: ConsultantRoute.SALARY_SIMULATION,
    component: PrivateSalarySimulationCountryComponent
  },
  {
    path: AdminRoute.BULK_IMPORT,
    component: UploadCsvComponent,
    data: {title: 'NAV.BULK_IMPORT'}
  },
  {
    path: AdminRoute.BULK_TEMP_MODELS,
    loadChildren: () => import('./main/content/admin/pages/configurations/bulk-template-models/bulk-template-models.module')
      .then(m => m.BulkTemplateModelsModule)
  },
  {
    path: 'trx-types',
    component: TransactionTypesManagementComponent,
    data: {title: 'NAV.CONFIGURATIONS.TRANSACTION_TYPES.TITLE'}
  },
  {
    path: AdminRoute.UPLOAD_RESULTS,
    component: ImportResultsTableComponent,
    data: {title: 'NAV.CONFIGURATIONS.UPLOAD_RESULTS.TITLE'}
  },
  {
    path:  `${AdminRoute.UPLOAD_RESULTS}/:id`,
    component: ImportResultsComponent,
    data: {
      title: 'NAV.CONFIGURATIONS.UPLOAD_RESULTS.TITLE',
      backRoute: AdminRoute.UPLOAD_RESULTS
    }
  },
  {
    path: '',
    component: EmptyComponent
  },
  {
    path: '**',
    component: EmptyComponent
  }
];

@NgModule({
  declarations: [CompleteYourProfileModalComponent],
  imports: [
    RouterModule.forRoot(availableRoutes),
    MatButtonModule,
    MatDialogModule,
    TranslateModule,
    FlexModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    PerfectScrollbarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatIconModule,
    MatTooltipModule,
    MatIconModule
  ],
  exports: [RouterModule],
  entryComponents: [CompleteYourProfileModalComponent, TimeOutDialogComponent],
  providers: [MatDatepickerModule]
})
export class AppRoutingModule {
}
