import {NativeDateAdapter} from '@angular/material';
import {DateAdapter, MatDateFormats} from '@angular/material/core';

export const MAT_DATE_LOCALE_CODE_MOMMENT = 'pt';
export const MAT_DATE_LOCALE_UCT_MOMMENT = true;

export const DEFAULT_FORMAT_BIRTH = {
  parse: {
    dateInput: 'DD/MM/YYYY'
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  }
};

export const DEFAULT_FORMAT_DAYS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export class AppDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: any): string {

    if (displayFormat === 'input') {
      let day: string | number = date.getDate();
      day = +day < 10 ? '0' + day : day;
      let month: string | number = date.getMonth() + 1;
      month = +month < 10 ? '0' + month : month;

      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    }

    return date.toDateString();
  }

  parse(value: string): any {
    const parts = value.split('/');
    if (parts.length === 3) {
      return new Date(+parts[2], (+parts[1]) - 1, +parts[0]);
    }
  }
}

export const APP_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: {month: 'short', year: 'numeric', day: 'numeric'},
  },
  display: {
    dateInput: 'input',
    monthYearLabel: {year: 'numeric', month: 'numeric'},
    dateA11yLabel: {
      year: 'numeric', month: 'long', day: 'numeric'
    },
    monthYearA11yLabel: {year: 'numeric', month: 'long'},
  }
};

export const APP_DATE_FORMATS_REQUEST = {
  parse: {
    dateInput: 'DD MMMM',
  },
  display: {
    dateInput: 'DD MMMM',
    monthYearLabel: 'MMM',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM',
  },
  request: {
    dateInput: 'dd MMMM',
  },
  response: {
    dateInput: 'dd MMMM, mm:hh',
  }
};

export const setDateAdapterLanguage = (dateAdapter: DateAdapter<any>, language: string) => {
  dateAdapter.setLocale(language);
};
