import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {IPropertyMap} from '../../../../../models/admin/csv-upload';
import {Observable, Subscription} from 'rxjs';
import {PerfectScrollbarComponent} from 'ngx-perfect-scrollbar';

@Component({
  selector: 'app-temp-prop-view',
  templateUrl: './template-properties-view.component.html',
  styleUrls: ['./template-properties-view.component.scss']
})
export class TemplatePropertiesViewComponent implements OnInit, OnDestroy {
  @ViewChild(PerfectScrollbarComponent, {static: false}) scrollbarComponent?: PerfectScrollbarComponent;
  @Input() templateProperties: IPropertyMap[];

  private scrollUpdateSubscription: Subscription;
  @Input() scrollUpdate: Observable<void>;

  constructor() {
  }

  ngOnInit() {
    this.scrollUpdateSubscription = this.scrollUpdate.subscribe(() => this.scrollbarComponent.directiveRef.update());
  }

  ngOnDestroy() {
    this.scrollUpdateSubscription.unsubscribe();
  }


}
