import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {UserSettingsComponent} from './user-settings.component';
import {TranslateModule} from '@ngx-translate/core';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatInputModule} from '@angular/material/input';
import {ExtendedModule, FlexModule} from '@angular/flex-layout';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {ReactiveFormsModule} from '@angular/forms';
import {NgxSpinnerModule} from 'ngx-spinner';
import {NavBarModule} from '../../nav-bar/nav-bar.module';
import {MatListModule} from '@angular/material/list';
import {MatSliderModule} from '@angular/material/slider';
import {Ng2OdometerModule} from 'ng2-odometer';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {CustomPipesModule} from '../../../../core/formatters/custom-pipes/custom-pipes.module';
import {MatCardModule} from '@angular/material/card';
import {UiCarouselModule} from 'ngx-ui-carousel';
import {SlickCarouselModule} from 'ngx-slick-carousel';
import {MatDialogModule} from '@angular/material/dialog';
import {MatMenuModule} from '@angular/material/menu';
import {InlineSVGModule} from 'ng-inline-svg';
import {CustomSlideToggleModule} from '../../../utils/custom-slide-toggle.module';
import {MatTabsModule} from '@angular/material/tabs';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTooltipModule} from '@angular/material/tooltip';
import {NoResultsModule} from '../../admin/pages/common/no-results/no-results.module';
import {DocumentsModule} from '../../consultants/pages/documents/documents.module';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {ChartsModule} from 'ng2-charts';
import {UserSettingsService} from '../../../services/common/user-settings.service';
import {ComponentsModule} from '../../components/components.module';

@NgModule({
  declarations: [
    UserSettingsComponent
  ],
  imports: [
    CommonModule,
    MatSidenavModule,
    PerfectScrollbarModule,
    MatToolbarModule,
    FlexModule,
    MatButtonModule,
    NavBarModule,
    MatListModule,
    MatIconModule,
    MatSliderModule,
    TranslateModule,
    MatProgressSpinnerModule,
    Ng2OdometerModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MatRadioModule,
    MatSelectModule,
    CustomPipesModule,
    MatCardModule,
    ExtendedModule,
    UiCarouselModule,
    SlickCarouselModule,
    MatDialogModule,
    NgxSpinnerModule,
    MatMenuModule,
    InlineSVGModule,
    CustomSlideToggleModule,
    MatTabsModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatTooltipModule,
    NoResultsModule,
    DocumentsModule,
    MatAutocompleteModule,
    ChartsModule,
    ComponentsModule
  ],
  providers: [
    UserSettingsService
  ],
  exports: [
    UserSettingsComponent
  ]
})
export class UserSettingsModule { }
