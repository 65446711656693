import { Injectable } from '@angular/core';
import MultiPlatform from '../../../../environments/multi-platform';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class UserSettingsService {

  private readonly userUrlTemplate = `${MultiPlatform.getDataEnvironmentHost()}api/common/users`;
  private readonly authIdPathVariable = `{authId}`;
  private readonly changePasswordUrlTemplate = `${this.userUrlTemplate}/newPassword/${this.authIdPathVariable}`;
  private readonly surveyIdentityDataUrlTemplate = `${this.userUrlTemplate}/${this.authIdPathVariable}/survey-data`;

  constructor(private readonly http: HttpClient) { }

  changePasswordDirectly(authUserId: string, newPassword: string): Observable<any> {
    return this.http.patch(this.getChangePasswordUrlData(authUserId), newPassword);
  }

  getSurveyIdentityData(authUserId: string): Observable<any> {
    return this.http.get(this.getSurveyIdentityDataUrlData(authUserId));
  }

  private getChangePasswordUrlData(authUserId: string) {
    return this.changePasswordUrlTemplate.replace(this.authIdPathVariable, encodeURIComponent(authUserId));
  }

  private getSurveyIdentityDataUrlData(authUserId: string) {
    return this.surveyIdentityDataUrlTemplate.replace(this.authIdPathVariable, encodeURIComponent(authUserId));
  }
}
