import { Pipe, PipeTransform } from '@angular/core';
import {ucfirst} from '../../utils/util-functions';

@Pipe({
  name: 'ucfirst'
})
export class UcfirstPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
      return ucfirst(value);
  }

}
