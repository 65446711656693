import {NotifierOptions} from 'angular-notifier';

export interface CustomWarning extends Message {
  warning: string;
}

export type CustomWarnings = CustomWarning[];

export interface CustomError extends Message {
  error: string;
}

export type CustomErrors = CustomError[];

export interface CustomNotification extends Message {
  notification: string;
}

export type CustomNotifications = CustomNotification[];

export interface Message {
  label: string;
  params: any[];
}

export enum MessageType {
  NOTIFICATION = 'NOTIFICATION',
  WARNING = 'WARNING',
  ERROR = 'ERROR'
}

export interface MessageContent {
  message: string;
  date: Date;
  type: MessageType;
}

export type MessageContents = MessageContent[];

export interface INotifierMessage {
  type: NotifierType;
  message: string;
}

export enum NotifierType {
  DEFAULT = 'DEFAULT',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error'
}

export const notifierDefaultOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'middle',
      distance: 12
    },
    vertical: {
      position: 'top',
      distance: 12,
      gap: 10
    }
  },
  behaviour: {
    autoHide: 5000,
    onClick: false,
    onMouseover: 'pauseAutoHide',
    showDismissButton: false,
    stacking: false
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 1000,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 300,
      easing: 'ease'
    },
    overlap: 150
  }
};

export const odometerConfig = {
  animation: 'count',
  format: '(.ddd),dd',
  theme: 'minima',
  value: 0,
  auto: true,
  duration: 500
};

export const odometerConfigSlide = {
  animation: 'slide',
  format: '(.ddd),dd',
  theme: 'minima',
  value: 0,
  auto: true,
  duration: 500
};


export const carouselConfig = {
  centerMode: false,
  initialSlide: 0,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
  dots: true,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 3000,
  pauseOnHover: true,
  // variableWidth: true,
  adaptiveHeight: true,
  focusOnSelect: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: false,
        slidesToShow: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        centerMode: false,
        slidesToShow: 1
      }
    }
  ]
};

export const carouselConfigCenterMode = {
  centerMode: true,
  initialSlide: 1,
  slidesToShow: 3,
  slidesToScroll: 1,
  swipeToSlide: true,
  lazyLoad: 'ondemand',
  dots: true,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 3000,
  pauseOnHover: true,
  adaptiveHeight: true,
  focusOnSelect: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: false,
        slidesToShow: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        slidesToShow: 1
      }
    }
  ]
};
