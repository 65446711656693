export const CLASS_COLOR_ACCENT = 'accent';

export const CLASS_CONTENT_NOTIFICATION = 'content-notification';
export const CLASS_CONTENT_NO_NOTIFICATION = 'content-no-notification';

export enum CLASS_MAT_DIALOG_TYPE {
  NORMAL = 'mat-dialog-',
  DIALOG_300 = 'mat-dialog-300-',
  DIALOG_400 = 'mat-dialog-400-',
  DIALOG_444 = 'mat-dialog-444-',
  DIALOG_496 = 'mat-dialog-496-',
  DIALOG_600 = 'mat-dialog-600-',
  DIALOG_1000 = 'mat-dialog-1000-'
}

export const MAX_WIDTH_MOBILE = '(max-width: 960px)';
