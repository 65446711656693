import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import MultiPlatform from '../../../../environments/multi-platform';
import {IDocumentType} from '../../models/admin/document-type';

export const DOCUMENT_TYPES_API_URL = `${MultiPlatform.getDataEnvironmentHost()}api/common/document-types`;
export const DOCUMENT_TYPES_NO_PAYSLIP_API_URL = `${MultiPlatform.getDataEnvironmentHost()}api/common/document-types/no-pay-slip`;

@Injectable({
  providedIn: 'root'
})
export class DocumentTypesService {
  constructor(private readonly http: HttpClient) {
  }

  getAllDocumentTypes(): Observable<IDocumentType[]> {
    return this.http.get(`${DOCUMENT_TYPES_API_URL}`) as Observable<IDocumentType[]>;
  }

  getAllDocumentTypesNoPayslip(): Observable<IDocumentType[]> {
    return this.http.get(`${DOCUMENT_TYPES_NO_PAYSLIP_API_URL}`) as Observable<IDocumentType[]>;
  }
}
