export interface ITaxClass {
  id?: number;
  taxClass: string;
  pert: any;
  p1?: any;
  p2?: any;
  code?: any;
  message?: any;
}

export interface ICar {
  id?: number;
  carModel: string;
  cost?: number;
  employer_cost?: number;
  employee_cost?: number;
  year: number;
}

export interface IParameters {
  id: number;
  code: string;
  label: string;
  value: number;
  employer_part: number;
  flat_fee: number;
  year: number;
  value_type: string;
  employer_part_type: string;
  flat_fee_type: string;
  section?: string;
}

export interface IAdvantage {
  description?: string;
  code?: string;
  value?: number;
  name?: string;
}

export interface IAdvantages {
  option: number;
  data: IAdvantage[];
}

export enum TypeResultEnum {
  NORMAL = 1,
  CALC_NET_IMPOSABLE = 3,
  TOTAL_PRICE_AND_TAX = 2,
  TICKET = 4,
  IMPOT_SUR_LE_REVENU = 5,
  HIDE_TICKET= 6
}

export class TypeResult {
  normal: TypeResultEnum = TypeResultEnum.NORMAL;
  netImposable: TypeResultEnum = TypeResultEnum.CALC_NET_IMPOSABLE;
  total: TypeResultEnum = TypeResultEnum.TOTAL_PRICE_AND_TAX;
  ticket: TypeResultEnum = TypeResultEnum.TICKET;
  impotSurLeRevenu: TypeResultEnum = TypeResultEnum.IMPOT_SUR_LE_REVENU;
  hideTicket: TypeResultEnum = TypeResultEnum.HIDE_TICKET;
}

export enum RateTypeEnum {
  DAILY_RATE = 'daily',
  MONTHLY_GROSS_SALARY = 'gross'
}

export enum CountrySalaryEnum {
  FR = 'fr',
  LU = 'lu'
}

export enum JobTypeSalaryEnum {
  FULL_TIME = 'FULL_TIME',
  PART_TIME = 'PART_TIME'
}
