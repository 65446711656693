import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {HIDE_DIALOG_REF, SPINNER_NAME} from '../../../../../../core/utils/constants';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ConfirmModalComponent>,
              private readonly spinner: NgxSpinnerService,
              @Inject(MAT_DIALOG_DATA) public data: {title: string, content: string, btn: string, showSpinner: boolean}) {
  }

  ngOnInit() {
  }

  confirm() {
    if (this.data.showSpinner) {
      this.showSpinner();
    }
    this.dialogRef.close(true);
  }

  private showSpinner() {
    this.dialogRef.addPanelClass(HIDE_DIALOG_REF);
    this.spinner.show(SPINNER_NAME);
  }
}
