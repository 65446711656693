import { Pipe, PipeTransform } from '@angular/core';
import {fixNumberToPercent, PERT_LOCALE} from '../format-percentagem';
import {formatPercent} from '@angular/common';
import MultiPlatform from '../../../../environments/multi-platform';

@Pipe({
  name: 'percentageToInteger'
})
export class CustomPercentageToIntegerPipe implements PipeTransform {

  transform(
    value: number,
    digitsInfo = '0',
    locale = PERT_LOCALE,
  ): string | null {
    const r = Math.round((value + Number.EPSILON) * 100) / 100;
    const precision = MultiPlatform.get().graph.precision;
    const t = +(Math.round(Number(r + 'e+' + precision)) + 'e-' + precision);

    return formatPercent(
      fixNumberToPercent(t),
      PERT_LOCALE,
      MultiPlatform.get().placesItem
    );
  }
}
