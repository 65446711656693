import {formatPercent} from '@angular/common';
import MultiPlatform from '../../../environments/multi-platform';

export const PERT_DIGITS_INFO = '0.2';
export const PERT_DIGITS_INFO_ZERO = '0.0-2';
export const PERT_LOCALE = 'fr-FR';

export function fixNumberToPercent(value: any): any {
  let pert = 0;
  if (isNaN(value)) {
    pert = 0;
  } else if (value === undefined) {
    pert = 0;
  } else if (value === Infinity) {
    pert = 0;
  } else {
    pert = value / 100;
  }
  return pert;
}

export function convertNumberToPercent(value: any): any {
  return formatPercent(
    fixNumberToPercent(value),
    PERT_LOCALE,
    PERT_DIGITS_INFO
  );
}

export function convertNumberToPercentChartJs(value: any): any {
  const r = Math.round((value + Number.EPSILON) * 100) / 100;
  const precision = MultiPlatform.get().graph.precision;
  const t = +(Math.round(Number(r + 'e+' + precision)) + 'e-' + precision);
  return formatPercent(
    fixNumberToPercent(value),
    PERT_LOCALE,
    MultiPlatform.get().placesGraph
  );
}
