import {Pipe, PipeTransform} from '@angular/core';
import {formatCurrency, getCurrencySymbol} from '@angular/common';

@Pipe({
  name: 'customCurrency'
})
export class CustomCurrencyPipe implements PipeTransform {

  transform(
    value: number,
    currencyCode = 'EUR',
    display = 'symbol',
    digitsInfo = '0.2-2',
    locale = 'fr-FR',
  ): string | null {
    let amount = value;
    if (isNaN(value)) {
      amount = 0;
    } else if (value === undefined) {
      amount = 0;
    } else if (value === Infinity) {
      amount = 0;
    }
    return formatCurrency(
      amount,
      locale,
      getCurrencySymbol(currencyCode, 'wide'),
      currencyCode,
      digitsInfo,
    );
  }

}
