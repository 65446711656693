import {Component, OnInit} from '@angular/core';
import MultiPlatform from '../../../../../../environments/multi-platform';

@Component({
  selector: 'app-public-salary-simulation-country',
  templateUrl: './public-salary-simulation-country.component.html',
  styleUrls: ['./public-salary-simulation-country.component.scss']
})
export class PublicSalarySimulationCountryComponent implements OnInit {
  ngOnInit(): void {
    if (MultiPlatform.get().policyFeatures.SHOW_SALARY_SIMULATION_PUBLIC) {

    }
  }

}
