import {Component, OnInit, ViewChild} from '@angular/core';
import {TransactionService} from '../../../../../services/admin/transaction-service';
import {Observable, of} from 'rxjs';
import {ITrxType} from '../../../../../models/admin/transaction';
import {MatDialog} from '@angular/material/dialog';
import {AddEditTrxTypeComponent} from './add-edit-trx-type/add-edit-trx-type.component';
import {catchError, map, switchMap, take, tap} from 'rxjs/operators';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {NgxSpinnerService} from 'ngx-spinner';
import {SPINNER_NAME} from '../../../../../../core/utils/constants';
import {ConfirmModalComponent} from '../../common/confirm-modal/confirm-modal.component';
import {CLASS_MAT_DIALOG_TYPE} from '../../../../../../core/utils/responsive';
import {ResponsiveService} from '../../../../../services/responsive.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-transaction-types-management',
  templateUrl: './transaction-types-management.component.html',
  styleUrls: ['./transaction-types-management.component.scss']
})
export class TransactionTypesManagementComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  displayedColumns = ['typeName', 'creditDebit', 'actions'];
  trxTypesDataSource$: Observable<MatTableDataSource<ITrxType>>;

  noResults;

  constructor(private readonly transactionService: TransactionService,
              private readonly dialog: MatDialog,
              private readonly spinner: NgxSpinnerService,
              private readonly responsiveService: ResponsiveService) {
  }

  ngOnInit() {
    this.refreshList();
  }

  refreshList(): void {
    this.spinner.show(SPINNER_NAME);
    this.trxTypesDataSource$ = this.transactionService.getAllTransactionTypes().pipe(
      map(trxTypes => new MatTableDataSource(trxTypes)),
      tap((dataSource: MatTableDataSource<ITrxType>) => {
        dataSource.paginator = this.paginator;
        dataSource.sort = this.sort;
        this.spinner.hide(SPINNER_NAME);
        this.noResults = dataSource.data.length === 0;
      }),
      catchError((err) => {
        console.error(err);
        this.spinner.hide(SPINNER_NAME);
        this.noResults = true;
        return of(new MatTableDataSource([]));
      })
    );
  }

  openAddEditTrxType(trxType: ITrxType = null): void {
    const dialogRef = this.dialog.open(AddEditTrxTypeComponent, {
      data: trxType,
      panelClass: this.responsiveService.getMatDialog(CLASS_MAT_DIALOG_TYPE.DIALOG_400)
    });
    dialogRef.afterClosed().pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.refreshList();
        }
      });
  }

  deleteTransactionType(trxType: ITrxType): void {
    const confirmDialogRef = this.dialog.open(ConfirmModalComponent, {
      panelClass: this.responsiveService.getMatDialog(CLASS_MAT_DIALOG_TYPE.NORMAL),
      data: {
        title: 'NAV.CONFIGURATIONS.TRANSACTION_TYPES.DELETE_TITLE',
        content: 'NAV.CONFIGURATIONS.TRANSACTION_TYPES.DELETE_CONTENT',
        btn: 'OK'
      }
    });
    confirmDialogRef.afterClosed().pipe(
      take(1),
      switchMap(confirmed => !confirmed ? of(null) : this.transactionService.deleteTransactionType(trxType))
    ).subscribe(() => this.refreshList());
  }

}
