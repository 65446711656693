import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-custom-slide-toggle',
  templateUrl: './custom-slide-toggle.component.html',
  styleUrls: ['./custom-slide-toggle.component.scss']
})
export class CustomSlideToggleComponent implements OnInit {
  @Input() fControl?: FormControl;
  @Input() inputChecked?: boolean;
  @Output() changeEvent: EventEmitter<void> = new EventEmitter<void>();

  constructor() {
  }

  ngOnInit() {
    if (this.fControlNullAndInputCheckedNotNull()) {
      this.fControl = new FormControl(this.inputChecked);
      this.fControl.valueChanges.subscribe(() => this.emitChangeEvent());
    }
  }

  private fControlNullAndInputCheckedNotNull(): boolean {
    return (this.fControl === undefined || this.fControl === null) && (this.inputChecked !== undefined && this.inputChecked !== null);
  }

  emitChangeEvent(): void {
    this.changeEvent.emit();
  }

}
