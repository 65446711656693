import {Injectable} from '@angular/core';
import {AuthService} from '../../main/services/auth.service';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {EmbeddedAuthService} from '../../main/services/embedded-auth.service';
import {catchError, mergeMap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {CONTENT_AUTHORIZATION_VALUE} from '../utils/constants';
import {LocalStorageService} from '../../main/services/local-storage-service';
import {PublicRoute} from '../utils/routes';

@Injectable({
  providedIn: 'root'
})
export class AddTokenService implements HttpInterceptor {

  constructor(private readonly auth: AuthService,
              private readonly embeddedAuth: EmbeddedAuthService,
              private readonly router: Router,
              private localStorageService: LocalStorageService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.embeddedAuth.checkAuthenticated()) {
      this.router.navigate([PublicRoute.LOGIN]);
    } else {
      const profile = this.localStorageService.getUserProfile();
      const dataPlatform: { id: any, email: string } = {
        id: '',
        email: ''
      };

      if (profile) {
        dataPlatform.id = profile.sub;
        dataPlatform.email = profile.email;
      }

      return of(this.embeddedAuth.getAccessToken()).pipe(
        mergeMap(token => {
          if (token) {
            const tokenReq = req.clone({
              setHeaders: {
                Authorization: `${CONTENT_AUTHORIZATION_VALUE} ${token}`,
                id: `${dataPlatform.id}`,
                email: `${dataPlatform.email}`,
                lang: `${this.localStorageService.getLanguage()}`,
                gps: `${this.localStorageService.getGps()}`
              }
            });
            return next.handle(tokenReq);
          }

          return next.handle(req);
        }),
        catchError(err => {
          return throwError(err);
        }),
      );
    }
  }
}
