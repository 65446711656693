import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import MultiPlatform from '../../../../../../environments/multi-platform';

@Component({
  selector: 'app-marketplace-modal',
  templateUrl: './marketplace-modal.component.html',
  styleUrls: ['./marketplace-modal.component.scss']
})
export class MarketplaceModalComponent {
  private readonly marketplaceUrl = MultiPlatform.getMarketplaceUrl();

  constructor(public dialogRef: MatDialogRef<MarketplaceModalComponent>) {
  }

  openMarketplace() {
    window.open(this.marketplaceUrl, '_blank');
    this.dialogRef.close();
  }
}
