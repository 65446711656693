import {Component, OnInit} from '@angular/core';
import {SidenavService} from '../../../services/admin/sidenav.service';
import {FormBuilder, FormGroup, FormGroupDirective, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {LocalStorageService} from '../../../services/local-storage-service';
import {take} from 'rxjs/operators';
import {SPINNER_NAME} from '../../../../core/utils/constants';
import {NotifierWithTemplateService} from '../../../services/notifier-with-template.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {UserSettingsService} from '../../../services/common/user-settings.service';

export const passwordMatchValidator: ValidatorFn = (formGroup: FormGroup): ValidationErrors | null => {
  if (formGroup.get('password').value === formGroup.get('confirmPassword').value) {
    return null;
  } else {
    return {passwordMismatch: true};
  }
};

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnInit {

  constructor(private readonly sidenav: SidenavService,
              private readonly translate: TranslateService,
              private readonly notifier: NotifierWithTemplateService,
              private userSettingsService: UserSettingsService,
              private localStorageService: LocalStorageService,
              private readonly spinner: NgxSpinnerService,
              private readonly notifierWithTemplateService: NotifierWithTemplateService,
              private readonly fb: FormBuilder) { }

  get password() { return this.passwordForm.get('password'); }
  get confirmPassword() { return this.passwordForm.get('confirmPassword'); }
  passwordForm: FormGroup;
  hide = true;
  hideConf = true;
  authUserId: string;
  showRules = false;
  fromSocialLinks: boolean;

  private static hasUppercase(str: string): boolean {
    return str.toLowerCase() !== str;
  }

  private static hasLowercase(str: string): boolean {
    return str.toUpperCase() !== str;
  }

  private static hasNumber(str: string) {
    return /\d/.test(str);
  }

  private static hasSpecialChars(myString) {
    return /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(myString);
  }

  private static checkPasswordStrength(password: string): boolean {
    let rulesFollowed = 0;
    if (UserSettingsComponent.hasLowercase(password)) rulesFollowed++;
    if (UserSettingsComponent.hasUppercase(password)) rulesFollowed++;
    if (UserSettingsComponent.hasNumber(password)) rulesFollowed++;
    if (UserSettingsComponent.hasSpecialChars(password)) rulesFollowed++;

    return rulesFollowed >= 3;
  }

  ngOnInit() {
    this.fromSocialLinks = !this.localStorageService.getUserProfile().sub.includes('auth0');
    this.initForm();
    this.authUserId = this.localStorageService.getData().userProfile.sub;
  }

  toggleMenu() {
    this.sidenav.toggle();
  }

  changePassword(passwordFormDirective: FormGroupDirective) {
    if (this.passwordForm.invalid) return;
    if (!UserSettingsComponent.checkPasswordStrength(this.passwordForm.controls.password.value)) {
      this.notifier.error(this.translate.instant('FORM_SIGN_UP.PASSWORD_STRENGTH_ERROR'));
      return;
    }
    this.spinner.show(SPINNER_NAME);
    this.userSettingsService.changePasswordDirectly(
      this.authUserId,
      this.passwordForm.controls.password.value
    ).pipe(take(1)).subscribe(() => {
      passwordFormDirective.resetForm(); // reset the errors
      this.passwordForm.reset();
      this.spinner.hide(SPINNER_NAME);
      this.notifierWithTemplateService.success(this.translate.instant('SETTINGS.PASSWORD_CHANGED'));
    }, () => {
      this.spinner.hide(SPINNER_NAME);
      this.notifierWithTemplateService.error(this.translate.instant('SETTINGS.PASSWORD_CHANGING_ERROR'));
    });
  }

  private initForm(): void {
    this.passwordForm = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', [Validators.required]],
    }, {validator: passwordMatchValidator});
  }

  onPasswordInput() {
    if (this.passwordForm.hasError('passwordMismatch')) {
      this.confirmPassword.setErrors([{passwordMismatch: true}]);
    } else {
      this.confirmPassword.setErrors(null);
    }
  }

  getErrorMessagePassword() {
    if (this.passwordForm.controls.password.hasError('required')) {
      return this.translate.instant('EMPTY_FIELD');
    } else if (this.passwordForm.controls.password.hasError('minlength')) {
      return this.translate.instant('SETTINGS.PASSWORD_LENGTH');
    }
    return '';
  }

  getErrorMessageConfirm() {
    if (this.passwordForm.controls.confirmPassword.hasError('required')) {
      return this.translate.instant('EMPTY_FIELD');
    } else if (this.passwordForm.controls.confirmPassword.invalid && !this.passwordForm.controls.confirmPassword.hasError('required')) {
      return this.translate.instant('SETTINGS.PASSWORD_NOT_MATCH');
    }
    return '';
  }
}
