import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import MultiPlatform from '../../../../environments/multi-platform';
import {Observable} from 'rxjs';
import {IInvitation, IInvitationCreationRequest} from '../../models/admin/invitation';
import {catchError, take} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {NotifierWithTemplateService} from '../notifier-with-template.service';

export const INVITATIONS_URL = `${MultiPlatform.getDataEnvironmentHost()}api/admin/invitations`;
export const PUBLIC_INVITATIONS_URL = `${MultiPlatform.getDataEnvironmentHost()}api/public/invitations/:id`;
export const AGENCY_URL = `${MultiPlatform.getDataEnvironmentHost()}api/admin/agency`;

@Injectable({
  providedIn: 'root'
})
export class InvitationService {
  private _invitation: IInvitation;

  constructor(private http: HttpClient,
              private readonly translate: TranslateService,
              private readonly notifier: NotifierWithTemplateService) {
  }

  sendInvitation(invitationCreationRequest: IInvitationCreationRequest): Observable<any> {
    return this.http.post(INVITATIONS_URL, invitationCreationRequest).pipe(
      catchError(() => {
        this.translate.get('NAV.CONSULTANT.INVITATIONS.ERROR.SEND')
          .pipe(take(1)).subscribe(error => this.notifier.error(error));
        return null;
      })
    );
  }

  getInvitations(): Observable<IInvitation[]> {
    return this.http.get<IInvitation[]>(INVITATIONS_URL)
      .pipe(
        catchError(() => {
          this.translate.get('NAV.CONSULTANT.INVITATIONS.ERROR.GET')
            .pipe(take(1)).subscribe(error => this.notifier.error(error));
          return [];
        })
      );
  }

  getInvitationByAgency(agencyId): Observable<IInvitationCreationRequest> {
    return this.http.get<IInvitationCreationRequest>(`${AGENCY_URL}/${agencyId}`);
  }

  getInvitation(id): Observable<IInvitation> {
    return this.http.get<IInvitation>(PUBLIC_INVITATIONS_URL.replace(':id', id));
  }

  resendEmail(id: number): Observable<void> {
    return this.http.patch<void>(`${INVITATIONS_URL}/resend`, id);
  }

  removeInvitation(id: number): Observable<void> {
    return this.http.delete<void>(`${INVITATIONS_URL}/${id}`);
  }

  get invitation(): IInvitation {
    return this._invitation;
  }

  set invitation(value: IInvitation) {
    this._invitation = value;
  }
}
