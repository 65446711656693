import {Injectable} from '@angular/core';
import {MarketplaceModalComponent} from '../../content/common/modals/marketplace-modal/marketplace-modal.component';
import {MatDialog} from '@angular/material/dialog';
import MultiPlatform from '../../../../environments/multi-platform';

@Injectable({
  providedIn: 'root'
})
export class MarketplaceService {
  private readonly AUTH0_APP_METADATA_PROPERTY = MultiPlatform.getMarketplaceAuth0AppMetadataProp();
  private readonly marketplaceLaunchDate = MultiPlatform.getMarketplaceLaunchInfo();

  constructor(private readonly dialog: MatDialog) {
  }

  public openMarketplacePopup() {
    this.dialog.open(MarketplaceModalComponent, {
      width: '70vw',
      height: '65vh',
      disableClose: true,
      panelClass: 'marketplace-dialog',
      backdropClass: 'backdrop-background-dialog'
    });
  }

  handleInitialMarketplacePopup(userProfile: any) {
    if (userProfile && userProfile[this.AUTH0_APP_METADATA_PROPERTY]) {
      const lastLogin = userProfile[this.AUTH0_APP_METADATA_PROPERTY].previous_login as number;

      if (lastLogin) {
        const marketplaceLaunchEpoch = new Date(
          this.marketplaceLaunchDate.year,
          this.marketplaceLaunchDate.month - 1,
          this.marketplaceLaunchDate.day)
          .getTime();

        if (marketplaceLaunchEpoch > lastLogin) {
          this.openMarketplacePopup();
        }
      } else {
        this.openMarketplacePopup();
      }
    } else {
      this.openMarketplacePopup();
    }
  }
}
