import {HttpClient, HttpResponse} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import MultiPlatform from '../../../../environments/multi-platform';
import {ITrx, ITrxType, TrxCreationRequest} from '../../models/admin/transaction';
import {catchError, map, take, tap} from 'rxjs/operators';
import {NotifierType} from '../../models/admin/custom-message';
import {TranslateService} from '@ngx-translate/core';
import {NotifierWithTemplateService} from '../notifier-with-template.service';
import {DocumentExtensionTypes, StatementDocumentType} from '../../models/consultant/statement';
import {DOCUMENT} from '@angular/common';
import {InvoicesDraft} from '../../models/consultant/transaction';
import {ConsultantIdentityService} from '../consultant/consultant-identity.service';
import {PersonalData} from '../../models/admin/consultant-account';
import {getAbrvFullnameByPersonalData} from '../../../core/utils/util-functions';
import {HatchrAuthService} from '../hatchr-auth.service';


@Injectable()
export class TransactionService {
  protected readonly http: HttpClient;

  private readonly transactionsUrl: string;
  private readonly transactionUrl: string;
  private readonly trxConsultantUrlTemplate: string;
  private readonly trxInvoiceDraftConsultantUrlTemplate: string;
  private readonly trxTypeUrl: string;
  private readonly trxTypeAllUrl: string;
  private readonly transactionCreationUrl: string;
  private readonly invoicesCreationUrl: string;

  private readonly paymentReceivedUrl: string;
  private readonly createManagementFeesUrl: string;
  private readonly createMealVouchersFeesUrl: string;
  private readonly deleteTransactionTypeUrl: string;
  private readonly createTransactionTypeUrl: string;
  private readonly updateTransactionTypeUrl: string;

  private readonly transactionIdStr = '{transactionId}';
  private readonly transactionTypeIdStr = '{transactionTypeId}';
  private readonly accountIdStr = '{accountId}';

  constructor(private readonly hatchrAuthService: HatchrAuthService,
              private readonly notifier: NotifierWithTemplateService,
              private readonly translate: TranslateService,
              @Inject(DOCUMENT) private document: Document,
              private consultantIdentityService: ConsultantIdentityService) {
    this.http = new HttpClient(hatchrAuthService);

    // URLs adapted for the hatchr platform
    // --------
    this.transactionsUrl = `${MultiPlatform.getDataEnvironmentHost()}/v1/payroll/transactions/types`;
    this.trxTypeAllUrl = `${this.transactionsUrl}/all`;
    this.deleteTransactionTypeUrl = `${this.transactionsUrl}/{transactionTypeId}`;
    this.trxTypeUrl = `${this.transactionsUrl}`;

    // OLD URLs (not modified) from payroll platform
    // --------
    // this.transactionsUrl = `${MultiPlatform.getDataEnvironmentHost()}api/admin/transactions`;
    this.trxConsultantUrlTemplate = `${this.transactionsUrl}/account/{accountId}`;
    this.trxInvoiceDraftConsultantUrlTemplate = `${this.trxConsultantUrlTemplate}/invoice-draft`;
    // this.trxTypeUrl = `${this.transactionsUrl}/types`;
    // this.trxTypeAllUrl = `${this.transactionsUrl}/types-all`;
    this.transactionCreationUrl = `${this.transactionsUrl}/new-transaction`;
    this.invoicesCreationUrl = `${this.transactionsUrl}/new-invoice`;
    this.paymentReceivedUrl = `${this.transactionsUrl}/{transactionId}/payment-received`;
    this.createManagementFeesUrl = `${this.transactionsUrl}/management-fees`;
    this.createMealVouchersFeesUrl = `${this.transactionsUrl}/meal-vouchers`;
    // this.deleteTransactionTypeUrl = `${this.trxTypeUrl}/{transactionTypeId}`;
    // this.createTransactionTypeUrl = `${this.transactionsUrl}/new-transaction-type`;
    // this.updateTransactionTypeUrl = `${this.transactionsUrl}/update-type`;
    this.transactionUrl = `${this.transactionsUrl}/{transactionId}`;
  }


  getConsultantInvoiceDraft(accountId: number, year: number, month: number): Observable<InvoicesDraft> {
    if (year === 0) {
      return this.http.get<InvoicesDraft>(`${this.getUrlInvoiceDraftTransaction(accountId)}`);
    }
    return this.http.get<InvoicesDraft>(`${this.getUrlInvoiceDraftTransaction(accountId)}/${year}/${month}`);
  }

  getAllTransactionsByConsultantId(accountId: number, year: number, month: number): Observable<ITrx[]> {
    if (year === 0) {
      return this.http.get<ITrx[]>(`${this.getUrlConsultantsTransaction(accountId)}`);
    }
    return this.http.get<ITrx[]>(`${this.getUrlConsultantsTransaction(accountId)}/${year}/${month}`);
  }

  getTransactionTypes(): Observable<ITrxType[]> {
    return this.http.get<ITrxType[]>(this.trxTypeUrl);
  }

  getAllTransactionTypes(): Observable<ITrxType[]> {
    return this.http.get<ITrxType[]>(this.trxTypeAllUrl).pipe(
      map(types => types.sort(((a, b) => a.typeName.localeCompare(b.typeName)))),
      catchError((err) => {
        console.error(err);
        this.translate.get('NAV.CONFIGURATIONS.TRANSACTION_TYPES.ERROR_LIST').pipe(take(1))
          .subscribe(message => this.notifier.notify(NotifierType.ERROR, message));
        return of(null);
      })
    );
  }

  createTransactionType(request: ITrxType): Observable<ITrxType> {
    return this.http.post<ITrxType>(this.transactionsUrl, request)
      .pipe(
        tap((trxType: ITrxType) => {
          if (trxType) {
            this.translate.get('NAV.CONFIGURATIONS.TRANSACTION_TYPES.SUCCESS_CREATE').pipe(take(1))
              .subscribe(message => this.notifier.notify(NotifierType.SUCCESS, message));
          } else {
            return null;
          }
        }),
        catchError(() => {
          this.translate.get('NAV.CONFIGURATIONS.TRANSACTION_TYPES.ERROR_CREATE').pipe(take(1))
            .subscribe(message => this.notifier.notify(NotifierType.ERROR, message));
          return of(null);
        })
      );
  }

  addTransactionType(request: ITrxType): Observable<ITrxType> {
    return this.http.post<ITrxType>(this.transactionsUrl, request);
  }

  updateTransactionType(request: ITrxType): Observable<ITrxType> {
    return this.http.put<ITrxType>(this.transactionsUrl, request);
  }

  createTransaction(request: TrxCreationRequest): Observable<ITrx> {
    return this.http.post<ITrx>(this.transactionCreationUrl, request);
  }

  createInvoice(request: TrxCreationRequest): Observable<ITrx> {
    return this.http.post<ITrx>(this.invoicesCreationUrl, request);
  }

  invoicePaymentReceived(transactionId: number, request: TrxCreationRequest): Observable<boolean> {
    return this.http.put<boolean>(this.getPaymentReceivedUrl(transactionId), request);
  }

  deleteTransaction(transactionId: string): Observable<boolean> {
    return this.http.delete<boolean>(this.getDeleteTransactionUrl(transactionId));
  }

  createMealVouchersFeesForYearAndMonth(date: Date): Observable<boolean> {
    return this.http.post<boolean>(this.createMealVouchersFeesUrl, date);
  }

  deleteTransactionType(transactionType: ITrxType): Observable<void> {
    return this.http.delete<void>(this.getDeleteTransactionTypeUrl(transactionType.id)).pipe(
      tap(() => this.translate.get('NAV.CONFIGURATIONS.TRANSACTION_TYPES.SUCCESS_DELETE').pipe(take(1))
        .subscribe(message => this.notifier.notify(NotifierType.SUCCESS, message))
      ),
      catchError(() => {
        this.translate.get('NAV.CONFIGURATIONS.TRANSACTION_TYPES.ERROR_DELETE').pipe(take(1))
          .subscribe(message => this.notifier.notify(NotifierType.ERROR, message));
        return of(null);
      })
    );
  }

  getTransaction(transactionId: string): Observable<ITrx> {
    return this.http.get<ITrx>(this.geTransactionUrl(transactionId));
  }

  updateTransaction(transactionId: string, request: TrxCreationRequest): Observable<ITrx> {
    return this.http.put<ITrx>(this.getUpdateTransactionUrl(transactionId), request);
  }

  downloadDocument(personalData: PersonalData, consultantId: number, year: number, month: number,
                   type: StatementDocumentType): Observable<any> {
    let extension = DocumentExtensionTypes.PDF;
    if (type === StatementDocumentType.EXCEL) {
      extension = DocumentExtensionTypes.EXCEL;
    }

    const downloadFile = `${getAbrvFullnameByPersonalData(personalData)}-statement-${MultiPlatform.get().abrvCompany}.${extension}`.toString().toLocaleLowerCase();
    return this.http.get(this.getDownloadFileUrl(consultantId, year, month, type), {
      responseType: 'blob',
      observe: 'response'
    }).pipe(
      tap((response: HttpResponse<any>) => {
        const downloadUrl = window.URL.createObjectURL(response.body);
        const link = this.document.createElement('a');
        link.href = downloadUrl;
        link.download = downloadFile;
        link.click();
      }),
      catchError(() => {
        this.translate.get('CONSULTANT.STATEMENT.ERROR.DOWNLOAD').pipe(take(1))
          .subscribe(error => this.notifier.error(error));
        return of(null);
      })
    );
  }

  downloadConsultantInvoiceDraft(personalData: PersonalData, consultantId: number, year: number, month: number, type: StatementDocumentType) {
    let extension = DocumentExtensionTypes.PDF;
    if (type === StatementDocumentType.EXCEL) {
      extension = DocumentExtensionTypes.EXCEL;
    }

    const downloadFile = `${getAbrvFullnameByPersonalData(personalData)}-invoices-${MultiPlatform.get().abrvCompany}.${extension}`.toString().toLocaleLowerCase();

    return this.http.get(this.getInvoiceDraftDownloadFileUrl(consultantId, year, month, type), {
      responseType: 'blob',
      observe: 'response'
    }).pipe(
      tap((response: HttpResponse<any>) => {
        const downloadUrl = window.URL.createObjectURL(response.body);
        const link = this.document.createElement('a');
        link.href = downloadUrl;
        link.download = downloadFile;
        link.click();
      }),
      catchError(() => {
        this.translate.get('CONSULTANT.STATEMENT.ERROR.DOWNLOAD').pipe(take(1))
          .subscribe(error => this.notifier.error(error));
        return of(null);
      })
    );
  }

  private getPaymentReceivedUrl(transactionId: number): string {
    return this.paymentReceivedUrl.replace(this.transactionIdStr, String(transactionId));
  }

  private getUrlConsultantsTransaction(accountId: number): string {
    return this.trxConsultantUrlTemplate.replace(this.accountIdStr, String(accountId));
  }

  private getUrlInvoiceDraftTransaction(accountId: number): string {
    return this.trxInvoiceDraftConsultantUrlTemplate.replace(this.accountIdStr, String(accountId));
  }

  private getDeleteTransactionUrl(transactionId: string): string {
    return this.transactionUrl.replace(this.transactionIdStr, transactionId);
  }

  private getDeleteTransactionTypeUrl(transactionTypeId: string): string {
    return this.deleteTransactionTypeUrl.replace(this.transactionTypeIdStr, transactionTypeId);
  }

  private geTransactionUrl(transactionId: string): string {
    return this.transactionUrl.replace(this.transactionIdStr, transactionId);
  }

  private getUpdateTransactionUrl(transactionId: string): string {
    return this.transactionUrl.replace(this.transactionIdStr, transactionId);
  }

  private getDownloadFileUrl(accountId: number, year: number, month: number, documentType: StatementDocumentType): string {
    return this.getUrlConsultantsTransaction(accountId)
      + '/' + year.toString() + '/' + month.toString() + '/download/' + documentType.toString();
  }

  private getInvoiceDraftDownloadFileUrl(accountId: number, year: number, month: number, documentType: StatementDocumentType): string {
    return this.getUrlInvoiceDraftTransaction(accountId)
      + '/' + year.toString() + '/' + month.toString() + '/download/' + documentType.toString();
  }
}



