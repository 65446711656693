import {ITrxType} from './transaction';

export interface ITemplate {
  id?: number;
  name: string;
  propertyMaps: IPropertyMap[];
  transactionType?: ITrxType;
}

export interface IPropertyMap {
  id?: number;
  externalKey: string;
  importInternalProperty?: {
    id: string,
    translation?: string,
    required?: boolean,
    importType?: IImportType
  };
  required: boolean;
  unique: boolean;
}
export interface IImportType {
  id: string;
  name: string;
}

export interface IImportResult {
  id?: number;
  dateInserted: Date;
  template?: ITemplate;
  deleted?: boolean;
  successes: IResultMessage[];
  errors: IResultMessage[];
  filename?: string;
  user?: string;
}

export interface IResultItemMessage {
  lines?: string[];
  column?: string;
  property?: string;
  value?: string;
  type?: string;
  number?: string;
}

export interface IResultMessage {
  messages: IResultItemMessage[];
  numberLine: number;
  idConsultant?: number | any;
  nameConsultant?: string | any;
}

export enum enumStatus {
  initial = 'initial',
  loading = 'loading',
  correctFile = 'correctFile',
  incorrectFile = 'incorrectFile',
  correctMapping = 'correctMapping',
  incorrectMapping = 'incorrectMapping'
}
