import {MngFeeTypeEnum} from '../app/main/models/admin/consultant-account';
import {TypeInstance} from './instance-enum';

const instance: string = window['env']['instance'] || TypeInstance.OYESK_LUX;
const production: string = window['env']['production'] || false;
const hmr: string = window['env']['hmr'] || false;
const isDebugMode: string = window['env']['isDebugMode'] || true;
const host: string = window['env']['host'] || 'http://localhost:9013';
const auth_redirectUrl: string = window['env']['auth_redirectUrl'] || 'http://localhost:4200';
const api_audience: string = window['env']['api_audience'] || 'http://localhost:8080';
const default_mng_fee_value: number = window['env']['default_mng_fee_value'] || 390;
const default_mng_fee_type: string = window['env']['default_mng_fee_type'] || MngFeeTypeEnum.MONETARY;
const default_mng_fee_2022_value: number = window['env']['default_mng_fee_2022_value'] || 490;
const default_mng_fee_2022_startDate: string = window['env']['default_mng_fee_2022_startDate'] || '2022-10-10';
const default_mng_fee_2022_yearToStartAsDefault: number = window['env']['default_mng_fee_2022_yearToStartAsDefault'] || 2023;
const default_mng_fee_2022_type: string = window['env']['default_mng_fee_2022_type'] || MngFeeTypeEnum.MONETARY;
const screebSurvey_websiteID: string = window['env']['screebSurvey_websiteID'] || '2b3c662b-fa1c-4e1f-9551-71b315235c7e';

const marketplace_url: string = window['env']['marketplace_url'] || 'https://dev.oyesk.com';
const marketplace_auth0_app_metadata_property: string = window['env']['marketplace_auth0_app_metadata_property'] || 'https://oyesk-api.oyesk.com/app_metadata';
const marketplace_launch_day: string = window['env']['marketplace_launch_day'] || 29;
const marketplace_launch_month: string = window['env']['marketplace_launch_month'] || 10;
const marketplace_launch_year: string = window['env']['marketplace_launch_year'] || 2022;

const externalWebsites_guichet: string = window['env']['externalWebsites_guichet'] || 'https://oyesk.atlassian.net/servicedesk/customer/portal/1';
const externalWebsites_faq: string = window['env']['externalWebsites_faq'] || 'https://oyesk.atlassian.net/wiki/spaces/CS1/overview';
const externalWebsites_pickup: string = window['env']['externalWebsites_pickup'] || 'https://outlook.office365.com/owa/calendar/Oyesk@luxpayroll.lu/bookings/';


const auth0_application_domain: string = window['env']['auth0_application_domain'] || 'local-oyesk.eu.auth0.com';
const auth0_application_clientID: string = window['env']['auth0_application_clientID'] || 'qSODbqGsWEQjIECaca2QGzLv0cuWPwtN';
const auth0_application_claimsLux: string = window['env']['auth0_application_claimsLux'] || 'https://luxpayroll/user_authorization';

export const environment = {
  company: {
    instance: instance
  },
  production: production,
  hmr: hmr,
  isDebugMode: isDebugMode,
  host: host,
  auth: {
    redirectUrl: auth_redirectUrl
  },
  api: {
    audience: api_audience
  },
  default_mng_fee: {
    value: default_mng_fee_value,
    type: default_mng_fee_type
  },
  default_mng_fee_2022: {
    value: default_mng_fee_2022_value,
    startDate: default_mng_fee_2022_startDate,
    yearToStartAsDefault: default_mng_fee_2022_yearToStartAsDefault, // for FR, with a big enough year it won't change anything in the current functionality
    type: default_mng_fee_2022_type
  },
  screebSurvey: {
    websiteID: screebSurvey_websiteID
  },
  marketplace: {
    url: marketplace_url,
    auth0_app_metadata_property: marketplace_auth0_app_metadata_property,
    launch: {
      day: marketplace_launch_day,
      month: marketplace_launch_month,
      year: marketplace_launch_year
    }
  },
  externalWebsites: {
    guichet: externalWebsites_guichet,
    faq: externalWebsites_faq,
    pickup: externalWebsites_pickup
  }
};

export const DataCompanyInstance = {

  instance: TypeInstance.OYESK_LUX,
  nameCompany: 'Oyesk',
  abrvCompany: 'lu',
  localeLangI18n: 'en',
  assets: '/assets/images/',
  logo: 'logo_blue.png',
  minLogo: 'mini_logo_blue.png',
  icon: '/assets/icons/oyesk/favicon.ico',
  classFooterLogo: 'oyesk-footer-logo',
  classDefaultLogo: 'oyesk-logo',
  login: 'Oyesk - Portal Login',
  signup: 'Oyesk - Portal Sign Up',
  loading: 'Loading...',
  phone: '+352 28 77 34 50',
  simulationPdf: 'https://luxpayroll.lu/simulation.pdf',
  popupHbspt: false,
  infoMail: 'info@oyesk.com',
  payrollEmail: 'payroll@oyesk.com',
  timesheetsMail: 'timesheet@oyesk.com',
  invoicesTimesheetsMail: 'admin@oyesk.com',
  contractRenewalsMail: 'contact@oyesk.com',
  salaireSimulationPdf: 'simulation-salary.pdf',
  privacyPolicyUrl: '/assets/documents/PRIVACY___COOKIE_STATEMENT_Oyesk_LUX.pdf',
  fixedTravelCostsUrl: 'https://impotsdirects.public.lu/fr/az/f/frais_depl.html',
  currencyCode: 'EUR',
  display: 'symbol',
  digitsInfo: '0.2-2',
  placesItem: '0.2',
  placesGraph: '0.2-2',
  locale: 'fr-FR',
  locale2: 'fr',
  provideLocaleId: 'en-EN',
  policyFeatures: {
    CONTRACTS: false,
    HOLIDAYS: false,
    TIME_SHEET: false,
    REQUEST: false,
    COUNTRIES: false,
    SHOW_SALARY_SIMULATION_PUBLIC: false,
    AGENCY: false
  },
  graph: {
    precision: 2
  },
  money: {
    specialCharacters: [',', '.'],
    mask: '0*V00',
    decimalMarker: ',',
    patterns: {
      separator: '[.,]',
      numbers: '[0-9]'
    }
  },
  pert: {
    specialCharacters: [',', '.'],
    mask: '0*V00',
    decimalMarker: ',',
    patterns: {
      separator: '[.,]',
      numbers: '[0-9]'
    }
  },
  date: {
    formats: {
      default: 'dd/MM/yyyy',
      notification: 'MMM d, y, HH:mm'
    }
  },
  download: {
    salaryScales: 'salary-scales-%filename%-%country%.csv',
    simulationPublicPdf: 'simulation.pdf'
  },
  auth0: {
    application: {
      domain: auth0_application_domain,
      clientID: auth0_application_clientID,
      claimsLux: auth0_application_claimsLux
    }
  }
};
