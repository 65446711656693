import {AfterViewInit, Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-public-salary-simulation-pdf',
  templateUrl: './public-salary-simulation-pdf.component.html',
  styleUrls: ['./public-salary-simulation-pdf.component.scss']
})
export class PublicSalarySimulationPdfComponent implements OnInit, AfterViewInit {

  constructor() {

  }

  ngOnInit() {

  }

  ngAfterViewInit(): void {
  }
}
