import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import MultiPlatform from '../../../../environments/multi-platform';
import {IAuth0UserProfile} from '../../models/admin/auth0';
import {USER_AUTH} from '../../../core/utils/constants';

export const SIGN_UP_CHECK_URL = `${MultiPlatform.getDataEnvironmentHost()}api/public/consultants`;

@Injectable({
  providedIn: 'root'
})
export class EnrollmentService {

  constructor(private readonly http: HttpClient) {
  }

  checkIfCreatesAccount(user: IAuth0UserProfile): Observable<boolean> {
    user.rolesList = user[USER_AUTH].roles;
    return this.http.post<boolean>(SIGN_UP_CHECK_URL, user);
  }
}
