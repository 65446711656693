import {Injectable} from '@angular/core';
import {Router, Resolve} from '@angular/router';
import {LocalStorageService} from '../../main/services/local-storage-service';
import {LangConf} from '../utils/languages';
import {LangRoute} from '../utils/routes';

@Injectable()
export class LanguageConsultantResolver implements Resolve<string> {

  constructor(private router: Router,
              private localStorageService: LocalStorageService) {
  }

  resolve(route, state) {
    if (route.params[LangRoute.RESOLVER_LANG] == null) {
      return null;
    }
    let storedLanguage = route.params[LangRoute.RESOLVER_LANG];
    if (!LangConf.getAvailableLanguages().includes(storedLanguage)) {
      this.localStorageService.setLanguage(this.localStorageService.getDataCompanyByI18n());
    } else if (storedLanguage) {
      if (LangRoute.getLang() === storedLanguage) {
          storedLanguage = this.localStorageService.getDataCompanyByI18n();
      }
      this.localStorageService.setLanguage(storedLanguage);
    }
    return null;
  }
}
