import {RoleToRouteEnum} from '../../main/models/admin/auth0';
import {AdminRoute, ConsultantRoute, RoutesAdminConsultants} from './routes';
import {Router} from '@angular/router';
import {LocalStorageService} from '../../main/services/local-storage-service';
import {Platform} from './global';

export enum Languages {
  FRENCH = 'fr',
  ENGLISH = 'en',
  GERMAN = 'de'
}

export const TRANSLATER_MATCH = /en|fr|de/;
export const DIR = '/assets/i18n/';
export const SUFFIX = '.json';

export class LangConf {

  public static getTranslateHttpLoader() {
    return `.${DIR}`;
  }

  public static getFile(lang: string): any {
    return `./..${DIR}${lang}${SUFFIX}`;
  }

  public static getAvailableLanguages(): any {
    return [Languages.FRENCH.toString(), Languages.ENGLISH.toString(), Languages.GERMAN.toString()];
  }

  public static changeLanguage(platform: Platform,
                               lang: any,
                               role: RoleToRouteEnum,
                               router: Router): any {
    platform.changeLanguage(lang);

    const url = `${lang}${router.url.substring((router.url.substring(1)).indexOf('/') + 1)}`;
    router.navigateByUrl(url);
  }

  public static navigateByUrlLanguage(platform: Platform,
                                      role: RoleToRouteEnum,
                                      router: Router,
                                      localStorageService: LocalStorageService): any {
    const lang = localStorageService.getLanguage();
    if (RoleToRouteEnum.ADMIN === role) {
      router.navigateByUrl(localStorageService.changeUrlLanguageRoute(AdminRoute.ADMIN, RoutesAdminConsultants.CONSULTANTS, lang));
    } else if (RoleToRouteEnum.CONSULTANT === role) {
      router.navigateByUrl(localStorageService.changeUrlLanguageRoute(ConsultantRoute.CONSULTANT, ConsultantRoute.HOME, lang));
    }
  }

  public static changeLanguageAndRoute(platform: Platform,
                                       lang: any,
                                       role: RoleToRouteEnum,
                                       router: Router,
                                       route: string,
                                       localStorageService: LocalStorageService): any {
    platform.changeLanguage(lang);
    LangConf.navigateByUrlLanguageToRoute(platform, role, router, route, localStorageService);
  }

  public static navigateByUrlLanguageToRoute(platform: Platform,
                                             role: RoleToRouteEnum,
                                             router: Router,
                                             route: string,
                                             localStorageService: LocalStorageService): any {
    const lang = localStorageService.getLanguage();
    if (RoleToRouteEnum.ADMIN === role) {
      router.navigateByUrl(localStorageService.changeUrlLanguageRoute(AdminRoute.ADMIN, route, lang));
    } else if (RoleToRouteEnum.CONSULTANT === role) {
      router.navigateByUrl(localStorageService.changeUrlLanguageRoute(ConsultantRoute.CONSULTANT, route, lang));
    }
  }
}
