import {GeneralRequest, LocaleRequest} from '../../main/models/common/general';
import {LangConf, Languages, TRANSLATER_MATCH} from './languages';
import {TranslateService} from '@ngx-translate/core';
import {LocalStorageService} from '../../main/services/local-storage-service';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {take} from 'rxjs/operators';
import {forkJoin} from 'rxjs';
import MultiPlatform from '../../../environments/multi-platform';
import {Gps} from './gps';
import {DataInstance} from '../../../environments/instance-enum';

@Injectable()
export class Platform {

  general: GeneralRequest;

  constructor(private readonly translate: TranslateService,
              private localStorageService: LocalStorageService,
              private httpClient: HttpClient) {

  }

  public getAvailableLanguages(): any {
    // todo It´s done - change when LUX-254 is ready
    const availableLanguage: string[] = [];
    if (this.general && this.general.locales) {
      this.general.locales.forEach((localeRequest: LocaleRequest) => {
        availableLanguage.push(localeRequest.code);
      });
    }
    return availableLanguage;
  }

  public geolocation() {
    if (navigator.geolocation) {
      const optionsGeolocation = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      };
      navigator.geolocation.getCurrentPosition(this.successGeolocation, this.errorGeolocation, optionsGeolocation);
    }
  }

  private successGeolocation(position: Position) {
    if (position) {
      const gps: Gps = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude
      };
      sessionStorage.setItem(DataInstance.GPS, JSON.stringify(gps));
    } else {
      sessionStorage.setItem(DataInstance.GPS, null);
    }
  }

  private errorGeolocation(err: any) {
  }

  changeLanguage(lang: any) {
    if (this.localStorageService && lang) {
      this.localStorageService.setLanguage(lang);
      forkJoin([this.httpClient.get(LangConf.getFile(lang)).pipe(take(1))]).subscribe(([i18n]) => {
        this.translate.setTranslation(lang, i18n);
        this.translate.setDefaultLang(lang);
        const storedLanguage = this.localStorageService.getLanguage();
        if (storedLanguage && LangConf.getAvailableLanguages().includes(storedLanguage)) {
          this.translate.use(storedLanguage);
        } else {
          const browserLang = this.translate.getBrowserLang();
          this.translate.use(browserLang.match(TRANSLATER_MATCH) ? browserLang : Languages.ENGLISH);
        }
      });
    }
  }

  checkLang(lang: any) {
    if (this.general && this.general.locales) {
      const data = this.general.locales.filter(o => o.code === lang);
      if (data && data.length !== 0) {
        return data[0].code;
      }
    }
    if (MultiPlatform.get().data) {
      return MultiPlatform.get().data.localeLangI18n;
    }
    return Languages.ENGLISH;
  }

  public isWindowsSupportedBrowser() {
    const agent = window.navigator.userAgent.toUpperCase();
    const isWindows = /^WIN/.test(navigator.platform.toUpperCase());
    const isSafari = /^SAFARI/.test(agent);
    if (isWindows && isSafari) {
      return false;
    }
    return true;
  }
}
