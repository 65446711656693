import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CustomSlideToggleComponent} from './custom-slide-toggle/custom-slide-toggle.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexModule} from '@angular/flex-layout';
import {TranslateModule} from '@ngx-translate/core';


@NgModule({
  declarations: [CustomSlideToggleComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FlexModule,
    TranslateModule,
    FormsModule
  ],
  exports: [
    CustomSlideToggleComponent
  ]
})
export class CustomSlideToggleModule {
}
