import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import MultiPlatform from '../../../../environments/multi-platform';
import {IAuth0Role, IAuth0User} from '../../models/admin/auth0';

export const AUTH_ROLES_URL = `${MultiPlatform.getDataEnvironmentHost()}api/admin/auth0`;

@Injectable({
  providedIn: 'root'
})
export class AuthRolesService {

  constructor(private readonly http: HttpClient) {
  }

  getAuthUsers(): Observable<IAuth0User[]> {
    return this.http.get<IAuth0User[]>(`${AUTH_ROLES_URL}/users`);
  }

  getAuthRoles(): Observable<IAuth0Role[]> {
    return this.http.get<IAuth0Role[]>(`${AUTH_ROLES_URL}/roles`);
  }

  getUserRoles(userId): Observable<string[]> {
    return this.http.get<string[]>(`${AUTH_ROLES_URL}/user-roles/${userId}`);
  }

  addUserToRole(userId, role): Observable<void> {
    return this.http.put<void>(`${AUTH_ROLES_URL}/user-roles/${userId}/add`, role);
  }

  removeUserFromRole(userId, role): Observable<void> {
    return this.http.put<void>(`${AUTH_ROLES_URL}/user-roles/${userId}/remove`, role);
  }
}
