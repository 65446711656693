import { Component, OnInit } from '@angular/core';
import MultiPlatform from '../../../../environments/multi-platform';
import {PublicRoute} from '../../../core/utils/routes';

@Component({
  selector: 'app-supported-browsers',
  templateUrl: './supported-browsers.component.html',
  styleUrls: ['./supported-browsers.component.scss']
})
export class SupportedBrowsersComponent implements OnInit {

  public data = MultiPlatform.get();

  constructor() { }

  ngOnInit() {
  }

  getLinkHome(): any {
    return PublicRoute.HOME;
  }
}
