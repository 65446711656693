import {NgModule} from '@angular/core';
import {NoResultsComponent} from './no-results.component';
import {TranslateModule} from '@ngx-translate/core';
import {MatButtonModule} from '@angular/material/button';
import {FlexLayoutModule} from '@angular/flex-layout';
import {CommonModule} from '@angular/common';

@NgModule({
  declarations: [NoResultsComponent],
  imports: [
    TranslateModule,
    MatButtonModule,
    FlexLayoutModule,
    CommonModule
  ],
  exports: [NoResultsComponent]
})

export class NoResultsModule {
}
