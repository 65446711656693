import {Injectable} from '@angular/core';
import {IdentitiesMapping} from '../../models/admin/identities-mapping';
import {getAbrvFullnameByIdentitiesMapping} from '../../../core/utils/util-functions';

@Injectable({
  providedIn: 'root'
})
export class ConsultantIdentityService {
  private consultantIdentity: IdentitiesMapping;

  constructor() {
  }

  setConsultantIdentity(value: IdentitiesMapping): void {
    this.consultantIdentity = value;
  }

  getConsultantIdentity(): IdentitiesMapping {
    return this.consultantIdentity;
  }

  getConsultantName(): string {
    return this.consultantIdentity ?
      `${this.consultantIdentity.firstName} ${this.consultantIdentity.lastName}` :
      null;
  }

  getConsultantFirstName(): string {
    return this.consultantIdentity ?
      this.consultantIdentity.firstName :
      null;
  }

  getConsultantId(): string {
    return (this.consultantIdentity && this.consultantIdentity.ids) ? this.consultantIdentity.ids.CONSULTANT_ID : null;
  }

  getAbrvFullname(): string {
    const data = this.getConsultantIdentity();
    return getAbrvFullnameByIdentitiesMapping(data);
  }
}
