import MultiPlatform from '../../../environments/multi-platform';

export const DEFAULT_FILE_ENCODING = 'UTF-8';
export const CLASS_MAT_INPUT_ERROR = 'mat-form-field-invalid';

export const TITLE = 'title';
export const UTIL_MONTHS = [
  {index: 0, name: 'January'},
  {index: 1, name: 'February'},
  {index: 2, name: 'March'},
  {index: 3, name: 'April'},
  {index: 4, name: 'May'},
  {index: 5, name: 'June'},
  {index: 6, name: 'July'},
  {index: 7, name: 'August'},
  {index: 8, name: 'September'},
  {index: 9, name: 'October'},
  {index: 10, name: 'November'},
  {index: 11, name: 'December'}
];

export const WEEK_DAYS = [
  {
    label: 'MON',
    translation: 'CONSULTANT.TIME_SHEETS.WEEK_DAYS.MON',
    dayIndex: 1,
  }, {
    label: 'TUE',
    translation: 'CONSULTANT.TIME_SHEETS.WEEK_DAYS.TUE',
    dayIndex: 2,
  }, {
    label: 'WED',
    translation: 'CONSULTANT.TIME_SHEETS.WEEK_DAYS.WED',
    dayIndex: 3,
  }, {
    label: 'THU',
    translation: 'CONSULTANT.TIME_SHEETS.WEEK_DAYS.THU',
    dayIndex: 4,
  }, {
    label: 'FRI',
    translation: 'CONSULTANT.TIME_SHEETS.WEEK_DAYS.FRI',
    dayIndex: 5,
  }, {
    label: 'SAT',
    translation: 'CONSULTANT.TIME_SHEETS.WEEK_DAYS.SAT',
    dayIndex: 6,
  }, {
    label: 'SUN',
    translation: 'CONSULTANT.TIME_SHEETS.WEEK_DAYS.SUN',
    dayIndex: 0,
  }
];

export const SORTED_WEEK_DAYS = JSON.parse(JSON.stringify(WEEK_DAYS)).sort((a, b) => a.dayIndex - b.dayIndex);

export const CHECK_MOMENT_FORMAT_DATE = 'YYYY-MM-DD';

export const YY_MM_DD_LOCALE_FORMAT = 'fr-CA';
export const DEFAULT_FORMAT_DATE_EN = 'YYYY-MM-DD';
export const DEFAULT_FORMAT_DATE_HOLIDAY = 'DD/MM/YYYY';
export const BOX_DIALOG = 'delete-dialog';
export const BOX_DIALOG_REQUEST = 'edit-request-dialog';
export const MIN_YEAR = 2017;
export const MIN_VALUE_SIMULATOR_GRAPH: any = 3;
export const DEFAULT_CODE_COUNTRY = 'LU';
export const DEFAULT_DEFAULT_CODE_LANG = 'en_GB';
export const DEFAULT_SECURITY_SOCIAL = '0000000000';
export const PERCENTAGE = 'PER';
export const EUR = 'EUR';
export const PDF = 'pdf';
export const TXT = 'txt';

export const BUTTON_GRAY = 'button-gray';

export const MAX_YEARS = 4;
export const MAX_ADD_DOCUMENTS = 5;

export const PAGE_SIZE_OPTIONS = [25, 50, 100, 200];
export const SPINNER_NAME = 'sp';
export const SPINNER_NAME_1 = 'sp1';
export const SPINNER_UPDATE_DATE = 'spinner_edit';

export const USER_AUTH = 'https://luxpayroll/user_authorization';

export const HIDE_DIALOG_REF = 'hide_dialog_ref';

export const APPLICATION_PDF = 'application/pdf';
export const TEXT_PLAIN = 'text/plain';
export const APPLICATION_JSON = 'application/json';
export const CONTENT_TYPE = 'Content-Type';
export const CONTENT_TYPE_JSON = {'Content-Type': APPLICATION_JSON};

export const PARAM_PAGE = 'page';
export const PARAM_SIZE = 'size';
export const PARAM_SORT = 'sort';
export const PARAM_STATUS = 'status';
export const PARAM_COUNTRY = 'countryId';
export const PARAM_SEARCH = 'search';

export const NOTIFICATIONS_PARAM_STATUS = PARAM_STATUS;
export const ORDER_ASC = 'asc';
export const CONTENT_AUTHORIZATION_KEY = 'Authorization';
export const CONTENT_AUTHORIZATION_VALUE = 'Bearer';

export const FULL_TIME = 'FULL_TIME';
export const PART_TIME = 'PART_TIME';
export const PARAMETER_FINAL_NET_SALARY = 'PARAMETER_FINAL_NET_SALARY';

export const DESC = 'DESC';
export const LOWER_DESC = 'desc';

export function convertFilenameToDownload(filename: string, value: string, env: any): string {
  return value.replace('%country%', MultiPlatform.get().abrvCompany).replace('%filename%', filename);
}

export const IMAGE_TYPE_JPEG = 'image/jpeg';
export const PDF_FORMAT = 'a4';
export const PDF_UNIT = 'px';

export const HIGHLIGHT_CHANGE = 'highlightChange';
export const HIGHLIGHT_CHANGE_RADIO = 'highlightChangeRadio';
export const EMERGENCY_CONTACT_LAST_NAME = 'emergencyContactLastName';
export const DATA_PERSONAL = 'data-personal';

export const REGEX_PERCENTAGE = /^(100(\.00?)?|[1-9]?\d(\.\d\d?)?)$/;

export const MOBILE = 'mobile';
export const EMERGENCY_CONTACT_MOBILE = 'emergencyContactMobile';
export const PHONE_NUMBER = 'phoneNumber';
