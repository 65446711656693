import {Injectable} from '@angular/core';
import {SCREEN_SIZES} from '../../core/utils/screen-size.enum';
import {CLASS_MAT_DIALOG_TYPE} from '../../core/utils/responsive';
import {BOX_DIALOG, BOX_DIALOG_REQUEST} from '../../core/utils/constants';

@Injectable({
  providedIn: 'root'
})
export class ResponsiveService {

  constructor() {
  }

  private getDetectWidth(): any {
    return SCREEN_SIZES.filter(o => window.innerWidth >= o.begin && window.innerWidth <= o.end)[0];
  }

  public getMatDialogAndBox(name: CLASS_MAT_DIALOG_TYPE): string[] {
    return [BOX_DIALOG, `${name}${this.getDetectWidth().name}`];
  }

  public getMatDialogAndBoxAndRequest(name: CLASS_MAT_DIALOG_TYPE): string {
    return `${BOX_DIALOG_REQUEST} ${name}${this.getDetectWidth().name}`;
  }

  public getMatDialog(name: CLASS_MAT_DIALOG_TYPE): string {
    return `${name}${this.getDetectWidth().name}`;
  }

  public getSizeResponsive(): any {
    return this.getDetectWidth().name;
  }
}
