import {Auth0UserProfile} from 'auth0-js';

export interface IAuth0Profile extends Auth0UserProfile {
  // @ts-ignore
  [environment.auth.claimsLux.toString()]: IAuth0UserAuthorization;
}

export interface IAuth0UserAuthorization {
  groups: string[];
  roles: string[];
  permissions: string[];
}

export enum RolesEnum {
  ADMIN_LUX = 'ADMIN',
  ADMIN_OYESK = 'ADMIN_FR',
  CONSULTANT_LUX = 'CONSULTANT',
  CONSULTANT_OYESK = 'CONSULTANT_FR'
}

export enum RoleToRouteEnum {
  ADMIN = 'admin',
  CONSULTANT = 'consultant'
}

export enum RoutePathMatchType {
  FULL= 'full'
}

export interface IAuth0User {
  email: string;
  name: string;
  picture: string;
  user_id: string;
  last_login: string;
  logins_count: string;
  rolesList: RolesEnum[];
}

export interface IAuth0UserProfile extends Auth0UserProfile {
  rolesList?;
}

export interface IAuth0Role {
  _id: string;
  name: RolesEnum;
  applicationId: string;
  description: string;
}
