import {NgModule} from '@angular/core';
import {InlineSVGModule} from 'ng-inline-svg';
import {TranslateModule} from '@ngx-translate/core';
import {MatButtonModule} from '@angular/material/button';
import {ExtendedModule, FlexModule} from '@angular/flex-layout';
import {BarTitleSearchComponent} from './bar-title-search/bar-title-search.component';
import {MatSelectModule} from '@angular/material/select';
import {ReactiveFormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {CommonModule} from '@angular/common';
import {MatToolbarModule} from '@angular/material/toolbar';
import {BarBackComponent} from './bar-back/bar-back.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MenuChangeLanguageComponent} from './menu-change-language/menu-change-language.component';
import {MatDividerModule} from '@angular/material/divider';
import {_MatMenuDirectivesModule, MatMenuModule} from '@angular/material/menu';
import { SalarySimulationComponent } from './salary-simulation/salary-simulation.component';
import {CustomPipesModule} from "../../../core/formatters/custom-pipes/custom-pipes.module";
import {ChartsModule} from "ng2-charts";
import {NgxMaskModule} from "ngx-mask";
import {MatRadioModule} from "@angular/material/radio";
import {MatSliderModule} from "@angular/material/slider";
import {CustomSlideToggleModule} from "../../utils/custom-slide-toggle.module";
import {MatDialogModule} from "@angular/material/dialog";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";

@NgModule({
  declarations: [
    BarTitleSearchComponent,
    BarBackComponent,
    MenuChangeLanguageComponent,
    SalarySimulationComponent
  ],
  imports: [
    CommonModule,
    InlineSVGModule,
    TranslateModule,
    MatButtonModule,
    ExtendedModule,
    FlexModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatIconModule,
    MatInputModule,
    MatToolbarModule,
    MatTooltipModule,
    MatDividerModule,
    _MatMenuDirectivesModule,
    MatMenuModule,
    CustomPipesModule,
    ChartsModule,
    NgxMaskModule,
    MatRadioModule,
    MatSliderModule,
    CustomSlideToggleModule,
    MatDialogModule,
    MatSlideToggleModule
  ],
  providers: [
  ],
  exports: [
    BarTitleSearchComponent,
    BarBackComponent,
    MenuChangeLanguageComponent,
    SalarySimulationComponent
  ],
  entryComponents: [
  ]
})
export class ComponentsModule {
}
