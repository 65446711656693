import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {TypeInstance} from '../../../environments/instance-enum';
import {RolesEnum} from '../models/admin/auth0';

@Injectable({
  providedIn: 'root'
})
export class InstanceService {

  constructor() { }

  public getInstance(): any {
    return environment.company.instance;
  }

  public isOyeskLux() {
    return TypeInstance.OYESK_LUX === this.getInstance();
  }

  public isOyeskFrance() {
    return TypeInstance.OYESK_FRANCE === this.getInstance();
  }

  get instanceRoles() {
    if (TypeInstance.OYESK_LUX === this.getInstance()) {
      return [RolesEnum.ADMIN_LUX, RolesEnum.CONSULTANT_LUX];
    } else if (TypeInstance.OYESK_FRANCE === this.getInstance()) {
      return [RolesEnum.ADMIN_OYESK, RolesEnum.CONSULTANT_OYESK];
    }
    return [];
  }

  get instanceAdminRole() {
    const instance = this.getInstance();
    if (TypeInstance.OYESK_LUX === instance) {
      return RolesEnum.ADMIN_LUX;
    } else if (TypeInstance.OYESK_FRANCE === instance) {
      return RolesEnum.ADMIN_OYESK;
    }
    return null;
  }

  get instanceConsultantRole() {
    if (TypeInstance.OYESK_LUX === this.getInstance()) {
      return RolesEnum.CONSULTANT_LUX;
    } else if (TypeInstance.OYESK_FRANCE === this.getInstance()) {
      return RolesEnum.CONSULTANT_OYESK;
    }
    return null;
  }
}
