import {MultiLineLabel, SingleLineLabel} from 'ng2-charts/lib/base-chart.directive';
import {SingleDataSet} from 'ng2-charts';
export declare type Label = SingleLineLabel | MultiLineLabel;
import {Color, Label} from 'ng2-charts';
import MultiPlatform from '../../../environments/multi-platform';

export enum TotalListGraphLux {
  LIST8 = 8,
  LIST7 = 7,
  LIST6 = 6,
  LIST5 = 5,
  LIST4 = 4,
  LIST3 = 3,
  LIST2 = 2,
  LIST1 = 1
}

export class ModelBaseLegend {
  color: string;
  label: Label;

  constructor(label: Label, color: string) {
    this.label = label;
    this.color = color;
  }

}

export class ModelBaseChart {
  label: string;
  valid: boolean;
  pert: number;
  color: string;

  constructor(label: string, color: string, pert: number) {
    this.label = label;
    this.color = color;
    this.valid = true;
    this.pert = pert;
  }
}

export class DataBaseChart {
  index = 0;
  list: ModelBaseChart[];

  public colors: Color[] = [
    {backgroundColor: []},
  ];

  public labels: Label[];
  public labels8: Label[] = ['', '', '', '', '', '', '', ''];
  public labels7: Label[] = ['', '', '', '', '', '', ''];
  public labels6: Label[] = ['', '', '', '', '', ''];
  public labels5: Label[] = ['', '', '', '', ''];
  public labels4: Label[] = ['', '', '', ''];
  public labels3: Label[] = ['', '', ''];
  public labels2: Label[] = ['', ''];
  public labels1: Label[] = [''];

  public listColor: string[] = [];
  public colors8: string[] = ['', '', '', '', '', '', '', ''];
  public colors7: string[] = ['', '', '', '', '', '', ''];
  public colors6: string[] = ['', '', '', '', '', ''];
  public colors5: string[] = ['', '', '', '', ''];
  public colors4: string[] = ['', '', '', ''];
  public colors3: string[] = ['', '', ''];
  public colors2: string[] = ['', ''];
  public colors1: string[] = [''];

  public singleDataSet: SingleDataSet = [];

  public singleDataSet8: SingleDataSet = [
    [0, 0, 0, 0, 0, 0, 0, 0]
  ];

  public singleDataSet7: SingleDataSet = [
    [0, 0, 0, 0, 0, 0, 0]
  ];

  public singleDataSet6: SingleDataSet = [
    [0, 0, 0, 0, 0, 0]
  ];

  public singleDataSet5: SingleDataSet = [
    [0, 0, 0, 0, 0]
  ];

  public singleDataSet4: SingleDataSet = [
    [0, 0, 0, 0]
  ];

  public singleDataSet3: SingleDataSet = [
    [0, 0, 0]
  ];

  public singleDataSet2: SingleDataSet = [
    [0, 0]
  ];

  public singleDataSet1: SingleDataSet = [
    [0]
  ];

  public getColors(): any {
    const listModelBaseChart = this.getListWithValid();
    this.listColor = [];
    if (listModelBaseChart.length === TotalListGraphLux.LIST8) {
      this.listColor = this.colors8;
    } else if (listModelBaseChart.length === TotalListGraphLux.LIST7) {
      this.listColor = this.colors7;
    } else if (listModelBaseChart.length === TotalListGraphLux.LIST6) {
      this.listColor = this.colors6;
    } else if (listModelBaseChart.length === TotalListGraphLux.LIST5) {
      this.listColor = this.colors5;
    } else if (listModelBaseChart.length === TotalListGraphLux.LIST4) {
      this.listColor = this.colors4;
    } else if (listModelBaseChart.length === TotalListGraphLux.LIST3) {
      this.listColor = this.colors3;
    } else if (listModelBaseChart.length === TotalListGraphLux.LIST2) {
      this.listColor = this.colors2;
    } else {
      this.listColor = this.colors1;
    }
    let index = 0;
    listModelBaseChart.forEach(data => {
      this.listColor[index] = data.color;
      index += 1;
    });
    this.colors[0].backgroundColor = this.listColor;
    return this.colors;
  }

  constructor() {
    this.list = [];
  }

  add(data: ModelBaseChart) {
    this.list.push(data);
  }

  getListWithValid(): ModelBaseChart[] {
    return this.list.filter(o => o.valid === true);
  }

  getListWithValidAndMoreFive(): ModelBaseChart[] {
    return this.list.filter(o => o.valid === true && o.pert >= 5);
  }

  getValues(): SingleDataSet {
    const listModelBaseChart = this.getListWithValid();
    if (listModelBaseChart.length === TotalListGraphLux.LIST8) {
      this.singleDataSet = this.singleDataSet8;
    } else if (listModelBaseChart.length === TotalListGraphLux.LIST7) {
      this.singleDataSet = this.singleDataSet7;
    } else if (listModelBaseChart.length === TotalListGraphLux.LIST6) {
      this.singleDataSet = this.singleDataSet6;
    } else if (listModelBaseChart.length === TotalListGraphLux.LIST5) {
      this.singleDataSet = this.singleDataSet5;
    } else if (listModelBaseChart.length === TotalListGraphLux.LIST4) {
      this.singleDataSet = this.singleDataSet4;
    } else if (listModelBaseChart.length === TotalListGraphLux.LIST3) {
      this.singleDataSet = this.singleDataSet3;
    } else if (listModelBaseChart.length === TotalListGraphLux.LIST2) {
      this.singleDataSet = this.singleDataSet2;
    } else {
      this.singleDataSet = this.singleDataSet1;
    }
    const result = this.singleDataSet;
    let index = 0;
    listModelBaseChart.forEach(data => {
      const r = Math.round((data.pert + Number.EPSILON) * 100) / 100;
      const precision = MultiPlatform.get().graph.precision;
      result[index] = +(Math.round(Number(r + 'e+' + precision)) + 'e-' + precision);
      index += 1;
    });
    return result;
  }

  getLegends(): ModelBaseLegend[]  {
    const cs = this.getLabels();
    const listModelBaseChart = this.getListWithValid();
    let listColor = [];
    if (listModelBaseChart.length === TotalListGraphLux.LIST8) {
      listColor = this.colors8;
    } else if (listModelBaseChart.length === TotalListGraphLux.LIST7) {
      listColor = this.colors7;
    } else if (listModelBaseChart.length === TotalListGraphLux.LIST6) {
      listColor = this.colors6;
    } else if (listModelBaseChart.length === TotalListGraphLux.LIST5) {
      listColor = this.colors5;
    } else if (listModelBaseChart.length === TotalListGraphLux.LIST4) {
      listColor = this.colors4;
    } else if (listModelBaseChart.length === TotalListGraphLux.LIST3) {
      listColor = this.colors3;
    } else if (listModelBaseChart.length === TotalListGraphLux.LIST2) {
      listColor = this.colors2;
    } else {
      listColor = this.colors1;
    }

    const lbs: Label[]  = this.getLabels();
    const rtn: ModelBaseLegend[] = [];
    let index = 0;
    listModelBaseChart.forEach(data => {
      const modelBaseLegend = new ModelBaseLegend(lbs[index], data.color);
      rtn.push(modelBaseLegend);
      index += 1;
    });

    return rtn;
  }

  getLabels(): Label[]  {
    const listModelBaseChart = this.getListWithValid();
    if (listModelBaseChart.length === TotalListGraphLux.LIST8) {
      this.labels = this.labels8;
    } else if (listModelBaseChart.length === TotalListGraphLux.LIST7) {
      this.labels = this.labels7;
    } else if (listModelBaseChart.length === TotalListGraphLux.LIST6) {
      this.labels = this.labels6;
    } else if (listModelBaseChart.length === TotalListGraphLux.LIST5) {
      this.labels = this.labels5;
    } else if (listModelBaseChart.length === TotalListGraphLux.LIST4) {
      this.labels = this.labels4;
    } else if (listModelBaseChart.length === TotalListGraphLux.LIST3) {
      this.labels = this.labels3;
    } else if (listModelBaseChart.length === TotalListGraphLux.LIST2) {
      this.labels = this.labels2;
    } else {
      this.labels = this.labels1;
    }
    let index = 0;
    listModelBaseChart.forEach(data => {
      this.labels[index] = data.label;
      index += 1;
    });
    return this.labels;
  }
}
