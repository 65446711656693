import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';
import {SPINNER_NAME} from '../../../../../../../../../core/utils/constants';
import {DocumentsService} from '../../../../../../../../services/common/documents.service';
import {take, takeUntil, tap} from 'rxjs/operators';
import {Observable, ReplaySubject} from 'rxjs';
import {DocumentTypesService} from '../../../../../../../../services/consultant/document-types.service';
import {IDocumentType} from '../../../../../../../../models/admin/document-type';
import {DatePipe} from '@angular/common';
import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import {
  APP_DATE_FORMATS,
  AppDateAdapter,
  setDateAdapterLanguage
} from '../../../../../../../../../core/formatters/format-datepicker';
import {RoleToRouteEnum} from '../../../../../../../../models/admin/auth0';
import {ConsultantAccountService} from '../../../../../../../../services/admin/consultant-account-service';
import {LocalStorageService} from '../../../../../../../../services/local-storage-service';

@Component({
  selector: 'app-add-document',
  templateUrl: './add-document.component.html',
  styleUrls: ['./add-document.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: AppDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}
  ]
})
export class AddDocumentComponent implements OnInit, OnDestroy {
  private readonly destroy$: ReplaySubject<void> = new ReplaySubject<void>(1);
  @ViewChild('fileInput', {static: false}) fileInput: ElementRef<HTMLInputElement>;
  uploadDocumentForm: FormGroup;

  $documentTypes: Observable<IDocumentType[]>;
  invalidSizeError = false;
  emptyDocumentError = false;
  dataDialog;
  minDate = new Date();
  payslipExists = false;
  payslipVarName = 'Payslip';

  constructor(public dialogRef: MatDialogRef<AddDocumentComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { accountId: number, consultantName: string, roleToRouteEnum: RoleToRouteEnum },
              private readonly fb: FormBuilder,
              private readonly dateFormBuilder: FormBuilder,
              private readonly spinner: NgxSpinnerService,
              private documentService: DocumentsService,
              private documentTypesService: DocumentTypesService,
              private consultantAccountService: ConsultantAccountService,
              private readonly localStorageService: LocalStorageService,
              private readonly dateAdapter: DateAdapter<any>,
              private readonly datePipe: DatePipe) {

    this.dataDialog = this.data;

    this.$documentTypes = this.data.roleToRouteEnum === RoleToRouteEnum.ADMIN ?
      documentTypesService.getAllDocumentTypes() :
      documentTypesService.getAllDocumentTypesNoPayslip();

    this.$documentTypes.pipe(tap(documentTypes => {
      if (documentTypes.length === 0) this.dialogRef.close();
    }));
    this.uploadDocumentForm = fb.group({
      filename: ['', Validators.required],
      document: [null, Validators.required],
      documentType: ['', Validators.required],
      expirationDate: [null]
    });
  }

  ngOnInit() {
    this.uploadDocumentForm.controls.documentType.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(() => this.setFilename());
    this.checkIfPayslipIdExists();
    setDateAdapterLanguage(this.dateAdapter, this.localStorageService.getLanguage());
  }

  private checkIfPayslipIdExists() {
    this.consultantAccountService.getAccountData(this.data.accountId.toString(10))
      .pipe(take(1)).subscribe((accountData) => {
      accountData.paySlipId ? this.payslipExists = true : this.payslipExists = false;
    }, () => this.payslipExists = false);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  save() {
    const expirationDate = this.uploadDocumentForm.controls.expirationDate.value;
    if (!expirationDate) {
      this.uploadDocumentForm.controls.expirationDate.setValue(new Date());
    }
    if (this.uploadDocumentForm.controls.document.invalid) this.emptyDocumentError = true;
    if (this.uploadDocumentForm.invalid) return;
    if (!expirationDate) {
      this.uploadDocumentForm.controls.expirationDate.setValue('');
    }
    const documentCreationRequest = Object.assign({accountId: this.data.accountId}, this.uploadDocumentForm.getRawValue());
    documentCreationRequest.documentTypeId = this.documentTypeFC.value.id;
    if (expirationDate) {
      documentCreationRequest.expirationDate = this.datePipe.transform(documentCreationRequest.expirationDate, 'yyyy-MM-dd');
    }
    this.spinner.show(SPINNER_NAME);
    this.documentService.postDocument(documentCreationRequest, this.data.roleToRouteEnum).pipe(take(1))
      .subscribe(() => {
        this.spinner.hide(SPINNER_NAME);
        this.dialogRef.close();
      }, () => {
        this.spinner.hide(SPINNER_NAME);
      });
  }

  handleFileInput(event) {
    this.spinner.show(SPINNER_NAME);
    const files = event.target.files;
    if (!files || (files && files.length === 0)) {
      this.spinner.hide(SPINNER_NAME);
      return;
    }
    const file: File = files.item(0);
    // check file size is lower than 10MB
    const sizeInMB = Number((file.size / (1024 * 1024)).toFixed(2));
    if (sizeInMB > 10) {
      this.invalidSizeError = true;
      this.spinner.hide(SPINNER_NAME);
      return;
    }
    this.invalidSizeError = false;
    this.documentFC.setValue(files.item(0));
    this.setFilename();
    this.fileInput.nativeElement.value = null;
    this.spinner.hide(SPINNER_NAME);
  }

  setFilename(): void {
    const filename = `${this.data.consultantName ? this.data.consultantName : this.data.accountId} - ${this.documentTypeFC.value.name}`;
    this.filenameFC.setValue(filename);
  }

  get documentFC(): FormControl {
    return this.uploadDocumentForm ? this.uploadDocumentForm.controls.document as FormControl : null;
  }

  get filenameFC(): FormControl {
    return this.uploadDocumentForm ? this.uploadDocumentForm.controls.filename as FormControl : null;
  }

  get documentTypeFC(): FormControl {
    return this.uploadDocumentForm ? this.uploadDocumentForm.controls.documentType as FormControl : null;
  }

  clearDocumentFC(): void {
    this.documentFC.setValue(null);
  }
}
