import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDays'
})
export class FormatDaysPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if(Number.isInteger(value)) {
      if(value < 0) {
        value = 0;
      }
      if(value <= 9) {
        return `0${value}`;
      }
      return value;
    }
    return new Intl.NumberFormat('pt-PT').format(value);
  }

}
