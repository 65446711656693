import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {AddDocumentComponent} from './add-document.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {MatButtonModule} from '@angular/material/button';
import {TranslateModule} from '@ngx-translate/core';
import {MatDialogModule} from '@angular/material/dialog';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';


@NgModule({
  declarations: [AddDocumentComponent],
  imports: [
    CommonModule,
    NgxSpinnerModule,
    MatButtonModule,
    TranslateModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    FlexLayoutModule,
    MatSelectModule,
    MatTooltipModule,
    MatInputModule,
    MatDatepickerModule,
  ],
  providers: [DatePipe],
  exports: [AddDocumentComponent]
})
export class AddDocumentModule {
}
