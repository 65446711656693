export interface ITrx {
  id: number;
  transactionType: ITrxType;
  accountId: number;
  creationDate: Date;
  amount: number;
  creditOrDebit: TrxTypeEnum;
  valueDate?: Date;
  descComments?: string;
  businessInfo?: string;
}

export enum DialogRespEnum {
  SAVE = 'SaveAction',
  CANCEL = 'CancelAction'
}

export class TrxCreationRequest {
  trxType: ITrxType;
  amount: number;
  accountId: number;
  description: string;
  valueDate: Date;
  creationDate: Date;
  businessInfo: string;

  constructor() {
    this.description = '';
  }

}

export interface ITrxType {
  id?: string;
  typeName: string;
  creditDebit: string;
  active?: boolean;
}

export enum TrxTypeEnum {
  DEBIT = 'DEBIT',
  CREDIT = 'CREDIT'
}
