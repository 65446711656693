import {TypeInstance} from './instance-enum';
import {DataCompanyInstance} from './environment';
import {Languages} from '../app/core/utils/languages';
import {environment} from './environment';

export default class MultiPlatform {

  static get(): any {
    return DataCompanyInstance;
  }

  static list(): any {
    return [{
      id: TypeInstance.OYESK_LUX,
      i18n: Languages.ENGLISH,
    }, {
      id: TypeInstance.OYESK_FRANCE,
      i18n: Languages.FRENCH,
    }];
  }

  static getDataEnvironmentAuth0(): any {
    const dataAuth0: any = MultiPlatform.get().auth0;
      return dataAuth0.application
  }

  static getDataEnvironmentHost(): any {
    return environment.host;
  }

  static getDataEnvironmentApi(): any {
    return environment.api;
  }

  static getDataEnvironmentRedirectUrl(): any {
    return environment.auth.redirectUrl;
  }

  static getDataEnvironmentScreebWebsiteID(): string {
    return environment.screebSurvey.websiteID;
  }

  static getMarketplaceUrl(): string {
    return environment.marketplace.url;
  }

  static getMarketplaceLaunchInfo(): { day: number, month: number, year: number } {
    return {
      day: Number(environment.marketplace.launch.day),
      month: Number(environment.marketplace.launch.month),
      year: Number(environment.marketplace.launch.year),
    };
  }

  static getMarketplaceAuth0AppMetadataProp() {
    return environment.marketplace.auth0_app_metadata_property;
  }

  static getGuichetUrl() {
    return environment.externalWebsites.guichet;
  }

  static getFaqUrl() {
    return environment.externalWebsites.faq;
  }

  static getPickupUrl() {
    return environment.externalWebsites.pickup;
  }
}
