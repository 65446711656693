import { Pipe, PipeTransform } from '@angular/core';
import {PERT_DIGITS_INFO, PERT_LOCALE} from '../format-percentagem';
import {formatCurrency, formatPercent, getCurrencySymbol} from '@angular/common';


@Pipe({
  name: 'percentageOrCurrencyPipe'
})
export class CustomPercentageOrCurrencyPipe implements PipeTransform {

  transform(
    value: number,
    type?: any,
    digitsInfo = PERT_DIGITS_INFO,
    locale = PERT_LOCALE,
    currencyCode = 'EUR',
    display = 'symbol',
  ): string | null {
    if (value === null) {
      return null;
    }
    if (type === 'NUMBER') {
      return value.toString();
    } else if (type === 'FLOAT') {
      return value.toString();
    } else if (type === 'EURO_PER_HOUR') {
      let amount = value;
      if (isNaN(value)) {
        amount = 0;
      } else if (value === undefined) {
        amount = 0;
      } else if (value === Infinity) {
        amount = 0;
      }
      return formatCurrency(
        amount,
        locale,
        getCurrencySymbol(currencyCode, 'wide'),
        currencyCode,
        digitsInfo,
      ) + '/h';
    }
    if (type === 'PER') {
      let pert = 0;
      if (isNaN(value)) {
        pert = 0;
      } else if (value === undefined) {
        pert = 0;
      } else if (value === Infinity) {
        pert = 0;
      } else {
        pert = value / 100;
      }
      return formatPercent(
        pert,
        locale,
        digitsInfo
      );
    } else if (type === 'EUR') {
      let amount = value;
      if (isNaN(value)) {
        amount = 0;
      } else if (value === undefined) {
        amount = 0;
      } else if (value === Infinity) {
        amount = 0;
      }
      return formatCurrency(
        amount,
        locale,
        getCurrencySymbol(currencyCode, 'wide'),
        currencyCode,
        digitsInfo,
      );
    }
    return null;
  }

}

