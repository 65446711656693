import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import MultiPlatform from '../../../../environments/multi-platform';
import {IImportResult, ITemplate} from '../../models/admin/csv-upload';
import {HatchrAuthService} from '../hatchr-auth.service';

@Injectable()
export class ConsultantTemplateImportService {
  protected readonly http: HttpClient;

  private readonly baseUrl = `${MultiPlatform.getDataEnvironmentHost()}/v1/payroll/bulk-import/templates`;
  private readonly bulkImportBaseUrl = `${MultiPlatform.getDataEnvironmentHost()}/v1/payroll/bulk-import`;
  private readonly importsUrl = `${MultiPlatform.getDataEnvironmentHost()}/v1/payroll/bulk-import/imports`;

  constructor(private readonly hatchrAuthService: HatchrAuthService) {
    this.http = new HttpClient(hatchrAuthService);
  }

  createTemplate(template: ITemplate): Observable<ITemplate> {
    return this.http.post(this.baseUrl, template) as Observable<ITemplate>;
  }

  updateTemplate(template: ITemplate): Observable<ITemplate> {
    return this.http.put(this.baseUrl, template) as Observable<ITemplate>;
  }

  bulkTemplates(): Observable<ITemplate[]> {
    return this.http.get(`${this.baseUrl}/bulk`) as Observable<ITemplate[]>;
  }

  list(): Observable<ITemplate[]> {
    return this.http.get(this.baseUrl) as Observable<ITemplate[]>;
  }

  listImportTypes(): Observable<any[]> {
    return this.http.get(`${this.baseUrl}/types`) as Observable<any[]>;
  }

  listPropertiesByTypes(type: number): Observable<any[]> {
    return this.http.get(`${this.baseUrl}/types/${type}/properties`) as Observable<any[]>;
  }

  importConsultants(body): Observable<any> {
    const formData = new FormData();
    Object.keys(body).forEach(key => formData.append(key, body[key]));

    return this.http.post(`${this.bulkImportBaseUrl}`, formData) as Observable<any>;
  }

  listImportRecords(): Observable<any> {
    return this.http.get(this.importsUrl);
  }

  getImportRecord(id: number): Observable<IImportResult> {
    return this.http.get(`${this.importsUrl}/${id}`) as Observable<IImportResult>;
  }

}
