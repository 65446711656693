import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {InstanceService} from './instance.service';
import {DataInstance} from '../../../environments/instance-enum';
import MultiPlatform from '../../../environments/multi-platform';
import {AdminRoute, ConsultantRoute, LangRoute, PublicRoute, RoutesAdminConsultants} from '../../core/utils/routes';

export enum SessionLocalStorageEnum {
  DATA_LOCAL_STORAGE = 'dataLocalLux',
  DEFAULT_AGENCY_ID = 1,
  INVITATION_LOCAL_STORAGE = 'invitationLocalLux',
  LANGUAGE_LOCAL_STORAGE = 'currentLanguage',
  NAME = 'name',
  PHONE_NUMBER = 'phoneNumber',
  PHONE_CODE_COUNTRY_IDENTIFIER = 'phoneCodeCountryIdentifier',
  AUTO_LANGUAGE_LOCAL_STORAGE = 'autoLang'
}

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor(public readonly instance: InstanceService) {

  }

  getNameCompany(): any {
    return MultiPlatform.get().nameCompany;
  }

  getDataCompanyByI18n(): any {
    return MultiPlatform.get().localeLangI18n;
  }

  getLogoCompany(): any {
    return MultiPlatform.get().logo;
  }

  getMinLogoCompany(): any {
    return MultiPlatform.get().minLogo;
  }

  getLanguageCompany(): any {
    return MultiPlatform.get().localeLangI18n;
  }

  getSimulationPdf(): any {
    return MultiPlatform.get().simulationPdf;
  }

  isPopupHbspt() {
    return MultiPlatform.get().popupHbspt;
  }

  update(expiresAt, accessToken, idToken) {
    const userProfile = this.getUserProfile();
    const dataLocalStorage = {
      agencyId: SessionLocalStorageEnum.DEFAULT_AGENCY_ID,
      isLoggedIn: true.toString(),
      expiresAt: expiresAt.toString(),
      idToken: idToken.toString(),
      accessToken: accessToken.toString()
    };
    this.setDataLocalStorage(dataLocalStorage);
    this.setUserProfile(userProfile);
  }

  remove() {
    localStorage.removeItem(SessionLocalStorageEnum.DATA_LOCAL_STORAGE);
    localStorage.removeItem(SessionLocalStorageEnum.AUTO_LANGUAGE_LOCAL_STORAGE);
  }

  getData() {
    return this.isLocalStorageSupported() ?
      JSON.parse(localStorage.getItem(SessionLocalStorageEnum.DATA_LOCAL_STORAGE)) : null;
  }

  isLocalStorageSupported(): boolean {
    return !!localStorage;
  }

  getExpiresAt(): any {
    return this.getData() ? this.getData().expiresAt : '';
  }

  getIdToken(): any {
    return this.getData() ? this.getData().idToken : '';
  }

  getAccessToken(): any {
    return this.getData() ? this.getData().accessToken : '';
  }

  isLoggedIn() {
    return !!(this.getData() && this.getData().isLoggedIn);
  }

  isUserProfile() {
    return !!(this.getData() && this.getData().userProfile);
  }

  getUserProfile() {
    return this.isUserProfile() ? this.getData().userProfile : null;
  }

  setRoleUserProfile() {
    const userProfile = JSON.parse(this.getData().userProfile);
    userProfile.roles.push(this.instance.instanceConsultantRole);
    this.setUserProfile(userProfile);
  }

  setUserProfile(userProfile: any) {
    const dataLocalStorage = this.getData();
    dataLocalStorage.userProfile = userProfile;
    this.setDataLocalStorage(dataLocalStorage);
  }

  setDataLocalStorage(dataLocalStorage: any) {
    localStorage.setItem(SessionLocalStorageEnum.DATA_LOCAL_STORAGE, JSON.stringify(dataLocalStorage));
  }

  logout() {
    this.remove();
  }

  setLanguage(lang: string): void {
    localStorage.setItem(SessionLocalStorageEnum.LANGUAGE_LOCAL_STORAGE, lang);
  }

  setAutoLang(autoLanguage: boolean): void {
    const op = autoLanguage ? 1 : 0;
    localStorage.setItem(SessionLocalStorageEnum.AUTO_LANGUAGE_LOCAL_STORAGE, op.toString());
  }

  isAutoLang(): boolean {
    const isAutoLanguage = localStorage.getItem(SessionLocalStorageEnum.AUTO_LANGUAGE_LOCAL_STORAGE);
    return isAutoLanguage ? Boolean(isAutoLanguage).valueOf() : false;
  }

  getLanguage(): string {
    const lang = localStorage.getItem(SessionLocalStorageEnum.LANGUAGE_LOCAL_STORAGE);
    return lang ? lang : this.getDataCompanyByI18n();
  }

  setNames(firstName: string, lastName: string): void {
    localStorage.setItem(SessionLocalStorageEnum.NAME, JSON.stringify({firstName, lastName}));
  }

  getNames() {
    return JSON.parse(localStorage.getItem(SessionLocalStorageEnum.NAME));
  }

  clearNames(): void {
    localStorage.removeItem(SessionLocalStorageEnum.NAME);
  }

  setPhoneCodeCountryIdentifier(phoneCodeCountryIdentifier: string): void {
    localStorage.setItem(SessionLocalStorageEnum.PHONE_CODE_COUNTRY_IDENTIFIER, JSON.stringify(phoneCodeCountryIdentifier));
  }

  getPhoneCodeCountryIdentifier() {
    return JSON.parse(localStorage.getItem(SessionLocalStorageEnum.PHONE_CODE_COUNTRY_IDENTIFIER));
  }

  setPhoneNumber(phoneNumber: string): void {
    localStorage.setItem(SessionLocalStorageEnum.PHONE_NUMBER, JSON.stringify(phoneNumber));
  }

  getPhoneNumber() {
    return JSON.parse(localStorage.getItem(SessionLocalStorageEnum.PHONE_NUMBER));
  }

  clearPhoneNumberAndCountryId(): void {
    localStorage.removeItem(SessionLocalStorageEnum.PHONE_NUMBER);
    localStorage.removeItem(SessionLocalStorageEnum.PHONE_CODE_COUNTRY_IDENTIFIER);
  }

  getCurrentCountry(): any {
    return MultiPlatform.get().abrvCompany;
  }

  getGps() {
    if (sessionStorage.getItem(DataInstance.GPS)) {
      return sessionStorage.getItem(DataInstance.GPS);
    }
    return '';
  }

  toLanguageRoute(path: any): any {
    return `${this.getLanguage()}/${path}`;
  }

  getPathAdminConsultants(name: any = null): any {
    const path = LangRoute.getLangAdmin();
    if (name) {
      return `${path}/${name}`;
    }
    return path;
  }

  getUrlHomeAdmin() {
    return this.getUrlAdmin(null);
  }

  getUrlHomeConsultant() {
    return this.getUrlConsultant(null);
  }

  getUrlAdmin(name: any = null): any {
    const path = this.toLanguageRoute(AdminRoute.ADMIN);
    if (name) {
      return `${path}/${name}`;
    }
    return path;
  }

  getUrlConsultant(name: any = null): any {
    const path = this.toLanguageRoute(ConsultantRoute.CONSULTANT);
    if (name) {
      return `${path}/${name}`;
    }
    return path;
  }

  getUrlPrefixNotification(name: any = null): any {
    const lang = this.getLanguage();
    if (name) {
      return `${lang}${name}`;
    }
    return lang;
  }

  getPathConsultant(name: any = null): any {
    const path = LangRoute.getLangConsultant();
    if (name) {
      return `${path}/${name}`;
    }
    return path;
  }

  getPathSupportedBrowsers(): any {
    return PublicRoute.SUPPORTED_BROWSERS;
  }

  getPathForgotPassword(): any {
    return PublicRoute.FORGOT_PASSWORD;
  }

  getPathForgotPasswordRecovery(): any {
    return PublicRoute.FORGOT_PASSWORD_RECOVERY;
  }

  changeUrlLanguageRoute(path: any, subPath: any, lang: any) {
    return `${lang}/${path}/${subPath}`;
  }

  getUrlAdminConsultantsView() {
    return `${this.getUrlAdmin()}/${RoutesAdminConsultants.CONSULTANTS}/${RoutesAdminConsultants.VIEW}`;
  }

  getNameInstance() {
    return environment.company.instance;
  }
}
