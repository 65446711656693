import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {interval, Observable} from 'rxjs';
import MultiPlatform from '../../../../environments/multi-platform';
import {INews} from '../../models/consultant/news';
import {map, startWith, switchMap} from 'rxjs/operators';

export const NEWS_URL = `${MultiPlatform.getDataEnvironmentHost()}api/consultants/news`;
export const NEWS_INTERVAL = 300000;

@Injectable({
  providedIn: 'root'
})
export class NewsService {
  constructor(private readonly http: HttpClient) {
  }

  public getNews(id) {
    return interval(NEWS_INTERVAL)
      .pipe(
        startWith(0),
        switchMap(() => this.http.get(`${NEWS_URL}/${id}`)
          .pipe(
            map((news: INews[]) => this.reverseNews(news))
          ) as Observable<INews[]>)
      );
  }

  reverseNews(news) {
    return news.reverse();
  }

  setRead(newsId, consultantId): Observable<INews> {
    return this.http.put(NEWS_URL, {newsId, consultantId}) as Observable<INews>;
  }
}
