import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {IAuth0Profile, RoleToRouteEnum} from '../../../models/admin/auth0';
import {Router} from '@angular/router';
import {LocalStorageService} from '../../../services/local-storage-service';
import {ConsultantRoute} from '../../../../core/utils/routes';

@Component({
  selector: 'app-profile-link',
  templateUrl: './profile-link.component.html',
  styleUrls: ['./profile-link.component.scss']
})
export class ProfileLinkComponent implements OnInit {
  @Input() userProfile$: Observable<IAuth0Profile>;
  @Input() mobile: boolean;
  failed = false;

  constructor(private readonly router: Router,
              private readonly localStorageService: LocalStorageService) {
  }

  ngOnInit() {
  }

  capitalizeRole(roles: string[]): string {
    let rolesLabel = '';
    const isFinal = (index) => index === roles.length - 1 ? '' : ', ';
    roles.forEach((role, index) => {
      rolesLabel += role.slice(0, 1).toUpperCase() + role.slice(1).toLowerCase() + isFinal(index);
    });
    return rolesLabel;
  }

  isRouteAdmin(): boolean {
    return this.router.url.includes(RoleToRouteEnum.ADMIN);
  }

  goToProfile() {
    if (!this.isRouteAdmin()) {
      this.router.navigate([this.localStorageService.getPathConsultant(ConsultantRoute.PROFILE)]);
      return false;
    }
    return true;
  }
}
