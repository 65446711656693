import {Component, OnInit} from '@angular/core';
import {EmbeddedAuthService} from '../main/services/embedded-auth.service';

@Component({
  selector: 'app-auth-fallback',
  templateUrl: './auth-fallback.component.html',
  styleUrls: ['./auth-fallback.component.scss']
})
export class AuthFallbackComponent implements OnInit {

  constructor(private readonly embeddedAuth: EmbeddedAuthService) {
  }

  ngOnInit() {
    this.embeddedAuth.crossOriginVerification();
  }

}
