import {Component, Inject} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {NotifierWithTemplateService} from '../../../../services/notifier-with-template.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {DocumentsService} from '../../../../services/common/documents.service';
import {IDocument} from '../../../../models/common/document';
import {APPLICATION_PDF} from '../../../../../core/utils/constants';

@Component({
  selector: 'app-view-document-modal',
  templateUrl: './view-document-modal.component.html',
  styleUrls: ['./view-document-modal.component.scss']
})
export class ViewDocumentModalComponent {
  documentFile;
  documentURL;
  totalPages = 1;
  page = 1;

  constructor(private readonly translate: TranslateService,
              private readonly notifier: NotifierWithTemplateService,
              private dialogRef: MatDialogRef<ViewDocumentModalComponent>,
              private readonly documentService: DocumentsService,
              @Inject(MAT_DIALOG_DATA) public data: {blobDoc: Blob, documentFile: IDocument}) {
    this.documentFile = this.data.documentFile;
    if (this.documentFile.fileFormat === 'pdf') {
      const pdfBlob = new Blob([this.data.blobDoc], {type: APPLICATION_PDF});
      this.documentURL = window.URL.createObjectURL(pdfBlob);
    } else {
      this.notifier.error(this.translate.instant('UNSUPPORTED_FORMAT'));
      this.dialogRef.close();
    }
  }

  nextPage() {
    this.page += 1;
    this.scrollTop();
  }

  previousPage() {
    this.page -= 1;
    this.scrollTop();
  }

  afterLoadComplete(pdfData: any) {
    this.totalPages = pdfData.numPages;
  }

  printDocument() {
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = this.documentURL;
    document.body.appendChild(iframe);
    setTimeout(() => {
      iframe.contentWindow.print();
    }, 100);
  }

  downloadDocument() {
    this.documentService.downloadDocument(this.documentFile);
  }

  scrollTop() {
    document.getElementById('top').scrollIntoView();
  }
}



