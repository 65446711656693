import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {ConsultantTemplateImportService} from '../../../../../services/admin/consultant-template-import.service';
import {IImportResult} from '../../../../../models/admin/csv-upload';
import {Observable, of} from 'rxjs';
import {catchError, filter, map, tap} from 'rxjs/operators';
import {NgxSpinnerService} from 'ngx-spinner';
import {SPINNER_NAME} from '../../../../../../core/utils/constants';
import {logger} from 'codelyzer/util/logger';

@Component({
  selector: 'app-import-results-table',
  templateUrl: './import-results-table.component.html'
})
export class ImportResultsTableComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  displayedColumns: string[] = ['id', 'template', 'totalRowsInserted', 'totalRowsFailed', 'dateInserted', 'filename', 'moreInfo'];
  importRecordsList$: Observable<MatTableDataSource<IImportResult>>;
  noResults = false;

  constructor(private readonly consultantTemplateImportService: ConsultantTemplateImportService,
              private readonly spinner: NgxSpinnerService) {
  }

  ngOnInit() {
    this.spinner.show(SPINNER_NAME);
    this.importRecordsList$ = this.consultantTemplateImportService.listImportRecords()
      .pipe(
        tap(() => this.spinner.hide(SPINNER_NAME)),
        map(importRecords => new MatTableDataSource(importRecords)),
        tap((dataSource: MatTableDataSource<IImportResult>) => {
          this.sort.direction = 'desc';
          this.sort.active = 'id';
          dataSource.paginator = this.paginator;
          dataSource.sort = this.sort;
          this.noResults = dataSource.data.length === 0;
        }),
        catchError((err) => {
          console.error(err);
          this.spinner.hide(SPINNER_NAME);
          return of(new MatTableDataSource([]));
        })
      );
  }

  canBeReverted(importRecord: IImportResult): boolean {
    return !importRecord.deleted && importRecord.successes.length > 0;
  }

}
