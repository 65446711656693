import {Component, OnInit} from '@angular/core';
import {NewsService} from '../../../services/consultant/news.service';
import {INews} from '../../../models/consultant/news';
import {ConsultantIdentityService} from '../../../services/consultant/consultant-identity.service';
import {NewsModalService} from '../../../services/consultant/news-modal.service';
import {filter, take} from 'rxjs/operators';

@Component({
  selector: 'app-news-link',
  templateUrl: './news-link.component.html',
  styleUrls: ['./news-link.component.scss']
})
export class NewsLinkComponent implements OnInit {
  newsStories: INews[];
  allNewsStories: INews[];
  NEWS_DEBOUNCE_VALUE = 5;

  consultantId;

  constructor(private readonly newsService: NewsService,
              private readonly consultantIdService: ConsultantIdentityService,
              private readonly newsModalService: NewsModalService) {
  }

  ngOnInit() {
    this.consultantId = this.consultantIdService.getConsultantId();
    this.newsService.getNews(this.consultantId)
      .pipe(take(1), filter(news => news.length > 0))
      .subscribe((news: INews[]) => {
        this.allNewsStories = news;
        this.newsStories = news.slice(0, this.NEWS_DEBOUNCE_VALUE);
      });
  }

  openNewsDialog(index) {
    this.newsModalService.openNewsDialog(this.allNewsStories, index, this.consultantId);
  }

  get unreadCount() {
    return this.allNewsStories ? this.allNewsStories.filter(x => !x.read).length : 0;
  }

  showMoreNews(e: Event) {
    e.stopPropagation();
    const l = this.newsStories.length;
    const moreNews = this.allNewsStories.slice(l, l + this.NEWS_DEBOUNCE_VALUE);
    this.newsStories = [...this.newsStories, ...moreNews];
  }
}
