export enum SCREEN_SIZE {
  XS,
  SM,
  MD,
  LG,
  XL
}

export const SCREEN_SIZES: any[] = [
  {
    id: SCREEN_SIZE.XS,
    name: 'xs',
    css: `d-block d-sm-none`,
    begin: 0,
    end: 575
  },
  {
    id: SCREEN_SIZE.SM,
    name: 'sm',
    css: `d-none d-sm-block d-md-none`,
    begin: 576,
    end: 767
  },
  {
    id: SCREEN_SIZE.MD,
    name: 'md',
    css: `d-none d-md-block d-lg-none`,
    begin: 768,
    end: 991
  },
  {
    id: SCREEN_SIZE.LG,
    name: 'lg',
    css: `d-none d-lg-block d-xl-none`,
    begin: 992,
    end: 1199
  },
  {
    id: SCREEN_SIZE.XL,
    name: 'xl',
    css: `d-none d-xl-block`,
    begin: 1200,
    end: 99999
  },
];
