import {Injectable} from '@angular/core';
import {NotifierTemplateService} from './notifier-template.service';
import {NotifierService} from 'angular-notifier';
import {NotifierType} from '../models/admin/custom-message';

@Injectable({
  providedIn: 'root'
})
export class NotifierWithTemplateService {

  constructor(private readonly notifier: NotifierService,
              private readonly notifierTemplate: NotifierTemplateService) {
  }

  notify(type, message) {
    this.notifier.show({
      type, message, template: this.notifierTemplate.getTemplate()
    });
  }

  error(message) {
    this.notify(NotifierType.ERROR, message);
  }

  success(message) {
    this.notify(NotifierType.SUCCESS, message);
  }

  warning(message) {
    this.notify(NotifierType.WARNING, message);
  }

}
