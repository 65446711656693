import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import {environment} from './environments/environment';
import MultiPlatform from './environments/multi-platform';

document.title = MultiPlatform.get().nameCompany;
const linkIcon = document.createElement('link');
linkIcon.type = 'image/x-icon';
linkIcon.rel = 'icon';
linkIcon.href = '.' + MultiPlatform.get().icon;
document.head.appendChild(linkIcon);

function changeFavicon(src) {
  const link = document.createElement('link');
  const oldLink = document.getElementById('dynamic-favicon');
  link.id = 'dynamic-favicon';
  link.rel = 'shortcut icon';
  link.href = src;
  if (oldLink) {
    document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
}

changeFavicon(linkIcon.href);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
