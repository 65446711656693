import {Component, OnInit} from '@angular/core';
@Component({
  selector: 'app-private-salary-simulation-country',
  templateUrl: './private-salary-simulation-country.component.html',
  styleUrls: ['./private-salary-simulation-country.component.scss']
})
export class PrivateSalarySimulationCountryComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }
}
