import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-no-results',
  templateUrl: './no-results.component.html',
  styleUrls: ['./no-results.component.scss']
})
export class NoResultsComponent implements OnInit {
  @Output() action: EventEmitter<any> = new EventEmitter<any>();
  @Input() objectLabel = '';
  @Input() btnLabel = '';
  @Input() secondaryImage?: boolean;
  @Input() secondaryBackground?: boolean;
  @Input() isSwitch?: boolean = true;

  constructor() {
  }

  ngOnInit() {
  }

  emitAction(): void {
    this.action.emit();
  }

}
