import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'formatContractNo'
})
export class FormatContractNoPipe implements PipeTransform {

  transform(value: any, _numberOfDigits = 5): any {
    if (!value) return '';
    let numberOfZeros = _numberOfDigits - value.toString().length;
    numberOfZeros = numberOfZeros > 0 ? numberOfZeros : 0;
    while (numberOfZeros !== 0) {
      value = '0' + value;
      numberOfZeros--;
    }
    return value;
  }

}
