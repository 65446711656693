import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-default-picture',
  templateUrl: './default-picture.component.html',
  styleUrls: ['../profile-link.component.scss']
})
export class DefaultPictureComponent implements OnInit {
  @Input() name: string;
  @Input() smaller = false;
  randomColor;

  constructor() {
  }

  ngOnInit() {
    this.randomColor = '#' + Math.floor(Math.random() * 16777215).toString(16);
  }

}
