import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {EmbeddedAuthService} from '../../services/embedded-auth.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MyErrorStateMatcher} from '../../../core/utils/validators';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {LocalStorageService} from '../../services/local-storage-service';
import MultiPlatform from '../../../../environments/multi-platform';
import {Platform} from '../../../core/utils/global';
import {PublicRoute} from '../../../core/utils/routes';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  dataCompany: any = MultiPlatform.get();

  errorStateMatcher = new MyErrorStateMatcher();
  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required])
  });
  hide = true;

  signUpForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email])
  });

  constructor(private readonly auth: AuthService,
              private readonly embeddedAuth: EmbeddedAuthService,
              private readonly translate: TranslateService,
              private readonly router: Router,
              private localStorageService: LocalStorageService,
              private platform: Platform) {
  }

  ngOnInit() {
    this.localStorageService.clearNames();
    this.localStorageService.clearPhoneNumberAndCountryId();
    this.platform.changeLanguage(this.localStorageService.getLanguageCompany());
  }

  login(): void {
    if (this.loginForm.invalid) {
      return;
    }
    this.embeddedAuth.login(this.loginForm.controls.email.value, this.loginForm.controls.password.value);
  }

  loginWithGoogle(): void {
    this.embeddedAuth.loginWithGoogle();
  }

  loginWithMicrosoft(): void {
    this.embeddedAuth.loginWithMicrosoft();
  }

  loginWithLinkedIn(): void {
    this.embeddedAuth.loginWithLinkedIn();
  }

  getErrorMessage() {
    return this.loginForm.controls.email.hasError('required') ? this.translate.instant('EMPTY_FIELD') :
      this.loginForm.controls.email.hasError('email') ? this.translate.instant('EMAIL_INVALID') : '';
  }

  goToSignUp() {
    this.router.navigate([PublicRoute.SIGNUP]);
  }

  checkActiveColor(input: string) {
    if (this.loginForm.get(input).value !== '') {
      return 'active';
    }
    return '';
  }

  getLinkForgotPassword(): any {
    return PublicRoute.FORGOT_PASSWORD;
  }

  getLinkHome(): any {
    return PublicRoute.HOME;
  }
}
