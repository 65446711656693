import {Component, EventEmitter, forwardRef, HostListener, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ResponsiveService} from '../../../services/responsive.service';
import {SidenavService} from '../../../services/admin/sidenav.service';

@Component({
  selector: 'app-bar-title-search',
  templateUrl: './bar-title-search.component.html',
  styleUrls: ['./bar-title-search.component.scss'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => BarTitleSearchComponent), multi: true}
  ]
})
export class BarTitleSearchComponent implements OnInit, ControlValueAccessor {

  @Input() nameIcon: string;
  @Input() nameTitle: string;
  @Input() nameSearch: any = 'CONSULTANT.SEARCH';
  @Output() clickIcon = new EventEmitter();
  @Output() clickTitle = new EventEmitter();
  @Output() clearSearch = new EventEmitter();
  @Output() keyupSearch = new EventEmitter();
  @Output() keyupEnter = new EventEmitter();
  @Input() formControl: FormControl;
  @Input() hasContent: boolean;
  @Input() hasSearch: boolean;
  @Input() hasDisplayFlex = false;
  @Input() hasTitle = true;
  @Input() hasBarTitleMr = true;
  @Input() prefixSearchClass: string = null;
  private EMPTY_SEARCH = '';

  constructor(private readonly sidenav: SidenavService,
              public readonly responsiveService: ResponsiveService) {
  }

  ngOnInit() {

  }

  clearFilter(): void {
    this.formControl.setValue(this.EMPTY_SEARCH);
  }

  hasFilters() {
    return this.formControl.value !== this.EMPTY_SEARCH;
  }

  clickEmitIcon(): void {
    if (this.clickIcon) {
      this.clickIcon.emit();
    }
  }

  clickEmitTitle(): void {
    if (this.clickTitle) {
      this.clickTitle.emit();
    }
  }

  toggleMenu() {
    this.sidenav.toggle();
  }

  getValue(): string {
    return this.formControl.value;
  }

  enterSearch(): void {
    this.keyupEnter.emit();
  }

  @HostListener('window:keyup', ['$event']) keyUpEvent(event: KeyboardEvent) {
    this.keyupSearch.emit();
  }

  clearValueSearch(): void {
    this.clearFilter();
    if (this.clearSearch) {
      this.clearSearch.emit();
    }
  }

  reload(): void {
    this.keyupSearch.emit();
  }

  getPrefixClass() {
    if (this.prefixSearchClass) {
      return `-${this.prefixSearchClass}`;
    }
    return '';
  }

  hasFilter(): boolean {
    return this.formControl.value || this.formControl.value;
  }

  getBarTitleMr(): any {
    if (this.hasBarTitleMr) {
      return ` bar-title-mr-${this.responsiveService.getSizeResponsive()} `;
    }
    return ``;
  }

  getDisplayFlex() {
    if (this.hasDisplayFlex) {
      return `-flex`;
    }
    return ``;
  }

  registerOnChange(fn: any): void {
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(obj: any): void {
  }
}
