import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'parseUrl'
})
export class ParseUrlPipe implements PipeTransform {

  transform(text: string): string {
    const urls = /(\b(https?|ftp):\/\/[A-Z0-9+&@#\/%?=~_|!:,.;-]*[-A-Z0-9+&@#\/%=~_|])/gim;

    if (text.match(urls)) {
      text = text.replace(urls, '<a href="$1" target="_blank">$1</a>');
    }

    return text;
  }

}
