import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '../main/services/auth.service';
import {EmbeddedAuthService} from '../main/services/embedded-auth.service';
import {LocalStorageService} from '../main/services/local-storage-service';
import {USER_AUTH} from '../core/utils/constants';
import {InstanceService} from '../main/services/instance.service';
import {PublicRoute} from '../core/utils/routes';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  constructor(private readonly auth: AuthService,
              private readonly embeddedAuth: EmbeddedAuthService,
              private readonly router: Router,
              private localStorageService: LocalStorageService,
              public readonly instance: InstanceService) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return false;
    if (this.embeddedAuth.isLoggedIn()) {
      const isAdmin = (roles: string[]) => roles.includes(this.instance.instanceAdminRole);
      if (!this.embeddedAuth.isUserProfile()) {
        this.router.navigate([PublicRoute.LOGIN]);
        return false;
      }
      const userProfile = this.localStorageService.getUserProfile();
      if (userProfile && !isAdmin(userProfile[USER_AUTH].roles)) {
          this.router.navigate([PublicRoute.LOGIN]);
          return false;
      }
      return true;
    }
    this.router.navigate([PublicRoute.LOGIN]);
    return false;
  }
}
