import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {IModalNews, INews} from '../../../../models/consultant/news';
import {NewsService} from '../../../../services/consultant/news.service';
import {take} from 'rxjs/operators';
import {carouselConfig} from '../../../../models/admin/custom-message';

@Component({
  selector: 'app-news-modal',
  templateUrl: './news-modal.component.html',
  styleUrls: ['./news-modal.component.css']
})
export class NewsModalComponent {
  slideConfig = carouselConfig;
  news: INews[];
  index: number;
  userId;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IModalNews,
    private readonly newsService: NewsService) {
    this.news = data.news;
    this.index = data.index;
    this.userId = data.userId;
    this.slideConfig.initialSlide = this.index;
    this.slideConfig.autoplay = false;
  }

  setSeen(event) {
    const index = event.currentSlide;
    const newStory = this.news[index];
    if (!newStory.read) {
      this.newsService.setRead(newStory.newsStory.id, this.userId).pipe(take(1))
        .subscribe(resp => newStory.read = true);
    }
  }
}
