import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {getRouterData, translaterCompany} from './core/utils/util-functions';
import {filter, switchMap, takeUntil} from 'rxjs/operators';
import {
  TITLE
} from './core/utils/constants';
import {ReplaySubject} from 'rxjs';
import {EmbeddedAuthService} from './main/services/embedded-auth.service';
import {NotifierTemplateService} from './main/services/notifier-template.service';
import {NotifierType} from './main/models/admin/custom-message';
import {LocalStorageService} from './main/services/local-storage-service';
import MultiPlatform from '../environments/multi-platform';
import {LangConf} from './core/utils/languages';
import {Platform} from './core/utils/global';
import {PublicRoute} from './core/utils/routes';
import {ScreebSurveyService} from './main/services/common/screeb-survey.service';
import {InstanceService} from './main/services/instance.service';
import {MarketplaceService} from './main/services/common/marketplace.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('customNotification', {static: true}) notificationTemplate;
  private readonly destroy$: ReplaySubject<void> = new ReplaySubject<void>(1);

  notTypes = NotifierType;
  surveyScript = document.createElement('script');

  constructor(private readonly embeddedAuth: EmbeddedAuthService,
              private readonly translate: TranslateService,
              private readonly title: Title,
              private readonly router: Router,
              private readonly activatedRoute: ActivatedRoute,
              private notifierTemplate: NotifierTemplateService,
              private localStorageService: LocalStorageService,
              private readonly screebSurveyService: ScreebSurveyService,
              private readonly instanceService: InstanceService,
              public readonly platform: Platform,
              private readonly marketplaceService: MarketplaceService) {

    if (this.embeddedAuth.isUserProfile()) {
      const lang = localStorageService.getDataCompanyByI18n();
      translate.addLangs(LangConf.getAvailableLanguages());
      this.platform.changeLanguage(lang);
      this.platform.geolocation();
    } else {
      this.platform.changeLanguage(this.localStorageService.getLanguage());
    }
  }

  favIcon: HTMLLinkElement = document.querySelector('#appIcon');
  appTitle: HTMLLinkElement = document.querySelector('#appTitle');
  public dataCompany = MultiPlatform.get();

  ngOnInit(): void {
    this.favIcon.href = MultiPlatform.get().icon;
    this.appTitle.href = MultiPlatform.get().nameCompany;

    if (this.platform.isWindowsSupportedBrowser()) {
      if (this.embeddedAuth.isUserProfile()) {
        if (!this.embeddedAuth.checkAuthenticated()) {
          this.router.navigate([PublicRoute.LOGIN]);
        }
        this.embeddedAuth.renewSession();
        if (this.instanceService.isOyeskLux()) {
          this.screebSurveyService.initSurvey(this.localStorageService.getUserProfile(), this.surveyScript);
        }
        this.marketplaceService.handleInitialMarketplacePopup(this.localStorageService.getUserProfile());
      }

      getRouterData(this.router, this.activatedRoute)
        .pipe(
          takeUntil(this.destroy$),
          filter(event => event[TITLE] !== undefined && event[TITLE] !== null),
          switchMap(event => this.translate.get(event[TITLE]))
        ).subscribe(title => {
        title = translaterCompany(title);
        this.title.setTitle(title);
      });
      this.notifierTemplate.setNotifierTemplate(this.notificationTemplate);
    } else {
      this.router.navigate([this.localStorageService.getPathSupportedBrowsers()]);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
