import {Component, ElementRef, Inject, Input, OnInit, Renderer2, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {SalarySimService} from '../../../services/consultant/salary-sim.service';
import {SidenavService} from '../../../services/admin/sidenav.service';
import {LocalStorageService} from '../../../services/local-storage-service';
import {NgxSpinnerService} from 'ngx-spinner';
import {InstanceService} from '../../../services/instance.service';
import {TranslateService} from '@ngx-translate/core';
import {DOCUMENT} from '@angular/common';
import {MatDialog} from '@angular/material/dialog';
import {
  IInfoSalarySimulatorRequest,
  IResultSalarySimulatorRequest,
  ISalarySimulatorBodyRequest
} from '../../../../core/utils/SimulatorSalary';
import MultiPlatform from '../../../../../environments/multi-platform';
import {
  IAdvantages,
  ITaxClass,
  JobTypeSalaryEnum,
  RateTypeEnum,
  TypeResult
} from '../../../models/consultant/salary-simulation';
import {Observable} from 'rxjs';
import {DataBaseChart, ModelBaseChart} from '../../../../core/utils/data-char';
import {ChartOptions, ChartType} from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import {
  DialogHbsptComponent
} from '../../public-pages/pages/public-salary-simulation-country/dialog-hbspt/dialog-hbspt.component';
import {map, tap} from 'rxjs/operators';
import {
  FULL_TIME,
  IMAGE_TYPE_JPEG,
  MIN_VALUE_SIMULATOR_GRAPH,
  PDF_FORMAT,
  PDF_UNIT,
  REGEX_PERCENTAGE,
  SPINNER_NAME,
  SPINNER_UPDATE_DATE
} from '../../../../core/utils/constants';
import {convertNumberToPercentChartJs} from '../../../../core/formatters/format-percentagem';
import {Keepalive} from '@ng-idle/keepalive';
import {Platform} from '../../../../core/utils/global';
import html2PDF from 'jspdf-html2canvas';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-salary-simulation',
  templateUrl: './salary-simulation.component.html',
  styleUrls: ['./salary-simulation.component.scss']
})
export class SalarySimulationComponent implements OnInit {

  @Input() btnShowDetails = false;
  @Input() btnBtnEmail = false;
  @Input() showPdf = false;

  public isSeeSalaryDetailsWithPotentialNetSalary = false;
  showDetails = false;
  private canDownload = false;
  @ViewChild('screen', {static: false}) screen: ElementRef;

  infoSalarySimulator: IInfoSalarySimulatorRequest;
  dataCompany: any = MultiPlatform.get();
  public salarySimulationForm: FormGroup;
  public salaryTypeOptions = [JobTypeSalaryEnum.FULL_TIME, JobTypeSalaryEnum.PART_TIME];
  private listTaxClass: ITaxClass[];
  public isPopupHbspt = false;
  public isEditSimulation = false;
  rateType = [{code: RateTypeEnum.DAILY_RATE, label: 'DAILY_2'}, {
    code: RateTypeEnum.MONTHLY_GROSS_SALARY,
    label: 'GROSS_SALARY_2'
  }];
  idPdfSalarySimulator: any;
  urlPdfSalarySimulator: any;
  monthlyGrossSalary: any;
  durationOfTheMissionInMonths: any;
  dailyRate: any;

  infoSalarySimulator$: Observable<IInfoSalarySimulatorRequest>;
  resultSalarySimulator$: Observable<IResultSalarySimulatorRequest>;

  iResultSalarySimulatorRequest: IResultSalarySimulatorRequest;

  public typeResult: TypeResult = new TypeResult();
  displaySimulation = false;
  dataBaseChart: DataBaseChart;

  public message: any = '';
  public chartType: ChartType = 'doughnut';
  public showLegend = false;
  public chartPlugins = [pluginDataLabels];

  private isInit = true;

  get advantagesAssignFC(): FormControl {
    return this.salarySimulationForm ? this.salarySimulationForm.controls.advantagesAssignFC as FormControl : null;
  }

  constructor(private readonly formBuilder: FormBuilder,
              public readonly salarySimService: SalarySimService,
              private readonly sidenav: SidenavService,
              public localStorageService: LocalStorageService,
              private readonly spinner: NgxSpinnerService,
              public readonly instance: InstanceService,
              private renderer2: Renderer2,
              private readonly translate: TranslateService,
              @Inject(DOCUMENT) private _document,
              private readonly dialog: MatDialog,
              private keepalive: Keepalive,
              private platform: Platform,
              private readonly route: ActivatedRoute) {
  }

  public chartOptions: ChartOptions = {
    legend: {
      align: 'start',
      position: 'bottom',
      fullWidth: true,
      labels: {
        fontSize: 10,
        usePointStyle: true,
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1,
    cutoutPercentage: 65,
    tooltips: {
      enabled: true,
      displayColors: true,
      xPadding: 15,
      yPadding: 15,
      backgroundColor: '#333333',
      borderWidth: 8,
      callbacks: {
        label: this.convertToToolTipChartJs
      }
    },
    plugins: {
      datalabels: {
        color: '#fff',
        anchor: 'center',
        align: 'center',
        formatter: this.convertToLabelChartJs,
        font: {
          size: 12,
          weight: 'bold'
        }
      }
    }
  };

  moneyPatterns = {
    V: {pattern: new RegExp(MultiPlatform.get().money.patterns.separator)},
    0: {pattern: new RegExp(MultiPlatform.get().money.patterns.numbers)}
  };

  initForm(): void {
    this.dataBaseChart = new DataBaseChart();

    if (this.instance.isOyeskLux()) {
      this.salarySimulationForm = this.formBuilder.group({
        simulationType: [JobTypeSalaryEnum.FULL_TIME, Validators.required],
        hours: ['', [Validators.min(this.infoSalarySimulator.minPertHours),
          Validators.max(this.infoSalarySimulator.maxPertHours),
          Validators.pattern(REGEX_PERCENTAGE)
        ]],
        rateType: ['', Validators.required],
        rate: ['', [Validators.required, Validators.min(1), Validators.max(this.checkMaxRate())]],
        missionDuration: [12, [Validators.min(this.infoSalarySimulator.minMissionDuration)]],
        taxClass: ['', [Validators.required]],
        taxRate: [''],
        costs: [''],
        reserve: [this.getMinReserve()],
        mealVouchers: [true],
        carLeasing: [''],
        healthInsurance: [''],
        mobilePhone: [''],
        fuelCard: [''],
        expenses: [''],
        advantagesAssignFC: [false],
        leasingCost: [''],
        beneffitInKind: ['']
      });
    } else if (this.instance.isOyeskFrance()) {
      this.salarySimulationForm = this.formBuilder.group({
        simulationType: [JobTypeSalaryEnum.FULL_TIME, Validators.required],
        hours: ['', [Validators.min(this.infoSalarySimulator.minPertHours),
          Validators.max(this.infoSalarySimulator.maxPertHours),
          Validators.pattern(REGEX_PERCENTAGE)
        ]],
        rateType: ['', Validators.required],
        rate: ['', [Validators.required, Validators.min(1), Validators.max(this.checkMaxRate())]],
        missionDuration: [12, [Validators.min(this.infoSalarySimulator.minMissionDuration)]],
        taxClass: ['', [Validators.required]],
        taxRate: [''],
        costs: ['', Validators.required],
        reserve: [this.getMinReserve()],
        mealVouchers: [false],
        expenses: ['']
      });
    }

    if (this.instance.isOyeskFrance()) {
      const s = this.renderer2.createElement('script');
      s.type = 'text/javascript';
      s.src = '/assets/scripts/hubSpotClient.js';
      s.text = ``;
      this.renderer2.appendChild(this._document.body, s);
    }

  }

  initPopupHbspt() {
    const dialogRef = this.dialog.open(DialogHbsptComponent, {
      data: {
        buttonText: {
          cancel: 'Done'
        },
        urlPdfSalarySimulator: this.urlPdfSalarySimulator,
        monthlyGrossSalary: this.monthlyGrossSalary,
        durationOfTheMissionInMonths: this.durationOfTheMissionInMonths,
        dailyRate: this.dailyRate,
        idPdfSalarySimulator: this.idPdfSalarySimulator,
      },
      panelClass: 'dialogHubSpot'
    });
  }

  ngOnInit() {
    if (this.showPdf) {
      this.initPdf();
    } else {
      this.infoSalarySimulator$ = this.salarySimService.infoSalarySimulator().pipe(
        tap((infoSalarySimulatorRequest: IInfoSalarySimulatorRequest) => {
          this.infoSalarySimulator = infoSalarySimulatorRequest;
          // hide health insurance input
          this.infoSalarySimulator.listAdvantages = infoSalarySimulatorRequest.listAdvantages
            .filter(advantage => advantage.code !== 'healthInsurance');
          if (this.isInit) {
            this.initForm();
            this.isInit = false;
          }
        }));
    }
  }

  initPdf() {
    this.dataBaseChart = new DataBaseChart();
    this.keepalive.interval(1);

    let i = 0;
    this.keepalive.onPing.subscribe(() => {
      if (this.iResultSalarySimulatorRequest.invoicedAmount &&
        this.iResultSalarySimulatorRequest.invoicedAmount >= 1 && this.canDownload) {
        this.downloadPdf();
        this.keepalive.stop();
        this.canDownload = false;
      }
      i += 1;
    });
    this.keepalive.start();

    const id: string = this.route.snapshot.queryParamMap.get('id');
    if (id) {
      this.idPdfSalarySimulator = id;
      this.spinner.show(SPINNER_NAME);
      this.resultSalarySimulator$ = this.salarySimService.resultSalarySimulatorPdf(id)
        .pipe(
          map(info => {
              const employeeLuxParam = info.types
                .find(type => type.code === 'PARAMETER_EMPLOYEE_LUX');

              if (employeeLuxParam) {
                employeeLuxParam.results = employeeLuxParam.results
                  .filter(input => input.name !== 'Health Insurance (-)');
              }

              const grossSalaryParam = info.types
                .find(type => type.code === 'PARAMETER_GROSS_SALARY');

              if (grossSalaryParam) {
                grossSalaryParam.results = grossSalaryParam.results
                  .filter(input => input.name !== 'Health insurance');
              }

              return info;
            }
          ),
          tap(info => {
              this.informationSimulation(info, true, true);
              this.canDownload = true;
            }
          )
        );
    }
  }

  downloadPdf() {
    const id: string = this.route.snapshot.queryParamMap.get('id');
    if (id) {
      this.spinner.show(SPINNER_NAME);
      this.showDetails = true;
      const pdf = html2PDF(this.screen.nativeElement, {
        html2canvas: {
          scrollX: 0,
          scrollY: 0,
          imageTimeout: 15000,
          logging: true,
          useCORS: true,
        },
        margin: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
        imageQuality: 1,
        jsPDF: {
          unit: PDF_UNIT,
          format: PDF_FORMAT,
        },
        imageType: IMAGE_TYPE_JPEG,
        output: MultiPlatform.get().salaireSimulationPdf
      });
    }
  }

  getFormRawValue(): any {
    return this.salarySimulationForm.getRawValue();
  }

  simulateSalary(isShowDetails: boolean, isReserve: boolean) {
    this.showLegend = false;
    this.showDetails = isShowDetails;

    const currentHoursFullTime = this.getFormRawValue().hours;

    const isHoursFullTime = this.salarySimulationForm.controls.simulationType.value === FULL_TIME;
    if (isHoursFullTime) {
      this.salarySimulationForm.controls.hours.setValue(this.infoSalarySimulator.minPertHours);
    }

    const costs = this.getFormRawValue().costs;
    if (this.instance.isOyeskFrance()) {
      this.salarySimulationForm.controls.costs.setValue(0);
    }

    if (isHoursFullTime) {
      this.salarySimulationForm.controls.hours.setValue(currentHoursFullTime);
    }

    this.spinner.show(SPINNER_NAME);
    this.salarySimulationForm.controls.costs.setValue(costs);
    this.isPopupHbspt = true;
    this.isEditSimulation = true;
    this.displaySimulation = true;
    this.resultSalarySimulator$ = this.salarySimService.resultSalarySimulator(this.getDataSimulation(isReserve))
      .pipe(
        map(info => {
            const employeeLuxParam = info.types
              .find(type => type.code === 'PARAMETER_EMPLOYEE_LUX');

            if (employeeLuxParam) {
              employeeLuxParam.results = employeeLuxParam.results
                .filter(input => input.name !== 'Health Insurance (-)');
            }

            const grossSalaryParam = info.types
              .find(type => type.code === 'PARAMETER_GROSS_SALARY');

            if (grossSalaryParam) {
              grossSalaryParam.results = grossSalaryParam.results
                .filter(input => input.name !== 'Health insurance');
            }

            return info;
          }
        ),
        tap(info => this.informationSimulation(info, this.showDetails, isReserve))
      );
  }

  informationSimulation(info: IResultSalarySimulatorRequest, _showDetails: any, isReserve: boolean): void {
    this.iResultSalarySimulatorRequest = info;
    this.spinner.hide(SPINNER_NAME);
    this.dataBaseChart = new DataBaseChart();
    if (info.graphs) {
      info.graphs.forEach(graph => {
        this.dataBaseChart.add(new ModelBaseChart(graph.description, graph.color, graph.tax));
      });
    }
    this.showLegend = false;
    this.showDetails = _showDetails;
    window.scroll(0, 0);

    this.idPdfSalarySimulator = info.id;

    this.urlPdfSalarySimulator = `${this.localStorageService.getSimulationPdf()}?id=${this.idPdfSalarySimulator}`;

    this.monthlyGrossSalary = info.salaireMensuelBrut.toString();
    this.durationOfTheMissionInMonths = info.missionDuration;
    this.dailyRate = info.dailyRate.toString();
  }

  getDataSimulation(_isReserve: boolean): ISalarySimulatorBodyRequest {

    let dataAdvantages: IAdvantages;
    if (this.instance.isOyeskLux()) {
      const optionAdditionalAdvantages = this.getFormRawValue().advantagesAssignFC;

      let carLeasingId;
      if (this.getFormRawValue().carLeasing && this.getFormRawValue().carLeasing !== this.salarySimService.OPTION_CAR_LEASING) {
        carLeasingId = this.salarySimService.OPTION_CAR_LEASING;
        if (this.infoSalarySimulator.listCarLeasing) {
          carLeasingId = this.infoSalarySimulator.listCarLeasing[this.getFormRawValue().carLeasing - 1].id;
        }
      }
      dataAdvantages = {
        option: optionAdditionalAdvantages,
        data: [{
          code: this.salarySimService.CAR_LEASING,
          value: carLeasingId
        }, {
          code: this.salarySimService.HEALTH_INSURANCE,
          value: this.convertToFloat(this.getFormRawValue().healthInsurance)
        }, {
          code: this.salarySimService.MOBILE_PHONE,
          value: this.convertToFloat(this.getFormRawValue().mobilePhone)
        }, {
          code: this.salarySimService.FUEL_CARD,
          value: this.convertToFloat(this.getFormRawValue().fuelCard)
        }, {
          code: this.salarySimService.EXPENSES,
          value: this.convertToFloat(this.getFormRawValue().expenses)
        }]
      };
    } else if (this.instance.isOyeskFrance()) {
      dataAdvantages = {
        option: 1,
        data: [{
          code: 'expenses',
          value: this.convertToFloat(this.getFormRawValue().expenses)
        }]
      };
    }

    const data: ISalarySimulatorBodyRequest = {
      country: this.localStorageService.getCurrentCountry(),
      jobType: this.getFormRawValue().simulationType,
      hours: this.convertToFloat(this.getFormRawValue().hours),
      rate: this.convertToFloat(this.getFormRawValue().rate),
      rateType: this.getFormRawValue().rateType,
      reserve: this.getFormRawValue().reserve,
      advantages: dataAdvantages,
      vouchers: this.getFormRawValue().mealVouchers,
      costs: this.convertToFloat(this.getFormRawValue().costs),
      taxRate: this.convertToFloat(this.getFormRawValue().taxRate),
      missionDuration: this.getFormRawValue().missionDuration,
      seeSalaryDetailsPotentialNetSalary: _isReserve
    };

    data.defaultReserve = data.reserve;
    if (!_isReserve) {
      data.reserve = 0;
    }
    data.taxClass = this.listTaxClass[this.getIndexTaxClass()].id;
    if (this.instance.isOyeskLux()) {
      data.leasingCost = this.convertToFloat(this.getFormRawValue().leasingCost);
      data.beneffitInKind = this.convertToFloat(this.getFormRawValue().beneffitInKind);
    }
    return data;
  }

  convertToFloat(value: any): any {
    const c1 = '.';
    const c2 = ',';
    if (value === null) {
      return 0;
    }
    return value.toString().replace(c2, c1);
  }

  toggleMealCheck() {
    this.salarySimulationForm.controls.mealVouchers.setValue(!this.salarySimulationForm.controls.mealVouchers.value);
  }

  moreLessDetails() {
    this.showDetails = !this.showDetails;
  }

  clearResults() {
    this.initForm();
  }

  editSimulation() {
    const c1 = '.';
    const c2 = ',';
    this.salarySimulationForm.controls.rate.setValue(this.getFormRawValue().rate.toString().replace(c1, c2));
    this.salarySimulationForm.controls.hours.setValue(this.getFormRawValue().hours.toString().replace(c1, c2));
    if (this.instance.isOyeskLux()) {
      this.salarySimulationForm.controls.healthInsurance.setValue(this.getFormRawValue().healthInsurance.toString().replace(c1, c2));
      this.salarySimulationForm.controls.mobilePhone.setValue(this.getFormRawValue().mobilePhone.toString().replace(c1, c2));
      this.salarySimulationForm.controls.fuelCard.setValue(this.getFormRawValue().fuelCard.toString().replace(c1, c2));
      this.salarySimulationForm.controls.beneffitInKind.setValue(this.getFormRawValue().beneffitInKind.toString().replace(c1, c2));
      this.salarySimulationForm.controls.leasingCost.setValue(this.getFormRawValue().leasingCost.toString().replace(c1, c2));
    }
    this.salarySimulationForm.controls.expenses.setValue(this.getFormRawValue().expenses.toString().replace(c1, c2));
    this.displaySimulation = false;
  }

  toggleMenu(): void {
    this.sidenav.toggle();
  }

  getMinReserve(): number {
    return this.infoSalarySimulator.minReserve;
  }

  getMaxReserve(): number {
    return this.infoSalarySimulator.maxReserve;
  }

  formatLabelReserve(value: number) {
    return value.toString().replace('.', ',') + '%';
  }

  onBlur(event) {
    if (event.target.value !== '') {
      event.target.value = parseFloat(event.target.value).toFixed(2);
    }
  }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  getMaskMoney(): any {
    return '99999999999999,00';
  }

  customToolTipChartJs(tooltipModel): any {
    tooltipModel.x = 10;
    tooltipModel.y = 0;
  }

  convertToToolTipChartJs(tooltipItem, data): any {
    const index = tooltipItem.index;
    const label = data.labels[index];
    const value = data.datasets[0].data[index];

    return ' ' + label + ' ' + convertNumberToPercentChartJs(value);
  }

  convertToLabelChartJs(value, context): any {
    if (parseFloat(value) >= MIN_VALUE_SIMULATOR_GRAPH) {
      return convertNumberToPercentChartJs(value);
    } else if (parseFloat(value) <= ((-1) * MIN_VALUE_SIMULATOR_GRAPH)) {
      return convertNumberToPercentChartJs(value);
    }
    return '';
  }

  checkAmount(amount: number): any {
    if (amount < 0) {
      return (-1) * amount;
    }
    return amount;
  }

  checkIfAmount(amount: number): any {
    if (amount === 0) {
      return '';
    }
    if (amount < 0) {
      return (-1) * amount;
    }
    return amount;
  }

  getResultHours() {
    return '(' + this.salarySimulationForm.getRawValue().hours + 'days)';
  }

  selectRateByCode(code: any, listTaxClass: ITaxClass[]): any {
    this.listTaxClass = listTaxClass;
    let rate = this.getFormRawValue().rate;
    if (this.salarySimulationForm) {
      const rateMaxLength = this.checkMaxRate();
      const rateControl = this.salarySimulationForm.controls.rate;
      const rateValue = rateControl.value;
      rateControl.setValidators(Validators.max(rateMaxLength));
      rateControl.reset();
      if (rateValue.toString().length > rateMaxLength) {
        rateControl.setValue('');
      } else {
        rateControl.setValue(rateValue);
      }
    }
    if (code === RateTypeEnum.DAILY_RATE) {
      rate = rate * 18;
    }
    if (rate) {
      const data = listTaxClass.filter(o => rate >= o.p1 && rate < o.p2)[0];
      if (data) {
        return data.pert;
      }
    }
    return listTaxClass[0].pert;
  }

  getImpotSurLeRevenuPert(price: number): any {
    if (this.listTaxClass) {
      const data = this.listTaxClass.filter(o => price >= o.p1 && price < o.p2)[0];
      return data.pert;
    }
    return 0;
  }

  checkMissionDuration(value: any) {
    if (value) {
      return value;
    }
    return this.infoSalarySimulator.defaultMissionDuration;
  }

  getFixedTravelCostsUrl(): any {
    return MultiPlatform.get().fixedTravelCostsUrl;
  }

  public checkSelectCarLeasing(): any {
    return this.salarySimService.OPTION_CAR_LEASING === this.getFormRawValue().carLeasing;
  }

  public checkMessageTaxClass(list: any): any {
    if (list && this.getFormRawValue().taxClass) {
      this.listTaxClass = list;
      const code = list[this.getIndexTaxClass()].code;
      if (this.salarySimService.TAX_CLASS_NEUTRE === code) return true;
      else if (this.salarySimService.TAX_CLASS_NON_IMPOSABLE === code) return true;
    }
    return false;
  }

  getIndexTaxClass(): any {
    return this.getFormRawValue().taxClass - 1;
  }

  getMessageTaxClass(list: any): any {
    if (list && this.getFormRawValue().taxClass) {
      this.message = '';
      this.listTaxClass = list;
      const code = list[this.getIndexTaxClass()].code;
      if (this.salarySimService.TAX_CLASS_NEUTRE === code || this.salarySimService.TAX_CLASS_NON_IMPOSABLE === code) {
        this.spinner.show(SPINNER_UPDATE_DATE);
        this.salarySimService.resultTaxClass(this.getDataSimulation(true)).pipe(
          tap(info => {
            }
          )
        ).subscribe(data => {
          this.spinner.hide(SPINNER_UPDATE_DATE);
          this.message = data.message;
        });
      }
    }
  }

  checkSelectTaxClass(list: any): any {
    if (list && this.getFormRawValue().taxClass) {
      this.listTaxClass = list;
      return this.salarySimService.TAX_CLASS_CODE_OTHER === list[this.getIndexTaxClass()].code;
    }
    return false;
  }

  private checkMaxRate(): any {
    const num = '9';
    const decimais = '.99';
    if (this.salarySimulationForm) {
      if (this.getFormRawValue().rateType === RateTypeEnum.DAILY_RATE) {
        return Number.parseFloat(num.toString().repeat(this.infoSalarySimulator.maxDaily) + decimais);
      } else if (this.getFormRawValue().rateType === RateTypeEnum.MONTHLY_GROSS_SALARY) {
        return Number.parseFloat(num.toString().repeat(this.infoSalarySimulator.maxMonthlyGrossSalary) + decimais);
      }
    }
    return Number.parseFloat(num.toString().repeat(this.infoSalarySimulator.maxDaily) + decimais);
  }

  getLabelTaxClass() {
    if (this.instance.isOyeskFrance()) {
      return 'TAX_TYPE';
    }
    return 'TAX_CLASS';
  }

  public getChooseTypeDeTaux(): any {
    if (this.instance.isOyeskFrance() && this.listTaxClass) {
      return this.listTaxClass[this.getIndexTaxClass()].taxClass;
    }
    return '';
  }

  clickSimulationWithoutReserve() {
    this.isSeeSalaryDetailsWithPotentialNetSalary = false;
    this.simulateSalary(false, false);
  }

  simulateSalaryWithReserve() {
    this.isSeeSalaryDetailsWithPotentialNetSalary = true;
    this.simulateSalary(false, true);
  }

  selectOptionType(option: JobTypeSalaryEnum): any {
    if (option === JobTypeSalaryEnum.PART_TIME && this.salarySimulationForm.controls.hours.value.length === 0) {
      this.salarySimulationForm.controls.hours.setValue(' ');
    }
  }

  getBasedOnDailyRateLabel(resultSalarySimulator: IResultSalarySimulatorRequest) {
    // 18 days of work a month in 12 months = 220 days
    const totalDays = resultSalarySimulator.partTimeDays ? resultSalarySimulator.partTimeDays * 12 : 220;
    return this.translate.instant('CONSULTANT.SALARY_SIMULATOR.' + resultSalarySimulator.infoFormula1)
      .replace('1{}', totalDays);
  }
}
