import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {EditNewsDialogComponent} from '../../news/edit-news.dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ITrxType, TrxTypeEnum} from '../../../../../../models/admin/transaction';
import {blankSpacesValidator, MyErrorStateMatcher} from '../../../../../../../core/utils/validators';
import {TransactionService} from '../../../../../../services/admin/transaction-service';
import {catchError, take, tap} from 'rxjs/operators';
import {HIDE_DIALOG_REF, SPINNER_NAME} from '../../../../../../../core/utils/constants';
import {NgxSpinnerService} from 'ngx-spinner';
import {NotifierType} from '../../../../../../models/admin/custom-message';
import {of} from 'rxjs';
import {NotifierWithTemplateService} from '../../../../../../services/notifier-with-template.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-add-edit-trx-type',
  templateUrl: './add-edit-trx-type.component.html',
  styleUrls: ['./add-edit-trx-type.component.scss']
})
export class AddEditTrxTypeComponent implements OnInit {
  trxTypeForm: FormGroup = this.fb.group({
    typeName: ['', [Validators.required, blankSpacesValidator()]],
    creditDebit: ['', Validators.required],
    active: [null]
  });
  matcher = new MyErrorStateMatcher();
  creditOptions = [TrxTypeEnum.CREDIT, TrxTypeEnum.DEBIT];
  isExistName = false;

  constructor(public dialogRef: MatDialogRef<EditNewsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public trxType: ITrxType,
              private readonly fb: FormBuilder,
              private readonly transactionService: TransactionService,
              private readonly spinner: NgxSpinnerService,
              private readonly notifier: NotifierWithTemplateService,
              private readonly translate: TranslateService) {
  }

  ngOnInit() {
    if (this.trxType) {
      this.trxTypeForm.patchValue({
        typeName: this.trxType.typeName,
        creditDebit: this.trxType.creditDebit,
        active: this.trxType.active
      });
    }
  }

  updateOrCreateTrxType(): void {
    if (this.trxTypeForm.invalid) {
      return;
    }
    const trxType = {
      id: this.trxType ? this.trxType.id : null,
      typeName: this.trxTypeForm.controls.typeName.value,
      creditDebit: this.trxTypeForm.controls.creditDebit.value,
      active: this.trxType ? this.trxTypeForm.controls.active.value : true
    } as ITrxType;

    if (this.trxType) {
      this.transactionService.updateTransactionType(trxType)
        .pipe(
          tap((data: ITrxType) => {
            if (data) {
              this.translate.get('NAV.CONFIGURATIONS.TRANSACTION_TYPES.SUCCESS_UPDATE').pipe(take(1))
                .subscribe(message => this.notifier.notify(NotifierType.SUCCESS, message));
              this.closeDialog(data);
            } else {
              this.isExistName = true;
            }
          }),
          catchError(() => {
            this.translate.get('NAV.CONFIGURATIONS.TRANSACTION_TYPES.ERROR_UPDATE').pipe(take(1))
              .subscribe(message => this.notifier.notify(NotifierType.ERROR, message));
            return of(null);
          })
        ).subscribe();
    } else {
      this.transactionService.addTransactionType(trxType).pipe(
        tap((data: ITrxType) => {
          if (data) {
            this.translate.get('NAV.CONFIGURATIONS.TRANSACTION_TYPES.SUCCESS_CREATE').pipe(take(1))
              .subscribe(message => this.notifier.notify(NotifierType.SUCCESS, message));
            this.closeDialog(data);
          } else {
            this.isExistName = true;
          }
        }),
        catchError(() => {
          this.translate.get('NAV.CONFIGURATIONS.TRANSACTION_TYPES.ERROR_CREATE').pipe(take(1))
            .subscribe(message => this.notifier.notify(NotifierType.ERROR, message));
          return of(null);
        })
      ).subscribe();
    }
  }

  private closeDialog(action$: any) {
    this.showSpinner();
    this.dialogRef.close(true);
  }
  private showSpinner() {
    this.dialogRef.addPanelClass(HIDE_DIALOG_REF);
    this.spinner.show(SPINNER_NAME);
  }

  showExistName(): boolean {
    return this.isExistName;
  }

  showMessage(): boolean {
    return this.trxTypeForm.controls.creditDebit.invalid;
  }

  onKeyUp() {
    this.isExistName = false;
  }
}
