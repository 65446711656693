import {Injectable, TemplateRef} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NotifierTemplateService {
  private notifierTemplate: TemplateRef<any>;

  constructor() {
  }

  setNotifierTemplate(notifierTemplate: TemplateRef<any>): void {
    this.notifierTemplate = notifierTemplate;
  }

  getTemplate(): TemplateRef<any> {
    return this.notifierTemplate;
  }
}
