import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {EmbeddedAuthService} from '../../../services/embedded-auth.service';
import {Subscription, timer} from 'rxjs';
import {LocalStorageService} from '../../../services/local-storage-service';

@Component({
  selector: 'app-time-out-dialog',
  templateUrl: './time-out-dialog.component.html',
  styleUrls: ['./time-out-dialog.component.scss']
})
export class TimeOutDialogComponent implements OnInit, OnDestroy {

  private subscription: Subscription;
  private countDown = 60;
  public logoutCount = ' (60)';

  constructor(public dialogRef: MatDialogRef<TimeOutDialogComponent>,
              private readonly embeddedAuth: EmbeddedAuthService,
              private localStorageService: LocalStorageService) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    const ti = timer(1000, 1000);
    let nowExpires;
    let currentExpiresAt;
    const initExpiresAt = this.localStorageService.getExpiresAt();
    this.subscription = ti.subscribe(() => {
      currentExpiresAt = this.localStorageService.getExpiresAt();
      if (currentExpiresAt > initExpiresAt) this.dialogRef.close();
      nowExpires = new Date().getTime();
      this.countDown--;
      this.logoutCount = ' (' + this.countDown + ')';
      if (this.countDown === 0 || (Number(nowExpires) > Number(currentExpiresAt - this.countDown * 1000))) {
        this.onLogoutClick();
      }
    });
  }

  onStayClick() {
    this.embeddedAuth.renewSession();
    this.dialogRef.close();
  }

  onLogoutClick() {
    this.embeddedAuth.logout();
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
