import {Component, Input, OnInit} from '@angular/core';
import {LocalStorageService} from '../../../services/local-storage-service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-bar-back',
  templateUrl: './bar-back.component.html',
  styleUrls: ['./bar-back.component.scss']
})
export class BarBackComponent implements OnInit {

  @Input() isAdmin = true;
  @Input() hasMatToolbar = true;
  @Input() title: string;
  @Input() nameLink: string;
  @Input() hasContent = true;
  @Input() hasDivTitle = true;

  constructor(public localStorageService: LocalStorageService,
              private readonly router: Router) { }

  ngOnInit() {
  }

  back() {
    if (this.isAdmin) {
      this.router.navigateByUrl(this.localStorageService.getUrlAdmin(this.nameLink));
    } else {
      this.router.navigateByUrl(this.localStorageService.getUrlConsultant(this.nameLink));
    }
    return false;
  }
}
