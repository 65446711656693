import {NotifierWithTemplateService} from '../notifier-with-template.service';
import MultiPlatform from '../../../../environments/multi-platform';
import {
  IDocument,
  IDocumentMassivePayslip,
  IDocuments,
  IDocumentZipRequest,
  IPayslipsCreationRequest
} from '../../models/common/document';
import {catchError, take, tap} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DOCUMENT} from '@angular/common';
import {Observable, of} from 'rxjs';
import {RoleToRouteEnum} from '../../models/admin/auth0';
import {APPLICATION_PDF, PDF, TEXT_PLAIN, TXT} from '../../../core/utils/constants';

export const DOCUMENT_API_URL_COMMON = `${MultiPlatform.getDataEnvironmentHost()}api/common/documents`;
export const DOCUMENT_API_URL_ADMIN = `${MultiPlatform.getDataEnvironmentHost()}api/admin/documents`;
export const DOCUMENT_API_URL_ADMIN_DATA_PERSONAL = `${MultiPlatform.getDataEnvironmentHost()}api/admin/accounts/personal-data-document`;
export const DOCUMENTS_API_URL_ADMIN_DATA_PERSONAL = `${MultiPlatform.getDataEnvironmentHost()}api/admin/accounts/personal-data-documents`;
export const DOCUMENT_API_URL_CONSULTANT_DATA_PERSONAL = `${MultiPlatform.getDataEnvironmentHost()}api/consultants/update-document`;
export const DOCUMENTS_API_URL_CONSULTANT_DATA_PERSONAL = `${MultiPlatform.getDataEnvironmentHost()}api/consultants/update-documents`;

export const DOCUMENT_API_URL_CONSULTANT = `${MultiPlatform.getDataEnvironmentHost()}api/consultants/documents`;
export const DOCUMENT_API_URL_ADMIN_MASSIVE_PAYSLIP = `${DOCUMENT_API_URL_ADMIN}/massivePayslip`;
export const DOCUMENT_API_URL_ADMIN_VIEW_MASSIVE_PAYSLIP = `${DOCUMENT_API_URL_ADMIN}/view/massivePayslip`;

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  constructor(private http: HttpClient,
              private readonly translate: TranslateService,
              @Inject(DOCUMENT) private document: Document,
              private readonly notifier: NotifierWithTemplateService) {
  }

  getDocumentIbanByAccountId(accountId: number): Observable<IDocument> {
    return this.http.get<IDocument>(`${DOCUMENT_API_URL_COMMON}/consultant/${accountId}/iban`);
  }

  getDocumentSecuritySocialByAccountId(accountId: number): Observable<IDocument> {
    return this.http.get<IDocument>(`${DOCUMENT_API_URL_COMMON}/consultant/${accountId}/security-social`);
  }

  getDocumentsByAccountId(accountId: number, role: RoleToRouteEnum): Observable<IDocuments> {
    return this.http.get<IDocuments>(this.getAllByConsultantUrl(accountId, role));
  }

  getDocumentsByAccountIdAndTypeId(accountId: number, type: number, role: RoleToRouteEnum): Observable<IDocuments> {
    return this.http.get<IDocuments>(`${this.getAllByConsultantUrl(accountId, role)}/${type}`);
  }

  readDocument(document: IDocument, role: RoleToRouteEnum) {
    let url = `${DOCUMENT_API_URL_CONSULTANT}`;
    if (role === RoleToRouteEnum.ADMIN) {
      url = `${DOCUMENT_API_URL_ADMIN}`;
    }
    return this.http.get<any>(`${url}/read/${document.id}`);
  }

  searchDocuments(accountId: number, keyword: string, role: RoleToRouteEnum): Observable<IDocuments> {
    let url = `${DOCUMENT_API_URL_CONSULTANT}`;
    if (role === RoleToRouteEnum.ADMIN) {
      url = `${DOCUMENT_API_URL_ADMIN}`;
    }
    return this.http.post<any>(`${url}/search/${accountId}`, keyword);
  }

  postDocumentWithPersonalData(body: any): Observable<void> {
    const formData = new FormData();
    Object.keys(body).forEach(key => {
      formData.append(key, body[key]);
    });
    return this.http.post<void>(DOCUMENT_API_URL_ADMIN_DATA_PERSONAL, formData).pipe(
      tap(() => this.translate.get('VIEW.TAB.DOCUMENTS.SUCCESS.UPLOAD').pipe(take(1))
        .subscribe(message => this.notifier.success(message))),
      catchError(() => {
        this.translate.get('VIEW.TAB.DOCUMENTS.ERROR.UPLOAD').pipe(take(1))
          .subscribe(message => this.notifier.error(message));
        return of(null);
      })
    );
  }

  postDocumentWithEditProfile(body: any): Observable<void> {
    const formData = new FormData();
    Object.keys(body).forEach(key => {
      formData.append(key, body[key]);
    });
    return this.http.post<void>(DOCUMENT_API_URL_CONSULTANT_DATA_PERSONAL, formData).pipe(
      tap(() => this.translate.get('VIEW.TAB.DOCUMENTS.SUCCESS.UPLOAD').pipe(take(1))
        .subscribe(message => this.notifier.success(message))),
      catchError(() => {
        this.translate.get('VIEW.TAB.DOCUMENTS.ERROR.UPLOAD').pipe(take(1))
          .subscribe(message => this.notifier.error(message));
        return of(null);
      })
    );
  }

  postDocumentsWithPersonalData(body: any): Observable<void> {
    const formData = new FormData();
    Object.keys(body).forEach(key => {
      formData.append(key, body[key]);
    });
    return this.http.post<void>(DOCUMENTS_API_URL_ADMIN_DATA_PERSONAL, formData).pipe(
      tap(() => this.translate.get('VIEW.TAB.DOCUMENTS.SUCCESS.UPLOAD').pipe(take(1))
        .subscribe(message => this.notifier.success(message))),
      catchError(() => {
        this.translate.get('VIEW.TAB.DOCUMENTS.ERROR.UPLOAD').pipe(take(1))
          .subscribe(message => this.notifier.error(message));
        return of(null);
      })
    );
  }

  postDocumentsWithEditProfile(body: any): Observable<void> {
    const formData = new FormData();
    Object.keys(body).forEach(key => {
      formData.append(key, body[key]);
    });
    return this.http.post<void>(DOCUMENTS_API_URL_CONSULTANT_DATA_PERSONAL, formData).pipe(
      tap(() => this.translate.get('VIEW.TAB.DOCUMENTS.SUCCESS.UPLOAD').pipe(take(1))
        .subscribe(message => this.notifier.success(message))),
      catchError(() => {
        this.translate.get('VIEW.TAB.DOCUMENTS.ERROR.UPLOAD').pipe(take(1))
          .subscribe(message => this.notifier.error(message));
        return of(null);
      })
    );
  }

  postDocument(body: any, role: RoleToRouteEnum): Observable<void> {
    const formData = new FormData();
    Object.keys(body).forEach(key => {
      formData.append(key, body[key]);
    });
    let url = DOCUMENT_API_URL_CONSULTANT;
    if (role === RoleToRouteEnum.ADMIN) {
      url = DOCUMENT_API_URL_ADMIN;
    }
    return this.http.post<void>(url, formData).pipe(
      tap(() => this.translate.get('VIEW.TAB.DOCUMENTS.SUCCESS.UPLOAD').pipe(take(1))
        .subscribe(message => this.notifier.success(message))),
      catchError(() => {
        this.translate.get('VIEW.TAB.DOCUMENTS.ERROR.UPLOAD').pipe(take(1))
          .subscribe(message => this.notifier.error(message));
        return of(null);
      })
    );
  }

  getDocumentBlob(documentId: number): Observable<any> {
    return this.http.get(`${DOCUMENT_API_URL_COMMON}/${documentId}`, {responseType: 'blob'}).pipe(
      tap((blobDocument: Blob) => {
        return blobDocument;
      }),
      catchError(() => {
        this.translate.get('VIEW.TAB.CONTRACTS.ERROR.DOWNLOAD_DOCUMENT').pipe(take(1))
          .subscribe(error => this.notifier.error(error));
        return of(null);
      })
    );
  }

  downloadDocument(document: IDocument) {
    this.getDocumentBlob(document.id)
      .pipe(take(1))
      .subscribe(blob => {
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = this.document.createElement('a');
        link.href = downloadUrl;
        link.download = `${document.description}.${document.fileFormat}`;
        link.click();
        URL.revokeObjectURL(downloadUrl);
    });
  }

  printDocument(_document: IDocument) {
    this.getDocumentBlob(_document.id).pipe(take(1))
      .subscribe(blobDocument => {
        const map = new Map([
          [PDF, APPLICATION_PDF],
          [TXT, TEXT_PLAIN]
        ]);
        if (!map.has(_document.fileFormat)) {
          this.translate.get('UNSUPPORTED_FORMAT').pipe(take(1))
            .subscribe(error => this.notifier.error(error));
          return of(null);
        }
        const blob = new Blob([blobDocument], {type: map.get(_document.fileFormat)});
        const documentURL = window.URL.createObjectURL(blob);
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = documentURL;
        document.body.appendChild(iframe);

        // delay needed to work in firefox
        setTimeout(() => {
          iframe.contentWindow.print();
          URL.revokeObjectURL(documentURL);
        }, 100);
      });
  }

  deleteDocumentDataPersonalById(documentId): Observable<boolean> {
    return this.http.delete<boolean>(`${DOCUMENT_API_URL_COMMON}/${documentId}`).pipe(
      tap(deleted => {
        const translation = `VIEW.TAB.DOCUMENTS.${deleted ? 'SUCCESS' : 'ERROR'}.DELETE`;
        this.translate.get(translation).pipe(take(1))
          .subscribe(message => deleted ? this.notifier.success(message) : this.notifier.error(message));
      }),
      catchError(() => {
        this.translate.get(`VIEW.TAB.DOCUMENTS.ERROR.DELETE`).pipe(take(1))
          .subscribe(this.notifier.error);
        return of(false);
      })
    );
  }

  deleteDocument(documentId): Observable<boolean> {
    return this.http.delete<boolean>(`${DOCUMENT_API_URL_COMMON}/${documentId}`).pipe(
      tap(deleted => {
        const translation = `VIEW.TAB.DOCUMENTS.${deleted ? 'SUCCESS' : 'ERROR'}.DELETE`;
        this.translate.get(translation).pipe(take(1))
          .subscribe(message => deleted ? this.notifier.success(message) : this.notifier.error(message));
      }),
      catchError(() => {
        this.translate.get(`VIEW.TAB.DOCUMENTS.ERROR.DELETE`).pipe(take(1))
          .subscribe(this.notifier.error);
        return of(false);
      })
    );
  }

  private getAllByConsultantUrl(accountId: number, role: RoleToRouteEnum): string {
    if (role === RoleToRouteEnum.ADMIN) {
      return `${DOCUMENT_API_URL_ADMIN}/${accountId}`;
    } else if (role === RoleToRouteEnum.CONSULTANT) {
      return `${DOCUMENT_API_URL_CONSULTANT}/${accountId}`;
    }
    return `${DOCUMENT_API_URL_COMMON}/consultant/${accountId}`;
  }

  uploadMassivePayslip(body: IDocumentMassivePayslip): Observable<any> {
    const formData = new FormData();
    Object.keys(body).forEach(key => {
      formData.append(key, body[key]);
    });
    return this.http.post<any>(DOCUMENT_API_URL_ADMIN_MASSIVE_PAYSLIP, formData);
  }

  getMassivePayslipPdf(path: string): Observable<any> {
    return this.http.post(DOCUMENT_API_URL_ADMIN_VIEW_MASSIVE_PAYSLIP, path, {responseType: 'blob'});
  }

  postAllPayslips(body: IPayslipsCreationRequest[]): Observable<void> {
    const url = `${DOCUMENT_API_URL_ADMIN}/allPayslips`;
    return this.http.post<void>(url, body).pipe(
      tap(() => this.translate.get('VIEW.TAB.DOCUMENTS.SUCCESS.UPLOAD').pipe(take(1))
        .subscribe(message => this.notifier.success(message))),
      catchError(() => {
        this.translate.get('VIEW.TAB.DOCUMENTS.ERROR.UPLOAD').pipe(take(1))
          .subscribe(message => this.notifier.error(message));
        return of(null);
      })
    );
  }

  deletePayslipTemporaryFiles(paths: string[]): Observable<any> {
    return this.http.post<any>(DOCUMENT_API_URL_ADMIN_MASSIVE_PAYSLIP + '/tempFiles', paths);
  }

  getDocumentZipBlob(downloadZipRequest: IDocumentZipRequest[], accountId: number) {
    return this.http.post(`${DOCUMENT_API_URL_COMMON}/zip/${accountId}`, downloadZipRequest, {responseType: 'blob'});
  }

  downloadDocumentsZip(downloadZipRequest: IDocumentZipRequest[], accountId: number, zipName: string) {
    this.getDocumentZipBlob(downloadZipRequest, accountId).pipe(
      take(1)).subscribe((data) => {
      const blob = new Blob([data], {
        type: 'application/zip'
      });
      const url = window.URL.createObjectURL(blob);
      // window.open(url);
      const anchorTag = document.createElement('a');
      anchorTag.download = zipName;
      anchorTag.href = url;
      anchorTag.click();

    }, () => {
      this.translate.get('VIEW.TAB.CONTRACTS.ERROR.DOWNLOAD_DOCUMENT').pipe(take(1))
        .subscribe(error => this.notifier.error(error));
    });
  }
}
