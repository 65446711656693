import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {NewsModalComponent} from '../../content/consultants/pages/news-modal/news-modal.component';

@Injectable({
  providedIn: 'root'
})
export class NewsModalService {

  constructor(private readonly dialog: MatDialog) {
  }

  openNewsDialog(news, index, userId?) {
    this.dialog.open(NewsModalComponent, {
      minHeight: '40%',
      minWidth: '40%',
      maxWidth: '50%',
      data: {news, index, userId}
    });
  }
}
