import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import MultiPlatform from '../../../../environments/multi-platform';
import {
  AccountData,
  EditPartialProfileRequest,
  EditProfileRequest,
  PersonalData
} from '../../models/consultant/consultant';
import {Observable} from 'rxjs';
import {InvoicesDraft} from '../../models/consultant/transaction';
import {IdentitiesMapping} from '../../models/admin/identities-mapping';

@Injectable()
export class UserService {

  private readonly userUrlTemplate = `${MultiPlatform.getDataEnvironmentHost()}api/consultants`;
  private readonly emailPathVariable = `{email}`;
  private readonly authIdPathVariable = `{authId}`;
  private readonly consultantIdPathVariable = `{consultantId}`;
  private readonly identityIdPathVariable = `{identityId}`;
  private readonly accountValidUrlTemplate = `${this.userUrlTemplate}/${this.emailPathVariable}/${this.authIdPathVariable}`;
  private readonly personalDataUrlTemplate = `${this.userUrlTemplate}/${this.consultantIdPathVariable}/personal-data`;
  private readonly completeProfileUrlTemplate = `${this.userUrlTemplate}/${this.identityIdPathVariable}/complete-profile`;
  private readonly accountDataUrlTemplate = `${this.userUrlTemplate}/${this.consultantIdPathVariable}/account`;
  private readonly changePasswordUrlTemplate = `${this.userUrlTemplate}/newPassword/${this.authIdPathVariable}`;

  constructor(private readonly http: HttpClient) {
  }

  getUserInvoices(consultantId: string, year: number, month: number): Observable<InvoicesDraft> {
    if (year === 0) {
      return this.http.get<InvoicesDraft>(`${this.userUrlTemplate}/transactions/invoice-draft/${consultantId}`);
    }
    return this.http.get<InvoicesDraft>(`${this.userUrlTemplate}/transactions/invoice-draft/${consultantId}/${year}/${month}`);
  }

  getUserProfile(consultantId: string): Observable<PersonalData> {
    return this.http.get<PersonalData>(this.getUserProfileUrlData(encodeURIComponent(consultantId)));
  }

  getIdentitiesMapping(email: string, authUserId: string): Observable<IdentitiesMapping> {
    return this.http.get<IdentitiesMapping>(this.getAccountValidationUrlData(encodeURIComponent(email), encodeURIComponent(authUserId)));
  }

  getAccountData(consultantId: string): Observable<AccountData> {
    return this.http.get<AccountData>(this.getAccountUrlData(encodeURIComponent(consultantId)));
  }

  completeProfile(identityId: string, completeProfileRequest: EditProfileRequest | EditPartialProfileRequest): Observable<any> {
    return this.http.patch(this.getCompleteProfileUrlData(identityId), completeProfileRequest);
  }

  changePasswordDirectly(authUserId: string, newPassword: string): Observable<any> {
    return this.http.patch(this.getChangePasswordUrlData(authUserId), newPassword);
  }

  private getAccountUrlData(identityId: string): string {
    return this.accountDataUrlTemplate.replace(this.consultantIdPathVariable, identityId);
  }

  private getUserProfileUrlData(identityId: string): string {
    return this.personalDataUrlTemplate.replace(this.consultantIdPathVariable, identityId);
  }

  private getAccountValidationUrlData(email: string, authId: string): string {
    return this.accountValidUrlTemplate.replace(this.emailPathVariable, email).replace(this.authIdPathVariable, authId);
  }

  private getCompleteProfileUrlData(identityId: string): string {
    return this.completeProfileUrlTemplate.replace(this.identityIdPathVariable, identityId);
  }

  private getChangePasswordUrlData(authUserId: string) {
    return this.changePasswordUrlTemplate.replace(this.authIdPathVariable, authUserId);
  }
}
