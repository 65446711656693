import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {
  DocumentZipNature,
  IDocument,
  IDocuments,
  IDocumentType,
  IDocumentZipRequest
} from '../../../../models/common/document';
import {MatDialog} from '@angular/material/dialog';
import {NgxSpinnerService} from 'ngx-spinner';
import {DocumentsService} from '../../../../services/common/documents.service';
import {delay, take, tap} from 'rxjs/operators';
import {SPINNER_NAME} from '../../../../../core/utils/constants';
import {initializeDataSource} from '../../../../../core/utils/util-functions';
import {AddDocumentComponent} from '../../../admin/pages/consultant/list/consultant-view/documents/add-document/add-document.component';
import {ConsultantIdentityService} from '../../../../services/consultant/consultant-identity.service';
import {ReplaySubject} from 'rxjs';
import {RoleToRouteEnum} from '../../../../models/admin/auth0';
import {ViewDocumentModalComponent} from '../../../common/modals/view-document-modal/view-document-modal.component';
import {NotifierType} from '../../../../models/admin/custom-message';
import {NotifierWithTemplateService} from '../../../../services/notifier-with-template.service';
import {TranslateService} from '@ngx-translate/core';
import {ResponsiveService} from '../../../../services/responsive.service';
import {CLASS_MAT_DIALOG_TYPE} from '../../../../../core/utils/responsive';
import {FormControl} from '@angular/forms';
import {DeleteDocumentDialogComponent} from '../../../common/modals/delete-document-dialog/delete-document-dialog.component';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  accountId: any;
  consultantName: any;
  dataSource: MatTableDataSource<IDocument>;
  displayedColumns = ['checkbox', 'icon', 'description', 'expirationDate', 'uploadDate', 'actions'];
  loading = false;
  noResults = false;
  documents: IDocuments;
  documentType: IDocumentType;
  selectedDocuments: IDocumentZipRequest[] = [];
  searchFormControl = new FormControl('');
  private readonly destroy$: ReplaySubject<void> = new ReplaySubject<void>(1);

  constructor(private readonly dialog: MatDialog,
              private readonly spinner: NgxSpinnerService,
              private readonly documentService: DocumentsService,
              private readonly consultantIdService: ConsultantIdentityService,
              private readonly notifier: NotifierWithTemplateService,
              private readonly translate: TranslateService,
              public readonly responsiveService: ResponsiveService) {

  }

  ngOnInit() {
    this.accountId = this.consultantIdService.getConsultantId();
    this.consultantName = this.consultantIdService.getConsultantName();
    this.getDocuments();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getDocuments(): void {
    if (this.documentType) {
      this.setDocumentType(this.documentType);
    } else {
      this.getAllDocuments();
    }
    this.selectedDocuments = [];
  }

  getAllDocuments(): void {
    this.documentType = null;
    this.loading = true;
    this.spinner.show(SPINNER_NAME);
    this.documentService.getDocumentsByAccountId(this.accountId, RoleToRouteEnum.CONSULTANT).pipe(
      take(1),
      delay(0),
      tap(info => {
        this.documents = info;
        this.noResults = info.docs.length === 0;
        this.finishLoading();
        this.spinner.hide(SPINNER_NAME);
        this.dataSource = initializeDataSource(this.dataSource, info.docs, this.sort, this.paginator);
      }),
    ).subscribe();
  }

  setDocumentType(documentType: IDocumentType) {
    this.documentType = documentType;
    this.loading = true;
    this.spinner.show(SPINNER_NAME);
    this.documentService.getDocumentsByAccountIdAndTypeId(this.accountId, documentType.id, RoleToRouteEnum.CONSULTANT).pipe(
      take(1),
      delay(0),
      tap(info => {
        this.documents = info;
        this.noResults = info.docs.length === 0;
        this.finishLoading();
        this.spinner.hide(SPINNER_NAME);
        this.dataSource = initializeDataSource(this.dataSource, info.docs, this.sort, this.paginator);
      }),
    ).subscribe();
  }

  searchDocuments(keyword: string): void {
    if (keyword.trim() === '') {
      this.getAllDocuments();
    } else {
      this.documentType = null;
      this.loading = true;
      this.spinner.show(SPINNER_NAME);
      this.documentService.searchDocuments(this.accountId, keyword, RoleToRouteEnum.CONSULTANT).pipe(
        take(1)
      ).subscribe(docsInfo => {
          this.documents = docsInfo;
          this.noResults = docsInfo.docs.length === 0;
          this.finishLoading();
          this.dataSource = initializeDataSource(this.dataSource, docsInfo.docs, this.sort, this.paginator);
          this.spinner.hide(SPINNER_NAME);
        }, () => {
          this.notifier.notify(NotifierType.ERROR, this.translate.instant('CONSULTANT.DOCUMENTS.ERROR.SEARCH'));
          this.spinner.hide(SPINNER_NAME);
        }
      );
    }
  }

  previewDocument(document: IDocument) {
    this.documentService.readDocument(document, RoleToRouteEnum.CONSULTANT).pipe(
      take(1),
      delay(0),
      tap(() => {
        this.documentService.getDocumentBlob(document.id).pipe(take(1))
          .subscribe(blobDocument => {
            this.dialog.open(ViewDocumentModalComponent, {
              data: {blobDoc: blobDocument, documentFile: document}
            });
          });
      }),
    ).subscribe();
  }

  downloadDocument(document: IDocument) {
    this.documentService.downloadDocument(document);
  }

  printDocument(document: IDocument) {
    this.documentService.printDocument(document);
  }

  deleteDocument(document: IDocument) {
    const dialogRef = this.dialog.open(DeleteDocumentDialogComponent, {
      panelClass: this.responsiveService.getMatDialogAndBox(CLASS_MAT_DIALOG_TYPE.DIALOG_400)
    });
    dialogRef.afterClosed()
      .pipe(take(1))
      .subscribe((confirmed) => {
        if (!confirmed) return;
        this.documentService.deleteDocument(document.id).pipe(take(1))
          .subscribe(() => {
            this.getDocuments();
          }, () => {
            this.notifier.notify(NotifierType.ERROR, this.translate.instant('CONSULTANT.DOCUMENTS.DIALOG-DELETE.DELETE_ERROR'));
          });
      });
  }

  upload() {
    const data = {
      accountId: this.accountId,
      consultantName: this.consultantName,
      roleToRouteEnum: RoleToRouteEnum.CONSULTANT
    };
    const dialogRef = this.dialog.open(AddDocumentComponent,
      {panelClass: this.responsiveService.getMatDialog(CLASS_MAT_DIALOG_TYPE.NORMAL), data});
    dialogRef.afterClosed().pipe(take(1)).subscribe(() => this.getDocuments());
  }

  checkExpiration(row: any) {
    return Date.now() > new Date(row.expirationDate).getTime();
  }

  isDocumentTypeSelected(type: IDocumentType) {
    if (this.documentType) {
      return type.id === this.documentType.id;
    }
    return false;
  }

  onCheckboxChange(doc: IDocument, checked: boolean): void {
    if (checked) {
      const documentZipRequest: IDocumentZipRequest = {
        nature: DocumentZipNature.DOCUMENT,
        value: doc.id.toString()
      };
      this.selectedDocuments.push(documentZipRequest);
    } else {
      this.selectedDocuments = this.selectedDocuments.filter(req => req.value !== doc.id.toString());
    }
  }

  downloadZip(): void {
    if (this.selectedDocuments.length !== 0) {
      const zipName = `${this.consultantName}Documents.zip`.replace(' ', '');
      this.documentService.downloadDocumentsZip(this.selectedDocuments, this.accountId, zipName);
    }
  }

  private finishLoading(): void {
    setTimeout(() => this.loading = false, 500);
  }
}
