import {Injectable} from '@angular/core';
import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import {BehaviorSubject, combineLatest, from, Observable, of, throwError} from 'rxjs';
import {catchError, concatMap, shareReplay, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import MultiPlatform from '../../../environments/multi-platform';
import {IAuth0Profile} from '../models/admin/auth0';
import {getRouteByRoles} from '../../core/utils/util-functions';
import {USER_AUTH} from '../../core/utils/constants';
import {LocalStorageService} from './local-storage-service';
import {InstanceService} from './instance.service';
import {PublicRoute} from '../../core/utils/routes';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  auth0Client$ = from(
    createAuth0Client({
      domain: `${MultiPlatform.getDataEnvironmentAuth0().domain}`,
      client_id: `${MultiPlatform.getDataEnvironmentAuth0().clientID}`,
      redirect_uri: `${MultiPlatform.getDataEnvironmentRedirectUrl()}/${PublicRoute.CALLBACK}`,
      audience: `${MultiPlatform.getDataEnvironmentApi().audience}`,
      useRefreshTokens: true,
      cacheLocation: 'localstorage'
    })
  ).pipe(
    shareReplay(1), // Every subscription receives the same shared value
    catchError(err => throwError(err))
  );
  // Define observables for SDK methods that return promises by default
  // For each Auth0 SDK method, first ensure the client instance is ready
  // concatMap: Using the client instance, call SDK method; SDK returns a promise
  // from: Convert that resulting promise into an observable
  isAuthenticated$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.isAuthenticated())),
    tap(res => this.loggedIn = res)
  );
  currentUser$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.getUser()))
  );
  handleRedirectCallback$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.handleRedirectCallback()))
  );
  // Create subject and public observable of user profile data
  private readonly userProfileSubject$ = new BehaviorSubject<any>(null);
  userProfile$: Observable<IAuth0Profile> = this.userProfileSubject$.asObservable();
  // Create a local property for login status
  loggedIn: boolean = null;

  constructor(private readonly router: Router,
              private readonly localStorageService: LocalStorageService,
              public readonly instance: InstanceService) {
    // On initial load, check authentication state with authorization server
    // Set up local auth streams if user is already authenticated
    this.localAuthSetup();
    // Handle redirect from Auth0 login
    this.handleAuthCallback();
  }

  // When calling, options can be passed if desired
  // https://auth0.github.io/auth0-spa-js/classes/auth0client.html#getuser
  getUser$(options?): Observable<any> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getUser(options))),
      tap(user => this.userProfileSubject$.next(user))
    );
  }

  private localAuthSetup() {
    // This should only be called on app initialization
    // Set up local authentication streams
    const checkAuth$ = this.isAuthenticated$.pipe(
      concatMap((loggedIn: boolean) => {
        if (loggedIn) {
          // If authenticated, get user and set in app
          // NOTE: you could pass options here if needed
          return this.getUser$();
        }
        // If not authenticated, return stream that emits 'false'
        return of(loggedIn);
      })
    );
    checkAuth$.subscribe();
  }

  login(redirectPath = '/') {
    // A desired redirect path can be passed to login method
    // (e.g., from a route guard)
    // Ensure Auth0 client instance exists
    this.auth0Client$.subscribe((client: Auth0Client) => {
      // Call method to log in
      client.loginWithRedirect({
        redirect_uri: `${MultiPlatform.getDataEnvironmentRedirectUrl()}/${PublicRoute.CALLBACK}`,
        appState: {target: redirectPath}
      });
    });
  }

  handleAuthCallback() {
    // Call when app reloads after user logs in with Auth0
    const params = window.location.search;
    if (params.includes('code=') && params.includes('state=')) {
      let targetRoute: string; // Path to redirect to after login processed
      const authComplete$ = this.handleRedirectCallback$.pipe(
        // Have client, now call method to handle auth callback redirect
        tap(cbRes => {
          // Get and set target redirect route from callback results
          targetRoute = cbRes.appState && cbRes.appState.target ? cbRes.appState.target : '/';
        }),
        concatMap(() => {
          // Redirect callback complete; get user and login status
          return combineLatest([
            this.getUser$(),
            this.isAuthenticated$
          ]);
        })
      );
      // Subscribe to authentication completion observable
      // Response will be an array of user and login status
      authComplete$.subscribe(([user, isLoggedIn]: [IAuth0Profile, boolean]) => {
        if (!isLoggedIn) {
          this.router.navigate([PublicRoute.LOGIN]);
          return;
        }
        const roles = user[USER_AUTH].roles;
        const route = getRouteByRoles(roles, this.instance, this.localStorageService);
        // Redirect to target route after callback processing
        this.router.navigate([route]);
      });
    }
  }

  logout() {
    this.localStorageService.logout();
    this.auth0Client$.subscribe((client: Auth0Client) => {
      client.logout({
        client_id: `${MultiPlatform.getDataEnvironmentAuth0().clientID}`,
        returnTo: `${window.location.origin}`
      });
    });
  }

  getTokenSilently$(options?): Observable<string> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getTokenSilently(options))
        .pipe(tap(() => this.getUser$().subscribe()))
      ),
      catchError(() => of(null))
    );
  }

}
